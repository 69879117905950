import { forwardRef } from 'react';
import { Icons } from 'styles';
import PropTypes from 'prop-types';
import './index.scss';

const Input = forwardRef(
  ({ onChange, onKeyDown, value, label, name, disabled, maxLength, error }, ref) => {
    return (
      <div
        className={`input-container ${disabled ? 'disabled' : ''} ${error ? 'error' : ''} ${
          value ? 'value-exist' : ''
        }`}
      >
        <input
          ref={ref}
          className='input'
          onChange={onChange}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
          maxLength={maxLength}
          value={value}
          disabled={disabled}
          name={name}
        />
        <div className='bar'></div>
        <div className='bar animated-bar'></div>
        <label className={`input-label`}>{label}</label>
        {error && (
          <div className='error-icon'>
            <Icons.IcError />
          </div>
        )}
        {error && <span className='error-msg'>{error}</span>}
      </div>
    );
  }
);

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func,
  value: PropTypes.string.isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  error: PropTypes.string,
};

export default Input;

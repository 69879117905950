import { Colors, Icons } from 'styles';
import PropTypes from 'prop-types';
import './index.scss';

//! type은 제플린 컴포넌트 가이드에 있는 순서대로 1~3, 4는 3의 hover 전,후 반전
const CircleButton = ({ label, onClick, type = 'type1', labelColor = Colors.black }) => {
  return (
    <div className='circle-button-conatiner' onClick={onClick}>
      {Array.isArray(label) ? (
        <div className='button-labels'>
          {label.map((text, i) => (
            <div key={i} className='button-label' style={{ color: labelColor }}>
              {text}
            </div>
          ))}
        </div>
      ) : (
        <span className='button-label' style={{ color: labelColor }}>
          {label}
        </span>
      )}
      <div className={`outer-button ${type}`}>
        <div className='inner-button'></div>
        <Icons.IcArrowLeft />
      </div>
    </div>
  );
};

CircleButton.propTypes = {
  /** 버튼의 라벨 */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /** 버튼이 클릭되었을 때 실행되는 함수 */
  onClick: PropTypes.func,
  /** 버튼의 타입 (각각 스타일이 다릅니다) */
  type: PropTypes.oneOf(['type1', 'type2', 'type3', 'type4']),
  /** 라벨 색상 */
  labelColor: PropTypes.string,
};

CircleButton.defaultProps = {
  type: 'type1',
  labelColor: Colors.black,
};

export default CircleButton;

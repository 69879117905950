import Strings from 'strings';
import './index.scss';

const IeComponent = () => {
  let isMobile = window.innerWidth < 768 ? true : false;
  const descArr = isMobile
    ? [Strings.IE_DESC_1, Strings.IE_DESC_4]
    : [Strings.IE_DESC_1, Strings.IE_DESC_2, Strings.IE_DESC_3];

  const onClick = () => {
    window.open(`https://www.google.com/chrome/`, '_blank');
  };
  return (
    <div className='ie-container'>
      <div className='ie-title'>{Strings.IE_TITLE}</div>
      <div className='ie-desc-container'>
        {descArr.map((desc, idx) => (
          <div className='ie-desc' key={idx}>
            {desc}
          </div>
        ))}
      </div>
      <div className='download-chrome' onClick={onClick}>
        {Strings.IE_BUTTON}
      </div>
    </div>
  );
};

export default IeComponent;

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import React from 'react';
import { render } from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import store, { history } from './_redux';
import { browserCheck } from 'utils';
import IeComponent from 'components/ie-component';

const root = document.getElementById('root');

if (browserCheck()) {
  render(
    <React.StrictMode>
      <Provider store={store}>
        <CookiesProvider>
          <App history={history} />
        </CookiesProvider>
      </Provider>
    </React.StrictMode>,
    root
  );
} else {
  render(<IeComponent />, root);
}

import { useRef, useState, useEffect } from 'react';
import { Icons } from 'styles';
import PropTypes from 'prop-types';
import './index.scss';

const LongSquareButton = ({ label, onClick, small }) => {
  const buttonRef = useRef();
  const [isMouseOver, setIsMouseOver] = useState(false);

  const toggleMouseEnter = () => {
    setIsMouseOver((prev) => !prev);
  };

  useEffect(() => {
    const button = buttonRef.current;

    button.addEventListener('mouseenter', toggleMouseEnter);
    button.addEventListener('mouseleave', toggleMouseEnter);

    return () => {
      button.removeEventListener('mouseenter', toggleMouseEnter);
      button.removeEventListener('mouseleave', toggleMouseEnter);
    };
  }, []);

  return (
    <div className={`long-square-button ${small ? 'small' : ''}`} ref={buttonRef} onClick={onClick}>
      <div className='long-square-button-label'>{label}</div>
      <div className='long-square-button-icon'>
        {isMouseOver ? <Icons.IcMoreWhite /> : <Icons.IcMoreBlack />}
      </div>
    </div>
  );
};

LongSquareButton.propTypes = {
  /** 버튼 라벨 텍스트 */
  label: PropTypes.string.isRequired,
  /** 버튼 클릭시 실행되는 함수 */
  onClick: PropTypes.func,
  /** 라벨 사이즈 */
  small: PropTypes.bool,
};

export default LongSquareButton;

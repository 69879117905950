import LocalizedStrings from 'react-localization';

const ko = {
  HEADER_MENU_ABOUT: 'ABOUT',
  HEADER_MENU_TECHNOLOGY: 'TECHNOLOGY',
  HEADER_MENU_PRODUCT: 'PRODUCT',
  HEADER_MENU_TEAM: 'TEAM',
  HEADER_MENU_PARTNERS: 'PARTNERS',
  HEADER_MENU_NEWS: 'NEWS',
  HEADER_MENU_CAREER: 'CAREER',
  HEADER_SUBMENU_RESEARCH: 'Research Area',
  HEADER_SUBMENU_BLOG: 'Tech Blog',
  HEADER_SUBMENU_VIEWINTER_HR: 'VIEWINTER HR',
  HEADER_SUBMENU_VIEWINTER: 'VIEWINTER',
  COOKIE_DESC: `Genesis Lab may serve cookies to analyze traffic to this site.\nInformation about your use of this site is shared with Genesis Lab for that purpose.`,
  COOKIE_DETAIL: 'SEE DETAIL',
  COOKIE_OK: 'OK, GOT IT',
  MAIN_VIDEO_WATCHVIDEO: 'Watch full video',
  MAIN_VIDEO_INTERACTIVE_AI: 'Interactive AI',
  MAIN_LEADING_TITLE: '\nLeading with Technology',
  MAIN_OUR_VISION: 'Our vision',
  MAIN_LEADING_SUBTITLE:
    'We are conducting research that advances the state-of-the-art in the Interactive AI field.',
  MAIN_LEADING_SUBTITLE_BIGSCREEN_1: `We are conducting research that advances`,
  MAIN_LEADING_SUBTITLE_BIGSCREEN_2: `the state-of-the-art in the Interactive AI field.`,
  MAIN_LEADING_01_TITLE: 'Detection',
  MAIN_LEADING_01_DESC:
    'Face Detection & Facial Landmark Detection,Age / Gender Estimation,Head Pose Estimation,Eye Gaze Estimation,Video based Facial Expression Recognition (7 expressions)',
  MAIN_LEADING_02_TITLE: 'Prediction & Analysis',
  MAIN_LEADING_02_DESC:
    'Hiring Score Prediction,Softskill Score Prediction,BEI Competency Assessment (context based)',
  MAIN_LEADING_03_TITLE: 'Advanced Research',
  MAIN_LEADING_03_DESC: 'Facial Expression Transfer,Speech to Face,Question Generation',
  MAIN_INTERACTIVE_TITLE_1: '\nBringing the benefits of Interactive AI',
  MAIN_INTERACTIVE_SUB_TITLE_1:
    'Applying to products and to new domains to ensure that everyone can access Interactive AI.',
  MAIN_INTERACTIVE_TITLE_2: `HR \nAI Video Interview`,
  MAIN_INTERACTIVE_SUB_TITLE_2: 'Analyzing videos for screening candidates.',
  MAIN_INTERACTIVE_TITLE_3: 'Mental Health \nAI Video Depression Diagnosis',
  MAIN_INTERACTIVE_SUB_TITLE_3: 'Diagnosing Depression Symptoms over video interviews.',
  MAIN_INTERACTIVE_TITLE_4: 'Education \nAI English Speaking Test',
  MAIN_INTERACTIVE_SUB_TITLE_4:
    'Testing ability to communicate effectively in a variety of situations.',
  MAIN_INTERACTIVE_TITLE_5: '\nBringing the benefits of Interactive AI',
  MAIN_INTERACTIVE_SUB_TITLE_5:
    'Applying to products and to new domains to ensure that everyone can access Interactive AI.',
  MAIN_GET_UPDATE_TITLE: `Get our latest updates`,
  MAIN_GET_UPDATE_SIGN_UP: `Sign up to receive newsletters from Genesis Lab. Your information will be used in accordance with Genesis Lab’s privacy policy. You may opt out at any time.`,
  MAIN_GET_UPDATE_BUTTON_LABEL: `Subscribe`,
  MAIN_GET_UPDATE_BUTTON_SUBSCRIBED_LABEL: `Submit`,

  MAIN_JOIN_OUR_MISSION_TITLE: `Join our mission`,
  MAIN_JOIN_OUR_MISSION_SUBTITLE: `We're the frontiers of Interactive AI and so can you.`,
  MAIN_JOIN_OUR_MISSION_LINK: `Search Open Roles`,
  MAIN_JOIN_OUR_MISSION_CURSOR_LABEL: `Explore jobs`,
  MAIN_CONTACT_US_TITLE: `Contact Us`,
  MAIN_CONTACT_US_CURSOR_LABEL: `More`,
  MAIN_SECOND_DESC_1: 'Analyzing videos for screening candidates.',
  MAIN_SECOND_DESC_2: 'Diagnosing Depression Symptoms over video interviews.',
  MAIN_SECOND_DESC_3: 'Testing ability to communicate effectively in a variety of situations.',
  MAIN_NOT_READY: 'Coming Soon',
  FOOTER_TERMS: 'Terms',
  FOOTER_PRIVACY: 'Privacy Policy',
  FOOTER_BLOG: 'Blog',
  FOOTER_DOWNLOAD: 'Download Company Introduction',
  FOOTER_CAREER: 'Career',
  NEWS_TITLE_1: `Our`,
  NEWS_TITLE_2: `News`,
  NEWS_SUBTITLE_DEFAULT: `Find out the latest public articles and stories from Genesis Lab and learn more about our breakthroughs in cutting-edge AI research.`,
  NEWS_SUBTITLE_BIGSCREEN_1: `Find out the latest public articles and stories from`,
  NEWS_SUBTITLE_BIGSCREEN_2: `Genesis Lab and learn more about our breakthroughs`,
  NEWS_SUBTITLE_BIGSCREEN_3: `in cutting-edge AI research.`,
  CONTACT_US_TITLE_1: `Contact`,
  CONTACT_US_TITLE_2: `Us`,
  CONTACT_US_SUBTITLE_DEFAULT: `Whether you have a question about our technology, partnership, investment, SDK/API, or anything else, we’re all ears.`,
  CONTACT_US_SUBTITLE_BIGSCREEN_1: `Whether you have a question about our technology,`,
  CONTACT_US_SUBTITLE_BIGSCREEN_2: `partnership, investment, SDK/API, or anything else,`,
  CONTACT_US_SUBTITLE_BIGSCREEN_3: `we’re all ears.`,
  CONTACT_US_LOCATION_TITLE: `Location`,
  CONTACT_US_LOCATION_HEADQUARTERS: `Headquarters`,
  CONTACT_US_LOCATION_HEADQUARTERS_DESC: `5F, 73 Myeongdong-gil, Jung-gu, Seoul, Republic of Korea`,
  CONTACT_US_ADDRESS_TITLE: `Address`,
  CONTACT_US_ADDRESS_EMAIL: `Email`,
  CONTACT_US_ADDRESS_EMAIL_DESC: `contact@genesislab.ai`,
  CONTACT_US_ADDRESS_CALL: `Call`,
  CONTACT_US_ADDRESS_CALL_DESC: `+82 2 6402 0118`,
  CONTACT_US_HELP_TITLE_1: `How can`,
  CONTACT_US_HELP_TITLE_2: `we help you?`,
  CONTACT_US_HELP_SUBTITLE_1: `Explore our products`,
  CONTACT_US_HELP_DESC_1_1: `Request a detailed product introductory metarial or sign up for a personalized demo of `,
  CONTACT_US_HELP_DESC_1_2: `our products.`,
  CONTACT_US_HELP_SUBTITLE_2: `Become a partner`,
  CONTACT_US_HELP_DESC_2_1: `From enterprises to colleges, we’re working with `,
  CONTACT_US_HELP_DESC_2_2: `magnificent partners.`,
  CONTACT_US_HELP_DESC_2_3: ` Do not hesitate to be the next.`,
  CONTACT_US_HELP_SUBTITLE_3: `Technology`,
  CONTACT_US_HELP_DESC_3_1: `We’re always happy to share thoughts about our `,
  CONTACT_US_HELP_DESC_3_2: `technology.`,
  CONTACT_US_HELP_DESC_3_3: ` Ask anything, or request for SDK/API.`,
  CONTACT_US_FORM_FIRST_NAME: `First Name`,
  CONTACT_US_FORM_LAST_NAME: `Last Name`,
  CONTACT_US_FORM_COMPANY_NAME: `Company Name`,
  CONTACT_US_FORM_JOB_TITLE: `Job Title`,
  CONTACT_US_FORM_EMAIL_ADDRESS: `Email Address`,
  CONTACT_US_FORM_PHONE_NUMBER: `Phone Number`,
  CONTACT_US_FORM_COUNTRY: `Country`,
  CONTACT_US_FORM_SUBJECT_LABEL: `Subject`,
  CONTACT_US_FORM_SUBJECT_1: `Buy Product`,
  CONTACT_US_FORM_SUBJECT_2: `Partnership`,
  CONTACT_US_FORM_SUBJECT_3: `Investment`,
  CONTACT_US_FORM_SUBJECT_4: `Technology Inquiry`,
  CONTACT_US_FORM_SUBJECT_5: `Request for SDK/API`,
  CONTACT_US_FORM_SUBJECT_100: `Others`,
  CONTACT_US_FORM_RELATED_PRODUCT_LABEL: `Related Product`,
  CONTACT_US_FORM_RELATED_PRODUCT_1: `Viewinter`,
  CONTACT_US_FORM_RELATED_PRODUCT_2: `Viewinter HR`,
  CONTACT_US_FORM_RELATED_PRODUCT_100: `Others`,
  CONTACT_US_FORM_COMMENT_LABEL: `Comment`,
  CONTACT_US_FORM_CHECKBOX_LABEL_1: `By submitting this form, I admit that I agree to the `,
  CONTACT_US_FORM_CHECKBOX_LABEL_2: `Privacy Policy`,
  CONTACT_US_FORM_CHECKBOX_LABEL_3: ` and I’d like to receive more information about Genesis lab.`,

  CONTACT_US_FORM_BUTTON_LABEL: `Send Message`,
  CONTACT_US_AGREE_ALERT: 'Please agree policy',
  BLOG_TITLE_1: 'Tech Blog',
  // BLOG_TITLE_2: 'Blog',
  BLOG_DETAIL_SUBTITLE_DEFAULT: `Check out our various research activities, paper reviews by researchers at Genesis Lab, and stories about the future of AI that we are dreaming of.`,
  BLOG_DETAIL_SUBTITLE_BIGSCREEN_1: 'Check out our various research activities, paper reviews',
  BLOG_DETAIL_SUBTITLE_BIGSCREEN_2: 'by researchers at Genesis Lab, and stories about the',
  BLOG_DETAIL_SUBTITLE_BIGSCREEN_3: 'future of AI that we are dreaming of.',

  TAG_TITLE: 'Tag',
  CONTACT_US_FILL: `Please fill {label}`,
  CONTACT_US_INVALID_EMAIL: 'invalid email',
  CONTACT_US_SUCCESS: 'Send Success',

  //* career 페이지
  CAREER_TITLE_1: `Open`,
  CAREER_TITLE_2: `Positions`,
  CAREER_JOIN: 'Join the',
  CAREER_TEAM: 'team',
  CAREER_HEADER_DESC: `We are a global team on a mission to build an interactive AI that communicates with human.
  Our service processes thousands of videos per month to make a state-of-the-art AI model
  and make human progress. Be a part of creating the future of AI and communication
  experiences without limitations.`,
  CAREER_JOB_GENESISLAB: 'Genesis Lab',
  CAREER_JOB_ENGINEERING: 'Engineering',
  CAREER_JOB_SALES: 'Sales',
  CAREER_JOB_MARKETING: 'Marketing',
  CAREER_JOB_FOUNDATIONS: 'Foundations',
  CAREER_JOB_PEOPLECULTURE: 'People&Culture',
  CAREER_JOB_RESEARCH: 'Research',
  CAREER_COPY_URL_BUTTON_LABEL: `Copy URL`,

  //* career filter 카테고리 string
  CAREER_FILTER_TITLE: `Filters`,
  CAREER_FILTER_JOB_TITLE_CATEGORY_TITLE: `Job Title`,
  CAREER_FILTER_JOB_TITLE_CATEGORY_ITEM1: `All Departments`,
  CAREER_FILTER_JOB_TITLE_CATEGORY_ITEM2: `Engineering`,
  CAREER_FILTER_JOB_TITLE_CATEGORY_ITEM3: `Sales`,
  CAREER_FILTER_JOB_TITLE_CATEGORY_ITEM4: `Marketing`,
  CAREER_FILTER_JOB_TITLE_CATEGORY_ITEM5: `Foundations`,
  CAREER_FILTER_JOB_TITLE_CATEGORY_ITEM6: `People&Culture`,
  CAREER_FILTER_JOB_TITLE_CATEGORY_ITEM7: `Design`,
  CAREER_FILTER_LOCATION_CATEGORY_TITLE: `Location`,
  CAREER_FILTER_LOCATION_CATEGORY_ITEM1: `All Locations`,
  CAREER_FILTER_LOCATION_CATEGORY_ITEM2: `Seoul, South Korea`,
  CAREER_FILTER_BUTTON_LABEL: `Apply`,

  //* career 직무 이름
  CAREER_JOB_TITLE_1: 'Frontend Developer',
  CAREER_JOB_TITLE_2: 'Android Developer',
  CAREER_JOB_TITLE_3: 'Server Developer',
  CAREER_JOB_TITLE_4: 'Product Manager',
  CAREER_JOB_TITLE_5: 'AI Scientist',
  CAREER_JOB_TITLE_6: 'Data Scientist',
  CAREER_JOB_TITLE_7: 'Service UX/UI Designer (서비스 기획자)',
  CAREER_JOB_TITLE_8: 'Service Operation Manager',

  // * 회사 소개 직무별
  CAREER_POSITION_COMMON2_INTRODUCE: `지원 전에 아래 링크를 통해 제네시스랩 인재상과 자세한 팀 소개를 확인하세요! `,
  CAREER_POSITION_COMMON1_INTRODUCE: `{{position}} 직무는 제네시스랩 서비스 개발팀에 소속되어 있습니다.`,
  CAREER_POSITION_COMMON3_INTRODUCE: `{{position}} 직무는 제네시스랩 Interactive AI 연구소에 소속되어 있습니다.`,
  CAREER_POSITION_COMMON4_INTRODUCE: `{{position}} 직무는 제네시스랩 사업운영팀에 소속되어 있습니다.`,

  CAREER_POSITION_GENESISLAB: `제네시스랩 인재상`,
  CAREER_POSITION_DEVELOP_TEAM: '서비스 개발팀 소개',
  CAREER_POSITION_RESEARCH_TEAM: 'Interactive AI 연구소 소개',
  CAREER_POSITION_OPERATION_TEAM: '사업운영팀 소개',

  CAREER_POSITION_WANTED_MEM_LINK: 'https://www.notion.so/3c5a9746629048cd9d3b0fb567de8aea',
  CAREER_POSITION_DEVELOP_TEAM_LINK: 'https://www.notion.so/bb8b85232ba44094a96bfee396a1a0af',
  CAREER_POSITION_RESEARCH_TEAM_LINK: 'https://www.notion.so/a34e354bbbe84273a132af3232d1ca64',
  CAREER_POSITION_RESEARCH_OPERATION_TEAM_LINK:
    'https://www.notion.so/6253938fe8034beb88e8bda5d319989b',

  //* career 회사 소개_공통_영문
  CAREER_COMPANY_CULTURE_1_TITLE: `감정인식의 기술로 모두에게 더 나은 가치를 제공`,
  CAREER_COMPANY_CULTURE_1_DESC: `제네시스랩에서는 감정인식 최신 기술들을 연구하고 개발하여 제품과 서비스에 접목하고 새로운 사업영역을 만들어 사람과 기기가 공감하고 소통하게 하는 원대한 비전을 가지고 있습니다.`,

  CAREER_COMPANY_CULTURE_2_TITLE: `감정인식 기술을 발전시키기 위한 데이터 파이프라인 구축`,
  CAREER_COMPANY_CULTURE_2_DESC: `제품과 서비스를 통해 연구에 도움이 되는 데이터를 지속적으로 축적하고 있습니다. 특정 도메인에서 다양한 도메인들의 데이터들이 지속적으로 감정인식 AI를 발전해 나갑니다.`,

  CAREER_COMPANY_CULTURE_3_TITLE: `기술역량의 창업자와 팀으로 구성`,
  CAREER_COMPANY_CULTURE_3_DESC: `2017년 1월에 창업하여 창업진 모두 기술 백그라운드가 강합니다. 또한 산업계 경력과 성공적인 연쇄창업 경험으로 회사의 빠른 성장을 일궈내고 있습니다.`,

  CAREER_COMPANY_CULTURE_4_TITLE: `함께 일하고 싶은 동료`,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_1: `기본적으로 해당 직무에 전문성을 갖춘 사람`,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_2: `기술 자체에 관심이 있고 욕심이 많은 사람`,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_3: `대략적인 방향만으로도 일을 계획적으로 추진 해 나갈 수 있는 사람`,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_4: `별다른 커뮤니케이션 교육 없이도 회사생활에 문제가 없는 사람`,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_5: `이미 잘 갖춰진 프로세스를 따르기보다 함께 프로세스를 만들고 개선하는 과정을 선호하는 사람`,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_6: `누군가의 관리가 필요하기보다 본인 스스로의 자기관리가 뛰어난 사람`,

  //* career 회사 소개_공통_영문
  CAREER_COMPANY_CULTURE_1_TITLE_EN: `We provide better value to all with emotion recognition technology`,
  CAREER_COMPANY_CULTURE_1_DESC_EN: `Genesis Lab has a grand vision of researching and developing the latest emotion recognition technologies, incorporating them into products and services, and creating new business areas so that people and AI can sympathize and communicate with each other.`,

  CAREER_COMPANY_CULTURE_2_TITLE_EN: `We establish a data pipeline to develop emotion recognition technology`,
  CAREER_COMPANY_CULTURE_2_DESC_EN: `We are continuously accumulating data through products and services that is useful for research.
Data collected from our various domains continuously develops emotion recognition AI.`,

  CAREER_COMPANY_CULTURE_3_TITLE_EN: `We are composed of founders and teams of technical competence`,
  CAREER_COMPANY_CULTURE_3_DESC_EN: `Founded in January 2017, all founders have strong technical backgrounds. In addition, our industry experience and successful serial entrepreneurship experience are driving the company's rapid growth.`,

  CAREER_COMPANY_CULTURE_4_TITLE_EN: `We are looking for people with… `,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_1_EN: `Basic expertise in the job field`,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_2_EN: `High interest and ambition for trending technology`,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_3_EN: `Initiative to make it happen only with a rough direction `,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_4_EN: `Communication skills and social attitude required for work life`,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_5_EN: `Preference in developing and improving the process, rather than simply following existing process `,
  CAREER_COMPANY_CULTURE_4_DESC_LIST_6_EN: `Ability in self-discipline, not needing someone's management `,

  //* career 직무별 설명_jobProcess_국문
  CAREER_DETAIL_JOB_PROCESS_SECTION_1_TITLE: `고용형태`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_1_CONTENT_LINE_1: `정규직 (수습기간 3개월)`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_1_CONTENT_LINE_1_1: `- 수습기간 동안의 급여는 정규직과 동일`,

  CAREER_DETAIL_JOB_PROCESS_SECTION_2_TITLE: `채용절차`,

  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_1: `1. 서류제출`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_1_1: `- 필수 사전 질문 : 제네시스랩에 지원하시는 이유는 무엇인가요?`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_1_2: `(지원서에 내용 포함하여 제출)`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_2: `2. 사전 인터뷰`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_3: `3. 과제`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_3_1: `- Front-end 개발과 관련된 구현 과제`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_3_2: `- Back-end 개발과 관련된 구현 과제`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_3_3: `- 딥러닝 관련 구현 과제`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_3_4: `- 데이터 분석 관련 구현 과제`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_3_5: '- 서비스 기획과 관련된 과제',
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_3_6: '- 제네시스랩 서비스 운영 관련 과제',
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_4: `4. 직무 인터뷰`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_4_1: `- 과제 수행 내용 및 자신이 구현한 소스코드 리뷰`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_4_2: `- 과제 수행 내용과 자신이 경험한 내용 발표 및 소스코드 리뷰`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_4_3: `- 과제 수행 내용과 자신이 연구한 내용 발표 및 소스코드 리뷰`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_4_4:
    '- 과제 수행한 내용 및 자신이 기획한 과제 리뷰',
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_4_5: '- 과제 수행 내용과 경험 리뷰',
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_5: `5. 구성원 인터뷰`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_6: '6. 레퍼런스 체크',
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_7: '7. 합격 여부 안내 및 처우 협의',

  CAREER_DETAIL_JOB_PROCESS_SECTION_3_TITLE: `복지 및 업무환경`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_1: `복지 제도 (클릭)`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_1_LINK: `https://www.notion.so/64a0fe1c5d63443fa9f44e02fb4a61ef`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_2: `업무 환경 (클릭)`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_2_LINK: `https://www.notion.so/a313bfb555744b6eb599384990c0c5d2`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_3: `사무실 위치 (클릭)`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_3_LINK: `https://www.notion.so/41670d40a26741579b878a5f808b4fd3`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_4: `건강 지원 : 건강검진 지원 (임직원 및 임직원 가족), 심리상담 지원`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_5: `기타 : 스낵바 제공, 복지카드 (예정)`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_6: `사무실 : 서울특별시 중구 명동1가 1-3 (을지로 3가역 도보 8분, 명동역 도보 6분)`,

  //* career 직무별 설명_jobProcess_영문
  CAREER_DETAIL_JOB_PROCESS_SECTION_1_TITLE_EN: `Employment type`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_1_CONTENT_LINE_1_EN: `Full-time (3 months of training period) `,
  CAREER_DETAIL_JOB_PROCESS_SECTION_1_CONTENT_LINE_1_1_EN: `- The pay during the training period is the same as that of full-time employees`,

  CAREER_DETAIL_JOB_PROCESS_SECTION_2_TITLE_EN: `Recruitment Procedure`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_1_EN: `1. Resume & CV screening (+ technical blog URL and/or portfolio) `,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_1_1_EN: `- Required pre-question: Why are you applying to Genesis Lab?`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_1_2_EN: `(response should be included in your paper)`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_2_EN: `2. Job interview `,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_3_EN: `3. Member interview `,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_4_EN: `4. Reference check `,
  CAREER_DETAIL_JOB_PROCESS_SECTION_2_CONTENT_LINE_5_EN: `5. Job offer and treatment negotiation`,

  CAREER_DETAIL_JOB_PROCESS_SECTION_3_TITLE_EN: `Benefits & welfare`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_1_EN: `Rewards that matches your competency`,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_2_EN: `Flexible work environment : flexible worktime, working from home (3 days a week) `,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_3_EN: `Growth support (i.e. book purchase, group study) `,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_4_EN: `Snack bar, welfare card (coming soon) `,
  CAREER_DETAIL_JOB_PROCESS_SECTION_3_CONTENT_LINE_5_EN: `Office: Euljiro (within 10 minutes walk from Euljiro 3-ga Station)`,

  CAREER_DETAIL_JOB_DESCRIPTION_SECTION_1_TITLE: `주요업무`,
  CAREER_DETAIL_JOB_DESCRIPTION_SECTION_2_TITLE: `기술스택`,
  CAREER_DETAIL_JOB_DESCRIPTION_SECTION_3_TITLE: `자격요건`,
  CAREER_DETAIL_JOB_DESCRIPTION_SECTION_4_TITLE: `우대사항`,

  //* career 직무별 설명_frontend_jobDescription_국문
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1: `Genesislab의 다양한 웹 서비스 개발`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_1: `- 뷰인터 서비스 웹`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_2: `- 뷰인터 관리자 페이지`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_3: `- 뷰인터HR 응시자용 웹`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_4: `- 뷰인터HR 관리자용(외부) 웹`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_5: `- 뷰인터HR 운영 담당자용(내부) 웹`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_6: '- vlink 서비스 웹',
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_2: `Product UI/UX 개선`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_3: `개발 환경 개선 및 생상성 향상을 위한 자동화 Tool 개발`,

  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_1: `ReactJS, Redux, Redux Saga`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_2: `Cypress, StoryBook, Jest`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_3: `HTML, CSS, JavaScript,`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_4: `Sentry`,

  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_1: `Frontend 개발 실무 경력 5년 이상 혹은 이에 준하는 수준`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_2: `React 를 이용한 운영 서비스 실무 경험`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_3: `CSS에 능숙한 개발 실무 경험`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_4: `Git & Github, RESTful API 클라이언트 개발 경험`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_5: `ChartJS 등 차트 관련 라이브러리를 이용한 개발 경험`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_6: `타 직군(운영, 기획, 디자인)과 원활한 협업, 커뮤니케이션이 가능한 분`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_7: `HTML, CSS, JavasScript에 대한 이해가 있으신 분`,

  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_1: `차트를 이용한 데이터 시각화 라이브러리 사용 경험`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_2: `HTML5 MediaStream 에 대한 이해`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_3: `TypeScript 를 이용한 개발 및 운영 경험`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_4: `단위 테스트 및 통합 테스트 경험`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_5: `GraphQL 을 이용한 경험`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_6: `기술 자체에 관심이 많고 자기 개발에 노력하는 분`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_7: `스타트업 업무 문화에 대해 경험 또는 이해도가 높은 분`,

  //* career 직무별 설명_frontend_jobDescription_영문
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_TITLE_EN: `WHAT YOU WILL DO`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_EN: `Build Genesislab's various web services`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_1_EN: `- Viewinter service web`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_2_EN: `- Viewinter admin page`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_3_EN: `- Web for Viewinter HR applicants`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_4_EN: `- Web for Viewinter HR manager (external)`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_5_EN: `- Web for Viewinter HR operation (internal)`,

  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_2_EN: `Imporove Product UI/UX`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_3_EN: `Develop automation tool to improve development environment and productivity`,

  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_TITLE_EN: `TECHNOLOGY STACK`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_1_EN: `ReactJS`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_2_EN: `Redux, Redux Saga, axios`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_3_EN: `CSS, HTML5`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_4_EN: `Javascript`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_5_EN: `SCSS, SVG`,

  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_TITLE_EN: `KEY QUALIFICATIONS`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_1_EN: `3+ years of experience in frontend`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_2_EN: `Hands-on experience of operation service using ReactJS framework `,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_3_EN: `Hands-on experience of development proficient in advanced CSS `,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_4_EN: `Experience of development with server communication (i.e. AJAX communication, JSON parsing)`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_5_EN: `Experience of development using chart-related libraries (i.e. ChartJS)`,

  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_TITLE_EN: `BONUS POINTS`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_1_EN: `Experience of developing HTML5 Video and Cavas `,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_2_EN: `Experience of using and reflecting UI components such as Google Material and Bootstrap `,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_3_EN: `Ability to communicate seamlessly with other professions (i.e. server developers, designers)`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_4_EN: `Interest in technology and are striving for self-improvement`,
  CAREER_FRONTEND_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_5_EN: `Experience or high interst in start-up working environment`,

  //* career 직무별 설명_android_jobDescription_국문
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1: `제네시스랩의 다양한 서비스 개발`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_1: `- 뷰인터 서비스 앱`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_2: `- 뷰인터HR 응시자용 서비스 앱`,

  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_1: `Android(Java)`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_2: `React-native`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_3: `Javascript`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_4: `CSS`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_5: `Redux`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_6: `Redux Saga`,

  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_1: `Android 앱 실무 경력 3년 이상`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_2: `Android 플랫폼에 대한 이해도가 높은 분`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_3: `도덕성과 윤리의식이 높으신 분`,

  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_1: `기술 자체에 관심이 많고 자기 개발에 노력하는 분`,
  CAREER_ANDROID_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_2: `스타트업 업무 문화에 대해 경험 또는 이해도가 높은 분`,

  //* career 직무별 설명_server_jobDescription_국문
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1: `제네시스랩의 다양한 서비스의 API 및 백엔드 시스템 개발 및 운영`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_1: `- 뷰인터, 뷰인터 HR, vlink 등`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_2: `- 각 서비스 별 운영 관리 및 관리자 서버`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_3: `- 뷰인터, 뷰인터HR 운영 관리 및 관리자 백앤드 서버 개발`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_2: `대용량 트래픽을 안정화시켜 가용성 높은 서비스 구축`,

  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_1: `Spring, spring boot`,

  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_1: `서버개발 실무 경력 1년 이상으로 주니어도 지원 가능!`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_2: `Spring, JPA 실무 개발 및 운영 경험이 있으신 분`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_3: `MariaDB와 같은 RDBMS 사용 경험 및 DB 설계 능력이 있으신 분`,

  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_1: `Spring 배치 및 통계 시스템 개발 경험이 있으신 분`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_2: `B2C 서비스 백엔드 실무 개발 / 운영/ 유지보수 개발 경험이 있으신 분`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_3: `데이터베이스 성능 개선 경험이 있으신 분`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_4: `QueryDSL 개발 경험이 있으신 분`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_5: `AWS 운영 경험이 있으신 분`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_6: `AI 기술을 연동하여 개발한 경험이 있으신 분`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_7: `기술 자체에 관심이 많고 자기 개발에 노력하시는 분`,
  CAREER_SERVER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_8: `스타트업 업무 문화에 대해 경험 또는 이해도가 높으신 분`,

  //* career 직무별 설명_productManager_jobDescription_국문
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1: `사업의 방향성을 이해하고 필요한 Product의 모습을 찾아 개발 및 운영하는 일`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_2: `필요한 Product의 모습을 개발자들이 이해할 수 있는 스토리로 구체화하는 일`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_3: `Product의 전반적인 방향에 대해 유관 부서에 전파하며 협업하는 일`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_4: `Product가 설계되고 만들어지는 과정에 참여하여, 개발팀과 사용자/기술/사업적인 측면에서 원활하게 커뮤니케이션하고 스케줄링하여 제품 로드맵을 구체화하는 일`,

  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_1: `프로젝트 리딩 경험 3년 이상`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_2: `제품이 나가야할 방향을 설정하고, 개발 과정 전반에 대한 이해를 바탕으로 협업이 가능하신 분`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_3: `개발자와 디자이너가 이해할 수 있는 스토리로 구체화할 수 있는 능력을 갖추신 분`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_4: `개발, UX 디자인, 마케팅 등 다양한 협업 부서와 사용자/기술/사업 측면에서 원활한 커뮤니케이션이 가능하신 분`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_5: `고객 또는 소비자 중심적 사고를 갖고 계신 분`,

  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_1: `서비스에 대한 전반적인 경험이 있으신 분 (모바일 앱, 웹사이트 개발 및 운영 관리)`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_2: `데이터 분석을 통한 서비스 전략 수립에 경험이 있으신 분`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_3: `고객 또는 소비자 중심적 사고를 갖고 계신 분`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_4: `AI에 대한 이해와 관심이 있으신 분`,
  CAREER_PRODUCT_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_4_CONTENT_LINE_5: `테스트 경험, 고객 인터뷰, Product 개선 경험이 있으신 분`,
  CARRER_FORM_NOT_SUPPORTED: `Only PDF format files are supported.`,

  // * AI Scientist 국문
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1:
    '(얼굴 정보 인식) 얼굴 및 랜드마크, 나이 및 성별, 얼굴 움직임, 눈 움직임, 감정 인식',
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_2:
    '(음성 인식) 립리딩 및 음성 인식',
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_3:
    '(비디오 분석 및 평가) 채용 추천 평가, BEI 역량 평가, 정신건강 상태 평가, 영어 스피킹 평가',
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_3_1:
    '멀티모달 학습, 준지도 학습, 자연어 처리, Explainable AI',
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_4:
    '(생성 기술) AI 휴먼 생성 (Text to Speech, Speech to Face 등), 질문 생성',

  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_2_CONTENT_LINE_1:
    '최신 논문을 빠르게 이해하고 구현할 수 있는 분',
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_2_CONTENT_LINE_2:
    'ML 프레임워크(Tensorflow, Pytorch, MXNet 등)을 이용하여 딥러닝 학습 구현이 가능하신 분',
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_2_CONTENT_LINE_3:
    '새로운 연구 분야에 도전하는 것을 즐기시는 분',

  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_3_CONTENT_LINE_1:
    '대표 AI 컨퍼런스 및 저널 논문 주저자 1편 이상 경력을 보유하신 분',
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_3_CONTENT_LINE_2:
    'AI 기술과 서비스 관계에 대한 이해도가 있으신 분',
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_3_CONTENT_LINE_3: '음성 인식 기술 관련 연구 실적',
  CAREER_AI_SCIENTIST_DETAIL_JOB_DESCRIPTION_3_CONTENT_LINE_4: '멀티모달 데이터에 관심이 많으신 분',

  // * Data Scientist 국문

  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1:
    '내부 시스템을 이용한 데이터 관리',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1_1:
    '-  데이터 태깅 및 Annotator 할당 및 관리',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_2:
    '딥러닝 모델 추론 결과 분석을 통한 모델 성능 향상 기여',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_3:
    'AI 기술 개발 시 데이터 구축 설계',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_4:
    '수집된 영상, 음성, 텍스트 데이터 분석',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_5:
    '서비스에서 나오는 다양한 데이터 분석',

  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_1:
    'Python을 이용한 데이터 분석이 가능하신 분',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_2:
    '딥러닝 학습을 위한 영상, 음성, 텍스트 등 데이터 전처리 작업이 가능하신 분',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_3:
    '데이터를 직접 보고 분석하는 작업에 대해 가치를 가지시는 분',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_4:
    '데이터 보안에 대한 이해도가 높으신 분',

  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_1:
    '딥러닝 추론 결과를 분석하여 모델 성능 향상에 기여한 경험이 있으신 분',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_2:
    'AI 기술에 대한 관심이 높으신 분',
  CAREER_DATA_SCIENTIST_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_3:
    '데이터 수집에 대한 설계 경험이 있으신 분',

  // * 서비스 기획자

  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1:
    '제네시스랩의 기존 서비스 개선 및 운영',
  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_2:
    '제네시스랩의 기존 서비스 기획서 관리',
  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_3: '신규 서비스 발굴 및 기획',

  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_1:
    '서비스 기획 실무 경력 1년 이상으로 주니어도 지원 가능!',
  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_2:
    '주도적으로 일을 진행하고 프로젝트를 이끌어 가실 수 있는 분',
  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_3:
    '타인을 설득할 수 있는 논리와 협업을 위한 커뮤니케이션 능력을 가지고 있으신 분',
  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_4:
    '평소 다양한 서비스를 즐겨 사용하고 UX/UI 에 관심이 많으신 분',

  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_1:
    'B2C or B2B 서비스 기획 경험이 있으신 분',
  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_2:
    '개발자 간 원할한 의사소통이 가능하신 분',
  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_3:
    '스타트업 업무 문화에 대해 경험 또는 이해도가 높으신 분',
  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_4:
    'Notion 사용에 익숙하신 분',
  CAREER_UI_UX_DESIGNER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_5:
    '데이터 기반 의사 결정에 능숙하신 분',

  // * 서비스 운영 매니저

  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_1:
    '고객사의 AI면접 프로젝트가 처음부터 끝까지 성공적으로 마무리될 수 있도록 운영하는 역할을 수행합니다.',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_2:
    'AI면접 프로젝트가 도입되고 종료될 때 까지의 진행 일정 조율 및 관리',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_3:
    '고객과의 소통을 통해 고객의 요구나 문제사항을 정확하게 파악하고 신속히 해결',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_4:
    '수집된 데이터 모니터링 및 데이터 품질 관리',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_1_CONTENT_LINE_5:
    '운영과정 중 이슈 도출 및 개선사항 제안',

  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_1:
    '서비스의 최고 가치는 고객임을 공감하시는 분',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_2:
    '다양한 문제를 고객경험과 비즈니스 관점으로 판단하실 수 있는 분',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_3:
    '원활한 커뮤니케이션 능력과 다양한 팀과의 협업 경험을 보유하신 분',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_4:
    '업무의 경계 없이 다양한 업무를 신속하게 처리해 본 경험을 보유하신 분',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_2_CONTENT_LINE_5:
    '무엇보다, 우리의 서비스에 대한 애정과 관심이 있으신 분',

  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_1:
    '고객 서비스 / 세일즈 관련 업무 경험이 있으신 분',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_2:
    '문서를 통한 커뮤니케이션 능력이 뛰어난 분',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_3:
    '다양한 고객관리시스템을 사용해 본 경험이 많으신 분',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_4:
    'AI에 대한 이해와 관심이 있으신 분',
  CAREER_OPERATION_MANAGER_DETAIL_JOB_DESCRIPTION_SECTION_3_CONTENT_LINE_5:
    '운영 전문가로 성장하고자 하는 의지가 있으신 분',

  // * research-area
  RESEARCH_TITLE: 'Research Area',
  RESEARCH_DESC_DEFAULT:
    'We are working on some of the most advanced areas of AI deep-learning technology. Find out what each technology is and how it applies to our services.',
  RESEARCH_AREA_DESC_1: 'We are working on some of the most advanced areas of AI',
  RESEARCH_AREA_DESC_2: 'deep-learning technology. Find out what each technology',
  RESEARCH_AREA_DESC_3: 'is and how it applies to our services.',
  RESEARCH_MENU_1: 'Detection',
  RESEARCH_MENU_2: 'Prediction & Analysis',
  RESEARCH_MENU_3: 'Advanced Research',
  RESEARCH_MENU_FIRST_1_TITLE: 'Age / Gender Estimation',
  RESEARCH_MENU_FIRST_2_TITLE: 'Face Detection & Facial Landmark Detection',
  RESEARCH_MENU_FIRST_3_TITLE: 'Head Pose Estimation',
  RESEARCH_MENU_FIRST_4_TITLE: 'Eye Gaze Estimation',
  RESEARCH_MENU_FIRST_5_TITLE: 'Video based Facial Expression Recognition (7 expressions)',

  RESEARCH_MENU_SECOND_1_TITLE: 'Hiring Score Prediction',
  RESEARCH_MENU_SECOND_2_TITLE: 'Softskill Score Prediction',
  RESEARCH_MENU_SECOND_3_TITLE: `BEI Competency Assessment (context based)`,

  RESEARCH_MENU_THIRD_1_TITLE: 'Facial Expression Transfer',
  RESEARCH_MENU_THIRD_2_TITLE: 'Speech to Face',
  RESEARCH_MENU_THIRD_3_TITLE: 'Question Generation',

  RESEARCH_MENU_FIRST_2_DESC_1:
    '"Face Detection" is a computer vision technology that identifies human faces in images. We continue to conduct research and development for a faster and more accurate algorithm, by combining various face detection models.',
  RESEARCH_MENU_FIRST_2_DESC_2:
    '“Face Landmark Detection” is a technology that detects facial landmarks in a facial image identified by our face detector. We keep conducting research and development in ANNs(Artificial Neural Networks) for detecting 68 landmarks as well as 98 landmarks.',
  RESEARCH_MENU_FIRST_2_DESC_3:
    'We applied lightweight deep learning techniques on the 68 landmark detector in order to minimize the loss in accuracy and to improve processing speed. The 98 landmark detector has higher accuracy than the 68 one — and detects profile landmarks as well.',
  RESEARCH_MENU_FIRST_2_DESC_4: `The face and landmark detection technologies are utilized in the first stage of analyzing a video. We selected the best algorithm that traded off the speed and accuracy, and applied it to VIEWINTER and VIEWINTER HR. These services detect facial and landmark information from applicants' videos and use them for the ANNs’ input data. (i.e. eye movement detection, emotion detection, recruiting assessment, etc.)`,

  RESEARCH_MENU_FIRST_1_DESC_1:
    'Age and Gender Estimation play a foundational role in human-computer interaction (HCI). Deep learning-based Age and Gender Estimation system used facial images aims to predict age and gender simultaneously.',
  RESEARCH_MENU_FIRST_1_DESC_2:
    'Our proposed network consists of multiple novel approaches such as distillation, generative adversarial training, and joint learning to provide fast and accurate predicted results.',
  RESEARCH_MENU_FIRST_1_DESC_3: `To enhance the performance, we developed a Generative Adversarial Network (GAN) based generator-discriminator network in which the discriminator simultaneously classifies real or fake Fast Fourier Transform (FFT) images, age, and gender.`,
  RESEARCH_MENU_FIRST_1_DESC_4: `The presented invaluable technology is utilized to mitigate bias in the preprocessing stage of VIEWINTER and VIEWINTER HR. We also deploy SDK and API in response to the customer's needs.`,
  RESEARCH_MENU_FIRST_3_DESC_1: `Head pose estimation is of great interest for practical applications such as surveillance, attention measurement, and face alignment. A deep learning-based head pose estimation method in a facial image can provide useful supplementary information (i.e., head-related landmark points, variations of head-movement) for both interviewees and interviewers in the HR industry. By exploiting the information, the interviewers find out the interviewee's inattentive or distracting attitude that can affect the assessment. On the other hand, the interviewees can improve their interview skills.`,
  RESEARCH_MENU_FIRST_3_DESC_2: `Our head pose estimation method based on Convolutional Neural Network (CNN) detects five landmark points and the Euler angles from the face image. The presented technology is applied in our HR products (VIEWINTER, VIEWINTER HR) to provide quantitative data for head-movement.`,
  RESEARCH_MENU_FIRST_4_DESC_1: `Eye Gaze Estimation is a task to predict where a person is looking at given an image of an eye. The aforementioned task has been applied VIEWINER and VIEWINTER HR to provide the information of variations in eye-movement and gaze direction. We believe this technology can also identify an interviewee's cheating, such as reading a prepared script during an interview.`,
  RESEARCH_MENU_FIRST_4_DESC_2: `The overall framework of our proposed eye gaze estimation network consists of two stages. In the first stage, we trained a region of interest (ROI) detection network to extract the eyes-related region. In the next stage, a multi-scale gaze estimation network that represents affluent spatial information was trained. Through the two-step training framework, the proposed model achieved a comparable result to that of state-of-the-art methods.`,
  RESEARCH_MENU_FIRST_5_DESC_1: `Genesis Lab's facial expression recognition technology classifies seven types(neutral, joy, sadness, anger, fear, disgust, and surprise) of emotional information in the face from each video frame. Since facial expression recognition technology is very sensitive to lighting or head movement changes, we presented an improved LSTM network robust to these environments.`,
  RESEARCH_MENU_FIRST_5_DESC_2: `This technology is applied to VIEWINTER and VIEWINTER HR to provide the candidate's emotional information as positive/negative throughout the video.`,
  RESEARCH_MENU_FIRST_5_DESC_3: `Genesis Lab has also been researching and developing a multi-modal emotion recognition technology for years to resolve the problem since this technology is difficult to recognize a speaking person's facial expression.`,
  RESEARCH_MENU_SECOND_1_DESC_1: `Our deep learning-based hiring recommendation system automatically assesses scores to assist HR experts and interviewees using interview video. The presented system applied deep neural networks (DNNs) to define a multimodal and multi-task architecture with a shared representation among modalities (i.e., visual, audio, and text), related tasks, respectively. We developed two versions of models according to the purpose and target: a lightweight model and a large-capacity model. In VIEWINTER, we applied the lightweight model to provide users a fast result as a B2C service. In VIEWINTER HR, which mainly targets the enterprise HR team, we applied a large-capacity model to provide greater accuracy.`,
  RESEARCH_MENU_SECOND_1_DESC_2: `As in the actual interview situation, our models learn several experts' opinions separately to alleviate subjective bias. And Genesis Lab's HR models help increase the consistency of assessment criteria among interviewers and provides a comprehensive report based on the soft skills.`,
  RESEARCH_MENU_SECOND_2_DESC_1: `Hiring new employees is a huge concern for companies. This is mainly because excellent individuals are the company's competitiveness. That's why companies put effort into open recruitment every year. Nonetheless, it is not easy for companies to hire appropriate employees. There are also cases of regret after hiring.`,
  RESEARCH_MENU_SECOND_2_DESC_2: `This originates from the lack of opportunities for companies to review many individuals. The number of applicants who have interview opportunities diminishes as applicants are initially screened with application forms. If all applicants are given the opportunity to be interviewed, companies will have a chance to find a Diamond in the rough” that they could not find in the application forms. However, in reality, this is also not easy due to the limited number of interviews. The situation will be different if there are substitutions for the interviewers.`,
  RESEARCH_MENU_SECOND_2_DESC_3: `This originates from the lack of opportunities for companies to review many individuals. The number of applicants who have interview opportunities diminishes as applicants are initially screened with application forms. If all applicants are given the opportunity to be interviewed, companies will have a chance to find a Diamond in the rough” that they could not find in the application forms. However, in reality, this is also not easy due to the limited number of interviews. The situation will be different if there are substitutions for the interviewers.`,
  RESEARCH_MENU_SECOND_3_DESC_1: `The Behavioral Event Interview (BEI) is an interview technique that assesses competency through in-depth questions about interviewees' past behavioral events. Several prior studies have been shown the feasibility of the BEI method and reported that the BEI has a reasonably positive correlation with job performance.`,
  RESEARCH_MENU_SECOND_3_DESC_2: `Genesis Lab has been collecting a large volume of the BEI dataset for various competency categories and developing the BEINet that automatically estimates job competency using a multi-modal framework based on the Deep Learning (DL) algorithm. Since, in the course of the BEI, the interviewee's competency score is mainly determined based on linguistic factors (competency-cause clauses in the responses), we focus on the quality of transcriptions and interpretability of BEINet. To obtain accurate transcription, we utilize automatic speech recognitions (ASRs) from multiple vendors and correct the error using our correction algorithms. On the other hand, to improve the interpretability, the BEINet is trained to predict the competency score and the presence of the behavioral indicators that are the factor for determining the score.`,
  RESEARCH_MENU_SECOND_3_DESC_3: `We believe asynchronous video interview (AVI) applied BEINet can reduce the time-consumption of the face-to-face interview and help interviewers by providing a second opinion.`,
  RESEARCH_MENU_THIRD_1_DESC_1: `"Facial expression transfer"(or face reenactment) is a computer vision technology that allows a source face shape and facial expression to be synthesized on the target face while maintaining its appearance and identity.`,
  RESEARCH_MENU_THIRD_1_DESC_2: `When applying the technology to VIEWINTER HR, we expect to be able to reduce the bias which occurs when a person directly views and evaluates interview video. In VIEWINTER, we expect that it will help protect user privacy when sharing videos to experts for expert consulting purposes.`,
  RESEARCH_MENU_THIRD_2_DESC_1: `"Speech to Face" is a computer vision technology that synthesizes the mouth movement of a human in the video in accordance with the inputted speech information. The speech, obtained by inputting text into the text to speech (TTS) technology, can be re-entered into this technology to create an animation of a human speaking.`,
  RESEARCH_MENU_THIRD_2_DESC_2: `By applying the question script created by the question generator to this technology, we can make our virtual human agent ask questions — which will bring us closer to our vision of Interactive AI.`,

  //* Research Patent
  RESEARCH_PATENT_TITLE: `PATENT`,
  RESEARCH_PATENT_COUNTRY_1: `KR`,
  RESEARCH_PATENT_COUNTRY_2: `US`,
  RESEARCH_PATENT_PCT_LABEL: `PCT`,
  RESEARCH_PATENT_CONTENT_1: `Automated Target Analysis System Using Machine Learning Model, Method, and Computer-readable Medium Thereof`,
  RESEARCH_PATENT_CONTENT_2: `Device, Method and Readable Media for Multimodal Recognizing Emotion Based on Artificial Intelligence`,
  RESEARCH_PATENT_CONTENT_3: `Multi-modal Emotion Recognition Device, Method, and Storage Medium Using Artificial Intelligence`,
  RESEARCH_PATENT_CONTENT_4: `Machine Learning System Using Joint Learning and The Method Thereof`,
  RESEARCH_PATENT_CONTENT_5: `Automated Driver-Managing System Using Machine Learning Model, and Method Thereof`,
  RESEARCH_PATENT_CONTENT_6: `Automated Facial Expression Recognizing Systems on N frames, Methods, and Computer-Readable Mediums Thereof`,
  RESEARCH_PATENT_CONTENT_7: `Non-verbal Evaluation Method, System and Computer-readable Medium Based on Machine Learning`,
  RESEARCH_PATENT_CONTENT_8: `Interview Preparation Service Providing Method, System and Computer-readable Medium Using Machine Learning`,
  RESEARCH_PATENT_CONTENT_9: `Online Interview Providing Method, System and Computer-readable Medium Using Machine Learning`,
  RESEARCH_PATENT_CONTENT_10: `Method for Providing College Early Admission Preparing Service and System Thereof`,
  RESEARCH_PATENT_CONTENT_11: `Interview Automatic Evaluation Model Learning Method, System and Computer-readable Medium`,
  RESEARCH_PATENT_CONTENT_12: `Online Interview Providing Method, System and Computer-readable Medium`,
  RESEARCH_PATENT_CONTENT_13: `Managing Method, System and Computer-readable Medium for Interview Automatic Evaluation Model`,
  RESEARCH_PATENT_CONTENT_14: `Managing Method, System and Computer-readable Medium for Interview Automatic Evaluation Model`,
  RESEARCH_PATENT_CONTENT_15: `Method for Providing Recruit Information Using Machine Learning Model and Computer-readable Medium Thereof`,
  RESEARCH_PATENT_CONTENT_16: `Feature Information Derivation From A Plural of frames System Using Machine Learning Model, Methods, Computer-Readable Mediums thereof, and Interview Preparation Service Providing Method`,
  RESEARCH_PATENT_CONTENT_17: `Method of Processing Video for Determining Emotion of a Person`,
  RESEARCH_PATENT_CONTENT_18: `Methods, Systems and Computer-Readable Medium for Automated Evaluation of Interview Videos using Machine Learning Model`,
  RESEARCH_PATENT_CONTENT_19: `Methods, Systems and Computer-Readable Medium for Deriving In-Depth Questions for Automated Evaluation of Interview Videos Using Machine Learning Model`,
  RESEARCH_PATENT_CONTENT_20: `Methods, Systems and Computer-Readable Medium for Teaching in Interviews Evaluation`,
  RESEARCH_PATENT_CONTENT_21: `Method, System and Computer-readable Medium for Conducting Recruitment Processes for Applicants Using Machine Learning Model`,
  RESEARCH_PATENT_CONTENT_22: `Methods, Systems and Computer-Readable Medium for Providing Preliminary Depression Diagnosis Information With Machine Learning Model`,
  RESEARCH_PATENT_CONTENT_23: `Methods, Systems and Computer-Readable Medium for Learning Machine-Learned-Model Evaluating Plural Competency`,

  //* about 페이지
  ABOUT_OUR_VISION_TITLE: 'OUR VISION',
  ABOUT_OUR_VISION_DESC_1: `We believe that Interactive AI represents our greatest opportunity to improve the way people communicate with machines.`,
  ABOUT_OUR_VISION_DESC_2: `We contribute to human progress by empowering machines to understand, have empathy and build relationship with us.`,
  ABOUT_OUR_VISION_DESC_1_BIG_SCREEN_1: `We believe that Interactive AI represents our greatest opportunity`,
  ABOUT_OUR_VISION_DESC_1_BIG_SCREEN_2: `to improve the way people communicate with machines.`,
  ABOUT_OUR_VISION_DESC_2_BIG_SCREEN_1: `We contribute to human progress by empowering machines`,
  ABOUT_OUR_VISION_DESC_2_BIG_SCREEN_2: `to understand, have empathy and build relationship with us.`,
  ABOUT_AI_INTRODUCE_TITLE: `Interactive AI`,
  ABOUT_AI_INTRODUCE_DESC_1_BIG_SCREEN_1: `Interactive AI consists of Video Analysis and Generation technology`,
  ABOUT_AI_INTRODUCE_DESC_1_BIG_SCREEN_2: `that allows seamless interactions with machines`,
  ABOUT_AI_INTRODUCE_DESC_1_BIG_SCREEN_3: `by understanding current domains and asking generated questions`,
  ABOUT_AI_INTRODUCE_DESC_1_BIG_SCREEN_4: `in the form of digitally generated persons.`,
  ABOUT_AI_INTRODUCE_DESC_1: `Interactive AI consists of Video Analysis and Generation technology that allows seamless interactions with machines by understanding current domains and asking generated questions in the form of digitally generated persons.`,
  ABOUT_AI_INTRODUCE_CAPTION_1: `Our goal is to provide as much analysis in the video as possible including the partners technology and data.`,
  ABOUT_AI_INTRODUCE_CAPTION_2: `Also to generate digital agent that interacts just like humans.`,
  ABOUT_FLYWHEEL_TITLE: `Genesis Lab Flywheel`,
  ABOUT_FLYWHEEL_SUBTITLE_1: `Advancing technology`,
  ABOUT_FLYWHEEL_DESC_1_1: `The success of the AI tech company comes from the quality and level of technology. We will continue to research and develop state-of-the-art Interactive AI.`,
  ABOUT_FLYWHEEL_SUBTITLE_2: `Product Market Fit`,
  ABOUT_FLYWHEEL_DESC_2_1: `Identifying the real world problem is important. Striving to find solutions with Interactive AI and verifying its usefulness is going to drive adoption of such AI technology in the market.`,
  ABOUT_FLYWHEEL_SUBTITLE_3: `Data`,
  ABOUT_FLYWHEEL_DESC_3_1: `Throughout the services, data will be collected, and these privately protected data will also nurture and improve current AI technology. Structuring data pipeline on top of services as well as labelling and managing are key missions.`,
  ABOUT_HISTORY_TITLE: `Our History`,
  ABOUT_HISTORY_2021: `2021`,
  ABOUT_HISTORY_2021_PRODUCT: `Mental Health`,
  ABOUT_HISTORY_2020: `2020`,
  ABOUT_HISTORY_2020_INVEST: `Pre-series B`,
  ABOUT_HISTORY_2019_PRODUCT: `HR domain`,
  ABOUT_HISTORY_2018_PRODUCT_DESC_1: `2 B2B customers`,
  ABOUT_HISTORY_2019_PRODUCT_DESC_1: `40 B2B/B2G customers`,
  ABOUT_HISTORY_2019_PRODUCT_DESC_2: `30 B2S customers`,
  ABOUT_HISTORY_2019: `2019`,
  ABOUT_HISTORY_2019_INVEST: `Series A`,
  ABOUT_HISTORY_2018: `2018`,
  ABOUT_HISTORY_2017: `2017`,
  ABOUT_HISTORY_2017_PRODUCT_1: `Founded`,
  ABOUT_HISTORY_2017_PRODUCT_2: `SDK/API`,
  ABOUT_HISTORY_2017_INVEST: `Seed Funding`,
  ABOUT_AI_INTRODUCE_FEEDS_TITLE_ANALYSIS: 'Analysis',
  ABOUT_AI_INTRODUCE_FEEDS_TITLE_GENERATIONS: 'Generation',
  ABOUT_ANALYSIS_SUB_TITLE: 'Non-verbal Signal Analysis, Context Analysis, …',
  ABOUT_GENERATIONS_SUB_TITLE: 'Question Generation, Speech to Face Generation, …',

  //* partners 페이지
  PARTNERS_PAGE_TITLE: `PARTNERS`,
  PARTNERS_PAGE_SUBTITLE_1: `Our partners are leading digital transformation and innovation with our technology.`,
  PARTNERS_PAGE_SUBTITLE_2: `Together, we create the future of AI that breaks new ground.`,
  PARTNERS_PARTNERSHIP_TITLE: `We work with`,
  PARTNERS_PARTNERSHIP_BUTTON_LABEL: `Request Partnership`,
  PARTNERS_PARTNERSHIP_DESC_1: `Working with Genesis lab was a true pleasure.`,
  PARTNERS_PARTNERSHIP_DESC_2: `The team found out the exact painpoint and enabled data-based decision making with AI technology on non-quantative factors of interview.`,
  PARTNERS_PARTNERSHIP_CAPTION_1: `Yuk Geunsik`,
  PARTNERS_PARTNERSHIP_CAPTION_1_1: `Team Leader`,
  PARTNERS_PARTNERSHIP_CAPTION_1_2: `Talent Acquisition Team at LG U Plus`,
  PARTNERS_INVESTORS_TITLE: `Investors`,
  PARTNERS_INVESTORS_BUTTON_LABEL: `Inquire Investment`,
  PARTNERS_RESELLER_PARTNERS_TITLE: `Reseller Partners`,
  PARTNERS_RESELLER_PARTNERS_BUTTON_LABEL: `Apply reseller program`,
  PARTNERS_UNIVERSITIES_TITLE: `Universities`,
  PARTNERS_UNIVERSITIES_BUTTON_LABEL: `Request Partnership`,
  PARTNERS_UNIVERSITIES_ITEM_1: `Hongik`,
  PARTNERS_UNIVERSITIES_ITEM_2: `Myongji `,
  PARTNERS_UNIVERSITIES_ITEM_3: `The Catholic`,
  PARTNERS_UNIVERSITIES_ITEM_4: `Chungnam National`,
  PARTNERS_UNIVERSITIES_ITEM_5: `Soongsil `,
  PARTNERS_UNIVERSITIES_ITEM_6: `Sangmyung `,
  PARTNERS_UNIVERSITIES_ITEM_7: `KC `,
  PARTNERS_UNIVERSITIES_ITEM_8: `Hansei `,
  PARTNERS_UNIVERSITIES_ITEM_9: `Dongduk Women's`,
  PARTNERS_UNIVERSITIES_ITEM_10: `Gachon `,
  PARTNERS_UNIVERSITIES_ITEM_11: `Gangneung-Wonju`,
  PARTNERS_UNIVERSITIES_ITEM_12: `Yeonsung`,
  PARTNERS_UNIVERSITIES_ITEM_13: `Pai Chai `,
  PARTNERS_UNIVERSITIES_ITEM_14: `Seokyeong `,
  PARTNERS_UNIVERSITIES_ITEM_15: `Seoul National `,
  PARTNERS_UNIVERSITIES_ITEM_16: `Ulsan College`,
  PARTNERS_UNIVERSITIES_ITEM_17: `Hansung `,
  PARTNERS_UNIVERSITIES_ITEM_18: `Induk `,
  PARTNERS_UNIVERSITIES_ITEM_19: `Wonkwang `,
  PARTNERS_UNIVERSITIES_ITEM_20: `Soongeui Women's`,
  PARTNERS_UNIVERSITIES_ITEM_TYPE_1: `University`,
  PARTNERS_UNIVERSITIES_ITEM_TYPE_2: `University of Korea`,
  PARTNERS_UNIVERSITIES_ITEM_TYPE_3: `National University`,
  PARTNERS_UNIVERSITIES_ITEM_TYPE_4: `University of Science&Technology`,
  PARTNERS_REQUEST_PARTNERSHIP_1: `Request`,
  PARTNERS_REQUEST_PARTNERSHIP_2: `Partnership`,

  //* Apply-career-form
  APPLY_FORM_LABEL_RESUME: 'Resume/CV',
  APPLY_FORM_LABEL_COVER_LETTER: 'Cover Letter',
  APPLY_FORM_LABEL_PORTFOLIO: 'Portfolio',
  APPLY_FORM_TEXT: 'Add File',
  APPLY_FORM_FILE_SIZE10_ALERT: 'We only support less than 10mb file size.',
  APPLY_FORM_FILE_SIZE50_ALERT: 'We only support less than 50mb file size.',
  APPLY_FORM_LINKEDIN_LABEL: 'Linkedin Profile',
  APPLY_FORM_WEBSITE_URL: 'Website URL',
  APPLY_FORM_CHECKBOX_LABEL_1: 'By submitting this form, I admit that I agree to the ',
  APPLY_FORM_CHECKBOX_LABEL_2: 'Candidate Data Policy',
  APPLY_FORM_CHECKBOX_LABEL_3: ` and I’d like to receive more information about Genesis lab.`,
  APPLY_SUBMIT_LABEL: 'Submit Application',
  APPLY_FORM_RESUME_LABEL: 'Resume',
  APPLY_SUCCESS: 'Apply Success.',
  APPLY_FAIL: 'Apply Fail.',
  APPLY_UPLOADING_MESSAGE: 'Now Uploading ...',

  //* Team 페이지
  TEAM_HEADER_TITLE: `Team`,
  TEAM_HEADER_DESC_DEFAULT: `Most of us have strong background in AI technology and we're familiar with journey from technical innovation and product launch. We are ready to tackle challenges to achieve Interactive AI at any risks.`,
  TEAM_HEADER_DESC_1: `Most of us have strong background in AI technology and`,
  TEAM_HEADER_DESC_2: `we're familiar with journey from technical innovation to`,
  TEAM_HEADER_DESC_3: `product launch. We are ready to tackle challenges to`,
  TEAM_HEADER_DESC_4: `achieve Interactive AI at any risks.`,
  TEAM_TEAMS_TITLE: `Teams`,
  TEAM_TEAMS_DESC: `We work on the following work cycle and success comes from dedicated collaboration between different fields. Learn more about our teams and people below.`,
  TEAM_ADVISORS_TITLE: `Advisors`,
  TEAM_ADVISORS_DESC: `Genesis Research is a group comprised of best academic scientists in AI field to help and guide our research.`,
  TEAM_ADVISORS_MODULE_NAME_1: `Sungbin Lim`,
  TEAM_ADVISORS_MODULE_DESC_1: `Assistant Professor, Artificial Intelligence Graduate School & Department of Industrial Engineering @UNIST`,
  TEAM_ADVISORS_MODULE_DEGREE_1: `Ph.D., Mathematics, Korea University`,
  TEAM_ADVISORS_MODULE_NAME_2: `Jaegul Choo`,
  TEAM_ADVISORS_MODULE_DESC_2: `Associate Professor, Graduate School of Artificial Intelligence @KAIST`,
  TEAM_ADVISORS_MODULE_DEGREE_2: `Ph.D., Computational Science and Engineering, Georgia Tech`,
  TEAM_ADVISORS_MODULE_NAME_3: `Sungchul Choi`,
  TEAM_ADVISORS_MODULE_DESC_3: `Associate Professor, Department of Industrial Engineering @Gachon University`,
  TEAM_ADVISORS_MODULE_DEGREE_3: `Ph.D., Industrial Management Engineering, POSTECH`,
  TEAM_ADVISORS_MODULE_NAME_4: `Hyunseok Min`,
  TEAM_ADVISORS_MODULE_DESC_4: `AI Team Leader @Tomocube`,
  TEAM_ADVISORS_MODULE_DEGREE_4: `Ph.D., Image Processing, KAIST`,
  TEAM_ADVISORS_MODULE_NAME_5: `Hyungjoo Cho`,
  TEAM_ADVISORS_MODULE_DESC_5: `AI Researcher @Tomocube`,
  TEAM_ADVISORS_MODULE_DEGREE_5: `Master of Science, Biomedical Radiation, Seoul National University`,
  TEAM_LEADERSHIP_TITLE: 'Leadership',
  TEAM_LEADERSHIP_DESC: `Our mission is challenging, and the leadership team serves and works every part of the company to achieve our vision.`,
  TEAM_PERSON_YB: 'Young Bok Lee',
  TEAM_PERSON_DH: 'Dr. Dae Hun Yoo',
  TEAM_PERSON_JK: 'Ju Kyung Park',
  TEAM_PERSON_YB_FULLNAME: 'Young Bok Lee, YB',
  TEAM_PERSON_DH_FULLNAME: 'Dr. Dae Hun Yoo',
  TEAM_PERSON_JK_FULLNAME: 'Ju Kyung Park',
  TEAM_SNS_TWITTER: 'Twitter',
  TEAM_SNS_LINKEDIN: 'Linkedin',
  TEAM_POSITION_YB: 'Founder & CEO',
  TEAM_POSITION_DH: 'Co-Founder & Chief AI Scientist',
  TEAM_POSITION_JK: 'Co-Founder & CTO',
  TEAM_PROFILE_YB:
    'YB co-founded Genesis Lab in 2017 after successful careers in both startup and conglomerate. He graduated from University of Auckland majoring in computer science and completed a MS degree in Image Processing at KAIST. He worked at Samsung Electronics as a research engineer in technology strategy group responsible for the company’s standardizations. In 2014, he co-founded enterprise saas messenger JANDI which is now a leading firm in Asia. His strong technology background, industrial work experiences and passion to achieve the vision are the assets of the company.',
  TEAM_PROFILE_DH: `Dr. DaeHun Yoo has been working as a developer since his early 20s, and has developed dozens of software such as firmware/system/server/web/mobile software by participating in projects of various companies including Samsung SDS and HDC ICONTROLS.
  Graduating from the Department of Mathematics at Kwangwoon University, he wanted to study computer theory deeply, so he received Ph.D. in Computer Science at Kwangwoon University.
  He joined SECUCEN as a founding member to research and develop bio-electronic signatures, and he was able to pass the CISA (Certified Information Systems Auditor) exam while acquiring security expertise and machine learning technology.
  He was also participating national government project using deep learning and blockchain, but soon after YB proposed a co-founding position for Genesis Lab.
  He is a full-stack developer, has more than 10 programming languages available, and is now immersed in AI technology, learning new knowledge with constant curiosity. He is leading research at Genesis Lab from data construction strategy to deep learning model development in order to introduce AI technology of a new domain.`,
  TEAM_PROFILE_JK: `JK has 20+ years of rich experience as a programmer. Special area of experience includes video imaging, multimedia streaming where he developed the world's first DMB BIFS solution. He graduated from Kyung Hee University with MS degree in EE, Multimedia. Looking forward to working with developers to make great services.`,
  TEAM_EXPLORE_JOB_DESC_1: `We are always looking for talented people`,
  TEAM_EXPLORE_JOB_DESC_2: `who really want to research and build Interactive AI and its related services.`,
  TEAM_EXPLORE_JOB_DESC_3: `Check out our jobs below.`,
  TEAM_EXPLORE_JOB_LINK: `Open positions`,
  TEAM_CATEGORY_RESEARCH: 'Research',
  TEAM_CATEGORY_RESEARCH_DESC: `Our team work on cutting-edge AI technology. 
    Research scientist and engineers collaborate across Genesis Lab with partners and advisors to create technology that can revolutionize our way of communicating with machines.`,
  TEAM_CATEGORY_ENGINEERING: 'Engineering',
  TEAM_CATEGORY_ENGINEERING_DESC: `Engineering team builds systems and services that adopt the latest Interactive AI technology to solve real world problems. Maintaining the highest level of quality is our mission.`,
  TEAM_CATEGORY_BUSINESS: 'Business',
  TEAM_CATEGORY_BUSINESS_DESC: `Business team works with customers to understand their pain points and designs solutions with Interactive AI to enable data driven decision makings.`,
  TEAM_CATEGORY_OPERATION: `Operations`,
  TEAM_CATEGORY_OPERATION_DESC: `Our dedicated teams include experts from people development, program management, public engagement and more who work together to maintain, optimize, and nurture our culture and world-leading research.`,
  TEAM_PRINCIPLE_TITLE: 'Our Principles',
  TEAM_BENEFIT_TITLE: 'Employee Benefits',
  TEAM_PRINCIPLE_SUBTITLE: `The following principles guide our discussions and decisions every day.`,
  TEAM_BENEFIT_SUBTITLE: `We really care for our employee's growth, health and work environment.`,
  TEAM_PRINCIPLE_1_TITLE: `Vision Obsession`,
  TEAM_PRINCIPLE_2_TITLE: `Quality Obsession`,
  TEAM_PRINCIPLE_3_TITLE: `Customer Obsession`,
  TEAM_PRINCIPLE_1_DESC: `It is all about the vision. Every work is for achieving the vision and we never go off the track.`,
  TEAM_PRINCIPLE_2_DESC: `Whether it is technology or service, we strive for the highest quality we can make.\n We never stop improving ourselves.`,
  TEAM_PRINCIPLE_3_DESC: `After all, the vision, technology and service we create are for customers and society.\n We never lose trust from customers. `,
  TEAM_BENEFIT_1_TITLE: `Growth`,
  TEAM_BENEFIT_2_TITLE: `Health`,
  TEAM_BENEFIT_3_TITLE: `Environment`,
  TEAM_BENEFIT_1_DESC: `We support any self-development books, courses and seminars. \n We invest broadening members horizons and influences.`,
  TEAM_BENEFIT_2_DESC: `Healthy foods and vitamins are provided while you work.\n We provide annual medical health checkups program.
  We also have dedicated psychiatric hospital for health in mind.`,
  TEAM_BENEFIT_3_DESC: `We provide the latest software and hardware to enhance productivity.\n We have many policies and allowances to support your holidays, congratulations and condolences. `,

  //* news articles
  NEWS_ARTICLE_TITLE_1: `'AI video interview' Genesis Lab, Overwhelmed with calls from Large Firms`,
  NEWS_ARTICLE_SUBTITLE_1_1: `AI will evaluate the applicant’s ability, increase satisfaction with technology applied companies... Aims to build an interactive AI`,
  NEWS_ARTICLE_DATE_1: `Oct 23, 2020`,
  NEWS_ARTICLE_DESC_1_1: `Hiring new employees is a huge concern for companies. This is mainly because excellent individuals are the company's competitiveness. That's why companies put effort into open recruitment every year. Nonetheless, it is not easy for companies to hire appropriate employees. There are also cases of regret after hiring.\n`,
  NEWS_ARTICLE_DESC_1_2: `This originates from the lack of opportunities for companies to review many individuals. The number of applicants who have interview opportunities diminishes as applicants are initially screened with application forms. If all applicants are given the opportunity to be interviewed, companies will have a chance to find a Diamond in the rough” that they could not find in the application forms. However, in reality, this is also not easy due to the limited number of interviews. The situation will be different if there are substitutions for the interviewers.`,
  NEWS_ARTICLE_DESC_1_3: `Founded in 2017, Genesis Lab was established to alleviate companies' burden during the hiring process. They developed “Viewinter,” a solution that conducts job interviews using the videos. Viewinter conducts video interviews, and an AI, which has acknowledged the right talents of a company with deep learning technology, asks questions on behalf of the interviewer.`,
  NEWS_ARTICLE_DESC_1_4: `During the interview process, Viewinter analyzes not only the behavior, knowledge, and skills of the applicant, but also the verbal and non-verbal factors such as attitude and speech which is converted into data of the applicant's ability.`,
  NEWS_ARTICLE_DESC_1_5: `Currently, companies are using data that has been first verified through Viewinter as a reference material before face-to-face interviews. In the future, Genesis Lab anticipates that if credibility in the technology is accumulated through the verification process, the era of will start of hiring with only the Viewinter instead of face-to-face interviews.`,
  NEWS_ARTICLE_DESC_1_6: `Young-bok Lee, CEO of Genesis Lab (presented in the photo), said, “With Viewinter, companies can significantly increase the number of interviewees in the recruitment process. It continues to learn with deep learning to select the best candidates.”`,
  NEWS_ARTICLE_DESC_1_7: `As Genesis Lab's solution began to gain word-of-mouth, leading investors have started their bets. Venture capitalists, such as Stick Ventures, Naver, and Blue Point Partners, volunteered to become supporters of Genesis Lab.`,
  NEWS_ARTICLE_DESC_1_8: `Currently, Genesis Lab's AI video interview solution is receiving numerous proposals from large companies and public institutions. LG U+plus, the CJ Group, Doosan Group, and Kwangdong Pharmaceuticals are already using Viewinter in their hiring process. As the number of cases in which the applicants are recommended by Viewinter matches the talents needed by the companies, companies are lining up to adopt the Genesis Lab solution.`,
  NEWS_ARTICLE_DESC_1_9: `CEO Lee said, “For one conglomerate, there was a case in which the first priority applicant recommendation by Viewinter later came in as first place in the hiring process. Even in other companies, there were cases in which the 2nd applicant recommendation by Viewinter came in as first place in the hiring process. As these cases grow, the credibility towards our data is growing as well.”`,
  NEWS_ARTICLE_DESC_1_10: `Genesis Lab has built not only an AI video interview solution for companies, but also a solution for job seekers to practice mock interviews through AI. Job seekers who stand out in mock interviews are sometimes recommended to companies as new recruits.`,
  NEWS_ARTICLE_DESC_1_11: `Universities are highly interested in the solution because they produce outstanding individuals. 30 universities have already adopted solutions to help students seek jobs. Genesis Lab has an ambition to introduce it to 100 additional universities by next year.`,
  NEWS_ARTICLE_DESC_1_12: `CEO Lee said, “Our goal is to develop the solution so that applicants can be verified as thoroughly as possible in the short term. Technically, we will advance the AI recommendation question model and AI’s text-to-video (TTV) by 2022. We will attract public institution clients as much as possible by next year."`,
  NEWS_ARTICLE_DESC_1_13: `He added, “Our ultimate goal is to create an interactive AI that enables humans and devices to sympathize and communicate with each other. When the interactive AI is realized, there will be endless areas for it to be applied to not only the HR field currently being applied, but also other fields such as medical, education and etc.”`,

  NEWS_ARTICLE_TITLE_2: `[e-terview] Genesis Lab “AI will interview you”`,
  NEWS_ARTICLE_SUBTITLE_2_1: `AI video interview technology startup... Utilized by LG U+ and LG Electronics`,
  NEWS_ARTICLE_SUBTITLE_2_2: `LG will expand it to other affiliates... Samsung and SK are also discussing utilization`,
  NEWS_ARTICLE_SUBTITLE_2_3: `“Provides customized engines for each company…Able to understand the psychology of current employees”`,
  NEWS_ARTICLE_DATE_2: `Nov 24, 2019`,
  NEWS_ARTICLE_DESC_2_1: `Young-bok Lee, CEO of Genesis Lab, introduces AI video analysis technology held at Naver D2SF office in Gangnam, Seoul in June. (Image=Naver)`,
  NEWS_ARTICLE_DESC_2_2: `[eDaily Reporter Gwangbum Han] “AIs can grasp communication skills and social skills through interviews”`,
  NEWS_ARTICLE_DESC_2_3: `Young-bok Lee, CEO of Genesis Lab, which is being spotlighted with its AI video interview technology, explained this technology in an interview with e-daily on the 22nd. Genesis Lab's AI video interview technology, “Viewinter HR,” was actually utilized in the open recruitment of new employees in the second half of this year by LG U+ and LG Electronics. It is a technology in which the AI analyzes the video interview of the applicant in real time and analyzes facial expressions, attitudes, and speaking ability, which are non-verbal signals.`,
  NEWS_ARTICLE_DESC_2_4: `“Through the AI interview, we can find out whether the applicant is lacking in communication skills or attitude, and etc.,” said CEO Mr. Lee. However, AI interviews are still used mainly as a reference. He said, "It's currently not at the level that the company can select the individuals it wants, but at least it can be used to filter out the 'subgroup' people that don't fit with the company."`,
  NEWS_ARTICLE_DESC_2_5: `Nevertheless, CEO Mr. Lee pointed out that, “At present, no AI technology can replace in-person interviews such as job-related knowledge or expertise.”`,
  NEWS_ARTICLE_DESC_2_6: `Genesis Lab's AI video interview technology can be changed into a different engine for each company. CEO Mr. Lee said, “Each company has different hiring standards. When creating engines for each company, the corporate HR team participates in creating a deep network based on their recruitment data.”`,
  NEWS_ARTICLE_DESC_2_7: `AI video interview technology is not just used in the hiring process. CEO Mr. Lee said, “In the future, Viewinter HR can be used in the video psychological evaluation of employees. It can also be used for employee presentations or coaching sales skills.”`,
  NEWS_ARTICLE_DESC_2_8: `It is reported that the Genesis Lab AI video interview technology, which was utilized in the second half of this year to recruit new employees at LG U+ and LG Electronics, will be expanded to other affiliates of the LG Group starting next year. Currently, the Samsung Group and SK Group are considering introducing this technology in their hiring process. Genesis Lab is currently focusing on targeting conglomerates. CEO Mr. Lee said, “If the AI video interview technology is advanced through cooperation with conglomerates, and the technology is verified, it can become a better reference in the domestic and international markets. Then, the global market entry will also be accelerated.”`,
  NEWS_ARTICLE_DESC_2_9: `In addition, B2C (consumer business) services are also in operation. When a user shoots and uploads a video on the 'Viewinter' web or app, the AI analyzes △recruitment recommendation index, △favorability index △communication ability △friendliness, and informs users about habits that users did not know, or evaluates facial expressions.`,
  NEWS_ARTICLE_DESC_2_10: `CEO Mr. Lee said with confidence that, “By introducing the Big Five psychological indicator, personalities can be analyzed through impressions, or rankings with other applicants can be shown. It will be a good tool for job seekers who do not have an interview practice environment.”`,
  NEWS_ARTICLE_DESC_2_11: `CEO Mr. Lee, who worked at Samsung Electronics and co-founded the group messaging service group 'JANDI' in the past, founded Genesis Lab in 2017. Genesis Lab has attracted investments from Blue Point Partners, Government Startup Support Program Tips (TIPS), and Naver's D2 Startup Factory (D2SF).`,

  NEWS_ARTICLE_TITLE_3: `Sang-hwan Yang, Naver D2SF Leader "Will expand the Technology Startup Pie"… Host AI·Autonomous Driving Demonstration Day`,
  NEWS_ARTICLE_DATE_3: `May 30, 2019`,
  NEWS_ARTICLE_DESC_3_1: `On the 30th, Naver D2 Startup Factory (D2SF) held a demo day to attract follow-up investments and partnerships from technology startups that have invested and supported them.\nMore than 100 people from venture capitals(VCs), corporate officials, and startups attended this event, held at D2SF Lounge in Gangnam-gu, Seoul.`,
  NEWS_ARTICLE_DESC_3_2: `The 5th D2SF Demo Day was attended by 4 startup teams in the field of AI and autonomous driving, including Doing Lab, Mobil Tech, Makinarax, and Genesis Lab. These startups are expanding their businesses in recently spotlighted fields such as healthcare, mobility, industrial process, and video interviews.`,
  NEWS_ARTICLE_DESC_3_3: `Sang-hwan Yang, the leader of Naver’s D2SF, said, "Growing along with technology startups and contributing to the ecosystem was the goal at the time of the launch of D2SF. Thanks to the support of many people, D2SF is also building achievements, and the technology startup ecosystem is also growing.”\nHe continued, "In Korea, startups are still not a part of the mainstream, but we want to share their achievements by expanding the pie of the technology startup industry. "D2SF will continue to cooperate with more startups, technology investors and companies."\nSang-hwan Yang, Naver’s D2SF leader is giving a greeting at the Demo Day held at the D2SF Lounge in Gangnam-gu, Seoul on the 30th./Report Jungmin Lee\nDoing Lab is currently operating business in the digital healthcare market with technology that helps healthcare. At this event, Doing Lab showed a real-time demonstration of a technology that analyzes all the foods and their nutrients in photos at once when food is taken with a smartphone camera. \nMobiltech unveiled an integrated cognitive system and base technology optimized for autonomous shuttles. Mobiltech, which has attracted investment from Naver and Hyundai Motor Company, is building cooperative relationships with domestic and overseas sensor companies and autonomous vehicle platform companies.`,
  NEWS_ARTICLE_DESC_3_4: `Machinarax disclosed their results of applying its own AI algorithm and solution to global companies in the semiconductor, vehicle, and energy fields. On the demonstration day site, they introduced the process of detecting and analyzing anomalies in real time by reproducing robotic arm work.\nGenesis Lab showed a real-time demonstration of the technology that AI analyzes when job seekers take an interview practice video. It also unveiled 'Viewinter', an interview practice service for job seekers, and B2B products that can be applied to the hiring process of companies.`,
  NEWS_ARTICLE_DESC_3_5: `Young-bok Lee, CEO of Genesis Lab, said, "After the presentation, many people came to the booth to experience the product, and they shared our thoughts in that our technology can be expanded to the entire face-video market. We look forward to building close partnerships on both sides of B2C and B2B.”`,

  NEWS_ARTICLE_TITLE_4: `Genesis Lab’s AI video interview technology utilized by LG Electronics and LG U+ recruitment`,
  NEWS_ARTICLE_DATE_4: `Nov 12, 2019`,
  NEWS_ARTICLE_DESC_4_1: `Genesis Lab announced on November 12th that its AI video interview technology was utilized to the open recruitment of new employees by LG U+ and LG Electronics in the second half of 2019.`,
  NEWS_ARTICLE_DESC_4_2: `Genesis Lab is a technology start-up that has developed a video AI interview solution that can be applied to job hunting, hiring, and advanced education. Genesis Lab's 'Viewinter' is a service in which an AI analyzes and evaluates interview videos when they are taken. It developed and applied a multimodal emotion recognition technology that integrates and analyzes facial expressions, voices, and gestures.`,
  NEWS_ARTICLE_DESC_4_3: `The 'Viewinter HR Solution', applied to LG U+ and LG Electronics, is an AI interview service for enterprises, and Genesis Lab, with LG U+, has developed a video AI interview solution that customizes to each company.`,
  NEWS_ARTICLE_DESC_4_4: `LG U+ and LG Electronics explained, "Genesis Lab's Viewinter AI video interview technology improves efficiency by reducing the interview time and cost of existing large companies and the troubles of the interviewees. If more than 40% of the subjects can be verified through an AI video interview, about 2,400 hours can be saved for screening 2,000 people.”`,
  NEWS_ARTICLE_DESC_4_5: `Genesis Lab explained, “As more data on candidates can be identified, an in-depth face-to-face interview can be conducted. The advantage of AI video interviews is that gender, region, school of origin, and acquaintances do not affect the interview, so that additional objectivity can be secured.”`,
  NEWS_ARTICLE_DESC_4_6: `Young-bok Lee, CEO of Genesis Lab, said, "A lot of changes are taking place in the recruitment process, such as the expansion of AI video interviews in Korea. Based on this cooperation, we plan to optimize Viewinter's AI video interview analysis technology to the ideal talents of each company.”`,
  NEWS_ARTICLE_DESC_4_7: `Meanwhile, Genesis Lab has attracted investment from Blue Point Partners and Naver D2 Startup Factory in recognition of the uniqueness and value of the technology.`,

  NEWS_ARTICLE_TITLE_5: `Hyundai Mobis, The Automotive AI Virtual Assistant now perceives emotions`,
  NEWS_ARTICLE_SUBTITLE_5_1: `This technology actively controls multimedia and interior lighting by analyzing the facial expressions and voice patterns of multiple car passengers with AI-based “Emotion Recognition Info-tainment control” technology.`,
  NEWS_ARTICLE_DATE_5: `Oct 11, 2018`,
  NEWS_ARTICLE_DESC_5_1: `This technology is a method that identifies more than 70 characteristic points of the passenger's facial area such as eyebrows, nose, lips, etc., and at the same time collects and analyzes the emotional state contained in the speaker's voice.`,
  NEWS_ARTICLE_DESC_5_2: `It was announced on the 6th that 'Dragon Drive', which is Nuance Communications’ AI Virtual Assistant for interactive vehicles, has integrated with the global leader in the field of artificial intelligence emotional intelligence Affectiva’s “Affectiva Automotive AI”, an automotive emotional intelligence platform. (Refer to the article on September 10, 2018)`,
  NEWS_ARTICLE_DESC_5_3: `In particular, Affectiva's automotive emotional intelligence platform, 'Affectiva Automotive AI', understands sophisticated emotional states from the faces and voices of drivers and its passengers. It also detects the degree of anger, expressions of laughter and yawning, blindfolding and blinking speed, and physical distraction(gestures). The system uses computer vision, machine learning and deep learning to learn algorithms to classify these emotions. In addition, the emotion database was analyzed with about 6 million faces in 75 countries, 5,313,751 facial videos and 38,944 hours of data. Currently about 2 billion face frames are analyzed and displayed.`,
  NEWS_ARTICLE_DESC_5_4: `Genesis Lab in Korea introduced a technology in which AI (virtual assistant) analyzes the driver's emotions and plays an appropriate song or changes the interior lighting of the vehicle, and Linkflow demonstrated a technology for passengers to enjoy video content such as virtual shopping and music videos by using the window of a vehicle as a large display screen on the 11th.`,
  NEWS_ARTICLE_DESC_5_5: `In particular, Genesis Lab's driver face recognition technology can be used as a safety technology such as preventing drowsy driving and detecting drunk driving. As such, both image and voice recognition technologies based on AI are highly utilized as innovative technologies in the future automobile market.`,
  NEWS_ARTICLE_DESC_5_6: `Hyundai Mobis (CEO Young-deuk Lim) announced on the 11th that it will review collaboration and joint development after a final technology demonstration for two promising startups in Korea (Genesis Lab and Linkflow) that were recently selected in the M.Start competition. This is to proactively respond to future car technology changes by incorporating innovative ideas from startups with Hyundai Mobis' automotive technology. M.Start is a program that Hyundai Mobis discovers and fosters excellent domestic startups, and the competition began at the end of last year. A total of 155 start-ups participated, and were evaluated on their innovation and commercialization potential. The two finalists were selected, and went through the technology development process for 7 months from March this year.`,
  NEWS_ARTICLE_DESC_5_7: `Kyung-kook Baek, executive manager in charge of research and planning at Hyundai Mobis emphasized that, “Collaboration with promising startups is aimed to acquire innovative technology that the market wants in advance. We will lead the market with advanced technologies by establishing broader collaboration relationships with promising startups in the domestic and overseas market.”`,
  NEWS_ARTICLE_DESC_5_8: `In the demonstration that day, Genesis Lab played music that was automatically selected according to the emotions of the passengers. This is a technology that enables cars to actively control multimedia and interior lighting by analyzing facial expressions and voice patterns of multiple car passengers with AI-based “emotional recognition info-tainment control” technology. This technology is a method that identifies more than 70 characteristic factors of the passenger's facial area such as eyebrows, nose, lips, etc., and at the same time collects and analyzes the emotional state of the speaker's voice. These detection algorithms operate based on deep learning that simulates human thinking systems, and thus show a higher success rate as the AI’s experience accumulates.`,
  NEWS_ARTICLE_DESC_5_9: `In fact, Genesis Lab possesses a boasting technology in the field of emotion recognition. It has a high recognition rate at a global level and, above all, excellent data processing capability. It realizes the same performance while lowering the data usage for emotion recognition by more than 90% compared to other companies. A Genesis Lab official said, “In addition to info-tainment, the emotion recognition technology can be linked to vehicle safety technologies such as detecting driving under influence and drowsy driving.” In the past, Genesis Lab has been recognized for developing the “AI Interviewer” which analyzes and corrects interviewee behavior through facial recognition and voice recognition. Currently, Genesis Lab has developed “Viewinter.Ai,” which analyzes interview posture based on facial recognition and voice recognition, and is being provided to Baidu in China.`,
  NEWS_ARTICLE_DESC_5_10: `Meanwhile, with this agreement between the two companies, the next-generation HMI and interactive automotive virtual assistant will have a significant impact on road safety and transportation environments in a situation where not only efficiency is important for building next-generation automotive assistance devices for next-generation connected cars and autonomous vehicles, but also integration of the interaction method is essential for safety. In the future, solutions are expected to address safety-related user cases, especially for cars in the autonomous vehicle spectrum.`,

  NEWS_ARTICLE_TITLE_6: `With the rise of COVID-19… Contactless ‘AI Interviews’ Surge`,
  NEWS_ARTICLE_DATE_6: `Mar 31, 2020`,
  NEWS_ARTICLE_SUBTITLE_6_1: `Last year’s 200 companies has grown to 300 this year `,
  NEWS_ARTICLE_SUBTITLE_6_2: `46% of Job Seekers “Better than face-to-face” `,
  NEWS_ARTICLE_SUBTITLE_6_3: `Before COVID-19, twice as much than 24%`,
  NEWS_ARTICLE_DESC_6_1: `With the spread of the COVID-19, a new recruitment method using artificial intelligence(AI) is being spotlighted. As the demand for contactless recruitment increases rapidly, companies adopting AI interviews that can save time as well as accurately judge job suitability are increasing at a rapid pace.`,
  NEWS_ARTICLE_DESC_6_2: `The AI interview solution providing firm, Midas-in, said on the 31st that the number of companies that adopted the company's AI competency test increased from 200 at the end of last year to 300 this year. Recently, Kyungil University installed an AI interviewer program for the first time in the region, and Shinhan Aitas is the first in the capital market industry to employ AI competency tests to recruit new employees.`,
  NEWS_ARTICLE_DESC_6_3: `AI interviews are useful when a large number of people must be recruited such as in the 1st interview, and when accurate and quick judgments matching the company's talent, etc. are needed only with videos. Based on the video in which the applicant introduces himself/herself and answers questions, AI analyzes non-verbal elements such as facial expression changes and voice tone, and scores communication skills and attitudes.`,
  NEWS_ARTICLE_DESC_6_4: `Previously, only a few large companies such as LG Electronics and LG U+ utilized this program, but most companies are considering introducing them due to the recent contactless recruitment trend.`,
  NEWS_ARTICLE_DESC_6_5: `Young-bok Lee, CEO of Genesis Lab, which provides an AI interview system to LG Electronics and LG U+, said, “There is a trend to introduce non-face-to-face interviews to companies that have stopped recruiting due to COVID-19, and inquiries regarding AI interviews have increased. The number of users of the video interview service that is provided for free has significantly increased, and I anticipate that the demand to spread to AI interviews which are effective for large-scale recruitment.”`,
  NEWS_ARTICLE_DESC_6_6: `Job-seekers are also increasing their preference for AI interviews due to the spread of COVID-19. The Employment Platforms, Job Korea and Albamon, surveyed 1,951 job seekers for 'preferred interview types', and 46.2% of the job seekers who participated in the survey preferred AI interviews to interviews conducted by interviewers after COVID-19. This number has nearly doubled from 24.4% of the AI interview preference rate before the outbreak of COVID-19.`,
  NEWS_ARTICLE_DESC_6_7: `As for the reason for preferring AI interviews(multiple-responses), they answered, ‘because it is safe to have an interview without direct contact considering the COVID-19 situation’, ranked first with 30.9%. It was followed by △'It is less tense than a in-person interview, so mistakes are less likely to occur’(26.2%) △'Can be evaluated without prejudice and bias of the interviewer' (23.3%) △'It is possible to reduce the cost of the interview, such as purchasing suits for interviews and transportation expenses' (11.4%) and △'Because I am not compared to other interviewees, it is possible to interview without frustration' (9.7%).`,

  NEWS_ARTICLE_TITLE_7: `Analyzes Aptitude and Competence without biases… An AI Interviewer who “knows how to evaluate humans”`,
  NEWS_ARTICLE_SUBTITLE_7_1: `“The AI Video Interview solution has been now introduced to over 40 Enterprises and Government Organizations”`,
  NEWS_ARTICLE_DATE_7: `Feb 2, 2021`,
  NEWS_ARTICLE_DESC_7_1_1: `“The AI interview field has already reached the level of accurately analyzing the applicant’s aptitude and potential competence with only 5 to 10 minute long video. As the AI learns the insights and experience of HR experts through Big Data, it allows neutral evaluation similar to that of an unbiased and experienced interviewer.”`,
  NEWS_ARTICLE_DESC_7_1_2: `Genesis Lab CEO Young-bok Lee(image) has recently met with Money Today and has stated that, “Our goal is to advance the AI video interview technology in 2 to 3 years so that it is capable of asking questions, answering questions and asking additional questions on its own.”`,
  NEWS_ARTICLE_DESC_7_1_3: `Genesis Lab, founded in 2017, is a startup that has developed the AI Video Interview technology ‘ViewInter HR’.`,
  NEWS_ARTICLE_DESC_7_1_4: `In ViewInter HR, AI analyzes the applicant’s interview video and shows the result of the data-based ‘quantitative evaluation’.`,
  NEWS_ARTICLE_DESC_7_1_5: `Since 2019, more and more companies have been implementing this technology, and currently many conglomerates such as LG, POSCO, SK, CJ, Doosan and several public enterprises are using this technology.`,
  NEWS_ARTICLE_PARA_TITLE_7_2: `Learn HR Expert’s ‘Experience·Insight’ through Big Data`,
  NEWS_ARTICLE_DESC_7_2_1: `There is two core analysis technology in AI Video Interview. ‘Non-verbal behavior analysis’ such as facial expressions, attitude, eye movement and etc., and ‘content analysis’ of the reply for competence evaluation. CEO Lee explained, “the two analysis technologies each searches for the necessary factors for evaluation in the external traits that are portrayed through behaviors and words said during the applicant’s response.”`,
  NEWS_ARTICLE_DESC_7_2_2: `For the non-verbal behavior analysis technology, the AI measures the ‘Soft Skills’ of the applicant through the applicant’s interview video. The Soft Skills are the widely known interpersonal relationship skills. The applicant’s 5 traits, including aptitude, personality, attitude, values, and interests, are evaluated in 9 sections of confidence, likeability, credibility, composure, proactivity, logicality, and etc.`,
  NEWS_ARTICLE_DESC_7_2_3: `In order for the AI to accurately evaluate, the AI has learned the evaluation standards from HR managing experts Conglomerates. As ‘AlphaGo’ learns how to play “Go”, the AI has learned a type of ‘answer sheet’ from the HR expert’s evaluation of various video interview results. The accumulated interview videos that have been learned up until now are 100 thousand videos.`,
  NEWS_ARTICLE_DESC_7_2_4: `The content analysis technology utilizes the ‘BEI(Behavioral Event Interviewing) Aptitude Evaluation’ method. The questions are designed to derive past experiences and behaviors, and the points by competence and the possibility of finding behavioral indexes have been converted into points. This also has been learned based on the evaluation results of domestic competence evaluation experts. CEO Mr. Lee says ‘In the AI learning process, the AI learns by itself trait information by examining the evaluation points results of experienced experts including recruiters and HR team members of actual companies. Furthermore, each company reflects different recruiting standards, so there can be different point distributed evaluations.”`,
  NEWS_ARTICLE_DESC_7_2_5: `CEO Mr. Lee underscored that he is especially being vigilant that the AI does not learn distorted biases such as gender biases, hatred, or etc. He says, “In order to prevent the AI from unintentionally learning unethical standards, we are especially being cautious of controlling the external environmental factors such as the gender proportion of the expert group.”`,
  NEWS_ARTICLE_DESC_7_2_6: `This year, they plan to expand from the current 40 companies that use their technology to over 100 domestic and global companies and organizations. CEO Mr. Lee says, “Based on the domestic service experience, this year we will challenge ourselves to expand to the global market. In the mid- and long-term, we expect to advance the video interview technology in order to expand to psychological counselling, medical examination before treatment, and career counselling.`,

  //* Privacy Policy
  PRIVACY_PAGE_TITLE: `Privacy Policy`,
  PRIVACY_CONTENT_DESC_1_1: `This Privacy Policy was last modified on 11th Nov 2020.`,
  PRIVACY_CONTENT_DESC_1_2: `Genesis Lab, Inc., is a company organised under the laws of Republic of Korea, with registered office at 5F, 73 Myeongdong-gil, Jng-gu, Seoul, Korea (“**Genesis Lab”, “us”, “we”, or “our**”).`,
  PRIVACY_CONTENT_DESC_1_3_1: `Genesis Lab is operates `,
  PRIVACY_CONTENT_DESC_1_3_2: ` (the “**Site**”). This Privacy Policy informs you of the data processing operations carried out by us in relation to the Site.`,
  PRIVACY_CONTENT_TITLE_2: `Personal data and use`,
  PRIVACY_CONTENT_DESC_2_1: `We take your privacy extremely seriously and use your personal data only for legitimate reasons and in accordance with data protection law, and any other applicable legislation, for purposes such as: providing and improving the Site, allowing you to participate in various projects implemented by us through the Site, responding to queries addressed by you through the Site, research, maintenance of accounts and records and the promotion of services.`,
  PRIVACY_CONTENT_TITLE_3: `Processing personal data of end users`,
  PRIVACY_CONTENT_DESC_3_1_1: `While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, postal address and phone number. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at `,
  PRIVACY_CONTENT_DESC_3_2: `We process your personal data for the purposes described in this Privacy Policy, based on the following legal grounds:`,
  PRIVACY_CONTENT_DESC_3_3_1: `with your consent: we may ask for your agreement to process your personal data for specific purposes and you have the right to withdraw your consent at any time; or`,
  PRIVACY_CONTENT_DESC_3_4_1: `Genesis Lab’s legitimate interests while applying appropriate safeguards that protect your privacy. This means that we may process your personal data for things like:`,
  PRIVACY_CONTENT_DESC_3_5_1: `providing, maintaining, and improving our Site; or`,
  PRIVACY_CONTENT_DESC_3_5_2: `detecting, preventing, or otherwise addressing fraud, abuse, security, or technical issues with our Site.`,
  PRIVACY_CONTENT_TITLE_4: `Log data`,
  PRIVACY_CONTENT_DESC_4_1: `Like many site operators, we collect information that your browser sends whenever you visit our Site (“Log Data”). This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser type, browser version, computer settings, the pages of our Site that you visit, how often you visit those pages, the time and date of your visits, the time spent on those pages and other statistics.`,
  PRIVACY_CONTENT_TITLE_5: `Cookies`,
  PRIVACY_CONTENT_DESC_5_1: `Cookies are files with small amounts of data, which may include an anonymous unique identifier. Cookies are sent to your computer when you visit a website and are stored on your computer’s hard drive.`,
  PRIVACY_CONTENT_DESC_5_2: `Like many websites, we may use “cookies” to collect information. These cookies enable us to understand how you use the Site and enhance your experience whilst on it. They help us to provide you with a better Site, by enabling us to monitor which pages you find useful, and those you do not. Please be assured that cookies in no way give us access to your computer or any information about you, other than the data you choose to share with us. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.`,
  PRIVACY_CONTENT_DESC_5_3: `By continuing to use our Site without changing your browser settings you consent to the use of cookies as described in this Privacy Policy.`,
  PRIVACY_CONTENT_TITLE_6: `Security`,
  PRIVACY_CONTENT_DESC_6_1: `The security of your personal data is important to us. All of the personal data described above is recorded on restricted database servers. Our suppliers use a variety of industry-standard security technologies and procedures to help protect your personal data from unauthorised access, use, or disclosure. Please remember, however, that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use appropriate and robust security measures to protect your personal data, we cannot guarantee its absolute security.`,
  PRIVACY_CONTENT_TITLE_7: `Links to other sites`,
  PRIVACY_CONTENT_DESC_7_1: `Our Site may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party’s site. Third parties may display advertisements on their sites. These advertisements may contain cookies, web beacons and other similar technologies. These third parties may collect information or insert cookies or similar technologies without our knowledge. We strongly advise you to review the privacy policy and terms and conditions of every site you visit. We have no control over, assume no responsibility for, and do not endorse, the content, privacy policies, or practices of any third party sites or services.`,
  PRIVACY_CONTENT_TITLE_8: `Retention of personal data`,
  PRIVACY_CONTENT_DESC_8_1: `Personal data will be retained for the entire period of time necessary for the performance of the purposes described in this Privacy Policy, as well as following their completion in accordance with applicable legal requirements.`,
  PRIVACY_CONTENT_TITLE_9: `Transfers`,
  PRIVACY_CONTENT_DESC_9_1: `If and when necessary or required, personal data processed for any of the purposes mentioned in this Privacy Policy may be transferred to other companies within the same group, suppliers and service providers, professional advisers and consultants, agents, survey and research organizations or to governmental or state authorities in accordance with applicable legal requirements. It may sometimes be necessary to transfer personal data overseas to other countries within the European Union/ European Economic Area or to other countries around the world. Any transfers made will be in full compliance with all applicable legal requirements.`,
  PRIVACY_CONTENT_TITLE_10: `Your rights`,
  PRIVACY_CONTENT_DESC_10_1: `Under data protection law, you have the right to request access to, update, remove, and restrict the processing of your personal data. You also have the right to object to the processing of your personal data or export your personal data to another service. Any requests should be addressed in writing to the Genesis Lab Legal Team at our registered office or at the following e-mail address: `,
  PRIVACY_CONTENT_TITLE_11: `Changes to this Privacy Policy`,
  PRIVACY_CONTENT_DESC_11_1: `We may update this Privacy Policy from time to time. We will inform you of any changes by posting the new Privacy Policy on the Site without other notice to you. You are advised to review this Privacy Policy periodically for any changes.`,
  PRIVACY_CONTENT_TITLE_12: `Contact us`,
  PRIVACY_CONTENT_DESC_12_1: `If you have any questions about this Privacy Policy, please contact us in writing at our registered address or via the e-mail address `,

  //* Term
  TERM_PAGE_TITLE: `Terms`,
  TERM_CONTENT_DESC_1_1: `This Agreement was last modified on 11th Nov 2020.`,
  TERM_CONTENT_DESC_1_2: `Please read these Terms and Conditions ("Agreement", "Terms and Conditions") carefully before using www.genesislab.ai ("the Site") operated by Genesis Lab, Inc.   ("us", "we", or "our"). This Agreement sets out the terms and conditions that apply to your use of the Site.`,
  TERM_CONTENT_DESC_1_3: `By accessing or using the Site, including, but not limited to, visiting or browsing the Site or contributing content or other materials to the Site, you agree to be bound by these Terms and Conditions and, if you are an employee or an agent acting for a third party, to bind your employer or that third party to these Terms and Conditions. Capitalised terms are defined in this Agreement.`,
  TERM_CONTENT_TITLE_2: `Intellectual Property`,
  TERM_CONTENT_DESC_2_1: `The Site and its original content, features and functionality are owned by Genesis Lab and are protected by various international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.`,
  TERM_CONTENT_TITLE_3: `Termination`,
  TERM_CONTENT_DESC_3_1: `We may suspend or terminate your access to the Site for business or operational reasons, without cause or notice, which may result in the forfeiture and destruction of all information associated with you. We will try to give you reasonable notice of any suspension or withdrawal.`,
  TERM_CONTENT_TITLE_4: `Links To Other Sites`,
  TERM_CONTENT_DESC_4_1: `Our Site may contain links to third-party sites that are not owned or controlled by Genesis Lab.Genesis Lab has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party sites or services. We strongly advise you to read the terms and conditions and privacy policy of any third-party site that you visit.`,
  TERM_CONTENT_TITLE_5: `Governing Law`,
  TERM_CONTENT_DESC_5_1: `If you are a business user, this Agreement shall be governed and construed in accordance with the laws of Republic of Korea, without giving effect to any principles of conflicts of law and you submit to the jurisdiction of the courts of Repubic of Korea. If you are a consumer user, nothing in this Agreement shall preclude you from access to the courts and laws of your place of usual residence.`,
  TERM_CONTENT_TITLE_6: `Changes To This Agreement`,
  TERM_CONTENT_DESC_6_1: `We reserve the right, at our sole discretion, to modify or replace these Terms and Conditions by providing reasonable notice of any material changes. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms and Conditions.If you do not agree to any of this Agreement or any changes to this Agreement, do not use, access or continue to access the Site or discontinue any use of the Site immediately.`,
  TERM_CONTENT_TITLE_7: `Contact us`,
  TERM_CONTENT_DESC_7_1: `If you have any questions about this Agreement, please contact us at the address below.`,
  TERM_CONTENT_DESC_7_2: `Address: Genesis Lab, Inc. a company registered in Republic of Korea (421-88-00471), 5F 73, Myeongdong-gil, Jung-gu, Seoul, Korea`,
  //* PRODUCT
  PRODUCT_VIEWINTER_HR: 'VIEWINTER HR',
  PRODUCT_VIEWINTER_HR_DESC_1: `VIEWINTER HR makes your recruitment process efficient and precise at the same time.`,
  PRODUCT_VIEWINTER_HR_DESC_2: `Prepare for the next normal of HR with a customized deep-learning AI Interview solution.`,
  PRODUCT_VIEWINTER_HR_MO_DESC: `VIEWINTER HR makes your recruitment process efficient and precise at the same time. Prepare for the next normal of HR with a customized deep-learning AI Interview solution.`,
  PRODUCT_CONTACT_SALES: `Visit Website`,
  PRODUCT_KEY_FEATURE_TITLE: 'Key Feature',
  PRODUCT_KEY_FEATURE_SUB1_TITLE: 'Softskill assessment',
  PRODUCT_KEY_FEATURE_SUB1_DESC:
    "Analyzes candidate's attitude and communication skills with hiring score predictions. (Non-verbal behavior analysis) It also provides BIG5 Personality traits analysis from video interviews.",
  PRODUCT_KEY_FEATURE_SUB2_TITLE: 'BEI (Behavior Event Interview) assessement',
  PRODUCT_KEY_FEATURE_SUB2_DESC: `Assesses competency by asking structural in-depth questions about interviewees' past behavioral events.`,
  PRODUCT_KEY_FEATURE_SUB3_TITLE: 'Question generation',
  PRODUCT_KEY_FEATURE_SUB3_DESC: `Based on the context of the candidate's video interviews, the next probing question is generated.`,

  PRODUCT_GOOD_HR_TITLE: 'What’s Good for HR?',
  PRODUCT_GOOD_HR_SUB1_TITLE: 'Customized AI Engine',
  PRODUCT_GOOD_HR_SUB1_DESC:
    "From existing AI engine, Transfer Learning is applied to customize client's qualification standards for candidates.",
  PRODUCT_GOOD_HR_SUB2_TITLE: 'Admin and Reports for HR',
  PRODUCT_GOOD_HR_SUB2_DESC: `Provides customized SaaS based solution including HR admin features and internal reports.`,
  PRODUCT_GOOD_HR_SUB3_TITLE: 'Support for Candidates',
  PRODUCT_GOOD_HR_SUB3_DESC: `Provide SaaS based mobile app (both iOS and Android) and website for candidates.`,
  PRODUCT_HOWITWORKS_TITLE: 'How it works',
  PRODUCT_HOWITWORKS_DESC:
    'A number of experts with more than 30 years of experience in HR domain are labeling interview videos.',
  PRODUCT_HOWITWORKS_LABEL: 'Learn more about our technology',
  PRODUCT_ARCHIEVE_TITLE: 'Achievements',
  PRODUCT_ARCHIEVE_PROJECT_DONE: 'Projects Done',
  PRODUCT_ARCHIEVE_PROJECT_DONE_DESC: `including the project with more than 10K candidates at once`,
  PRODUCT_ARCHIEVE_ANALYZED: `Video Interviews analyzed`,
  PRODUCT_ARCHIEVE_EFFICIENT: `Pearson Co-efficient `,
  PRODUCT_ARCHIEVE_EFFICIENT_DESC: `Highest Pearson Co-efficient between AI scores and Final results`,
  PRODUCT_SUCCESS_TITLE: 'Success Story',
  PRODUCT_SUCCESS_DESC: `Working with Genesis lab was a true pleasure.\nThe team found out the exact painpoint and enabled data-based\ndecision making with AI technology on non-quantative factors of interview.`,
  PRODUCT_SUCCESS_DESC_SUB: 'Talent Acquisition Team at LG U Plus',
  PRODUCT_PERSON_NAME: 'Yuk Geunsik',
  PRODUCT_PERSON_POSITION: 'Team Leader',
  PRODUCT_OTHER_PRODUCT_TITLE: `Other Products`,
  PRODUCT_OTHER_PRODUCT_VIEWINTER_TITLE: `VIEWINTER`,
  PRODUCT_OTHER_PRODUCT_VIEWINTER_DESC: `VIEWINTER is a SaaS based mobile app and web that provides job seekers to practice video interviews to understand the AI video interviews environments and enhance their interview skills.`,
  PRODUCT_OTHER_PRODUCT_VLINK_TITLE: `Vlink`,
  PRODUCT_OTHER_PRODUCT_VLINK_DESC: `Vlink is a fast and simple way to create video interview links for any usage. It is used for SMB companies or in schools to ask any questions in video and receive answers in video.`,
  PRODUCT_OTHER_PRODUCT_BUTTON_1: `Website (Korean)`,
  PRODUCT_OTHER_PRODUCT_BUTTON_2: `Website`,
  PRODUCT_GOT_QUESTION: 'Got Questions?',
  PRODUCT_GOT_QUESTION_SUB:
    'Any questions regarding the technology or solution, please contact us.',
  PRODUCT_GOT_QUESTION_CONTACT_US: 'Contact Us',

  // * IE 미지원
  IE_TITLE: 'The website is optimized for viewing in Chrome.',
  IE_DESC_1: `Built using HTML5 and CSS3, the website is partially unable to function in IE.`,
  IE_DESC_2: 'To make the best use of Genesis Lab services,',
  IE_DESC_3: `we recommend you use other browsers, such as Chrome.`,
  IE_DESC_4: `To make the best use of Genesis Lab services, we recommend you use other browsers, such as Chrome.`,
  IE_BUTTON: 'Download Chrome',

  SEE_ORIGINAL: 'See Original',
  SEE_ORIGINAL_KOREAN: '(Korean)',
  MESSAGE_COPIED: 'The URL has been copied.',
  SUBSCRIBE_DONE: 'You have been subscribed. Thank you.',
};
const en = {};
const Strings = new LocalizedStrings({
  en: ko,
  'en-US': ko,
  ko,
  'ko-KR': ko,
});

export default Strings;

import img_01 from 'assets/imgs/Alvideointerview.png';
// import img_02 from 'assets/imgs/e-terview.png';
import img_02 from 'assets/imgs/e-terview2.png';
import img_03 from 'assets/imgs/San-hwan.png';
import img_04 from 'assets/imgs/GenesisLabs.png';
import img_05 from 'assets/imgs/risewith.png';
import img_06 from 'assets/imgs/Hyundai.png';
import img_07 from 'assets/imgs/article_img_0.jpeg';
import Strings from 'strings';

export const articles = [
  {
    key: 0,
    thumbnail: img_07,
    time: Strings.NEWS_ARTICLE_DATE_7,
    title: Strings.NEWS_ARTICLE_TITLE_7,
    subtitle: [Strings.NEWS_ARTICLE_SUBTITLE_7_1],
    originalLink: 'https://news.mt.co.kr/mtview.php?no=2021012014280233232',
    description: [
      Strings.NEWS_ARTICLE_DESC_7_1_1,
      Strings.NEWS_ARTICLE_DESC_7_1_2,
      Strings.NEWS_ARTICLE_DESC_7_1_3,
      Strings.NEWS_ARTICLE_DESC_7_1_4,
      Strings.NEWS_ARTICLE_DESC_7_1_5,
      { type: 'title', content: Strings.NEWS_ARTICLE_PARA_TITLE_7_2 },
      Strings.NEWS_ARTICLE_DESC_7_2_1,
      Strings.NEWS_ARTICLE_DESC_7_2_2,
      Strings.NEWS_ARTICLE_DESC_7_2_3,
      Strings.NEWS_ARTICLE_DESC_7_2_4,
      Strings.NEWS_ARTICLE_DESC_7_2_5,
      Strings.NEWS_ARTICLE_DESC_7_2_6,
    ],
  },
  {
    key: 1,
    thumbnail: img_01,
    title: Strings.NEWS_ARTICLE_TITLE_1,
    subtitle: [Strings.NEWS_ARTICLE_SUBTITLE_1_1],
    time: Strings.NEWS_ARTICLE_DATE_1,
    originalLink:
      'https://thebell.co.kr/free/Content/ArticleView.asp?key=202010221458293720107512&svccode=03',
    description: [
      Strings.NEWS_ARTICLE_DESC_1_1,
      Strings.NEWS_ARTICLE_DESC_1_2,
      Strings.NEWS_ARTICLE_DESC_1_3,
      Strings.NEWS_ARTICLE_DESC_1_4,
      Strings.NEWS_ARTICLE_DESC_1_5,
      Strings.NEWS_ARTICLE_DESC_1_6,
      Strings.NEWS_ARTICLE_DESC_1_7,
      Strings.NEWS_ARTICLE_DESC_1_8,
      Strings.NEWS_ARTICLE_DESC_1_9,
      Strings.NEWS_ARTICLE_DESC_1_10,
      Strings.NEWS_ARTICLE_DESC_1_11,
      Strings.NEWS_ARTICLE_DESC_1_12,
      Strings.NEWS_ARTICLE_DESC_1_13,
    ],
  },
  {
    key: 2,
    thumbnail: img_05,
    title: Strings.NEWS_ARTICLE_TITLE_6,
    time: Strings.NEWS_ARTICLE_DATE_6,
    subtitle: [
      Strings.NEWS_ARTICLE_SUBTITLE_6_1,
      Strings.NEWS_ARTICLE_SUBTITLE_6_2,
      Strings.NEWS_ARTICLE_SUBTITLE_6_3,
    ],
    originalLink: 'http://www.munhwa.com/news/view.html?no=2020033101032630322001',
    description: [
      Strings.NEWS_ARTICLE_DESC_6_1,
      Strings.NEWS_ARTICLE_DESC_6_2,
      Strings.NEWS_ARTICLE_DESC_6_3,
      Strings.NEWS_ARTICLE_DESC_6_4,
      Strings.NEWS_ARTICLE_DESC_6_5,
      Strings.NEWS_ARTICLE_DESC_6_6,
      Strings.NEWS_ARTICLE_DESC_6_7,
    ],
  },
  {
    key: 3,
    thumbnail: img_02,
    title: Strings.NEWS_ARTICLE_TITLE_2,
    subtitle: [
      Strings.NEWS_ARTICLE_SUBTITLE_2_1,
      Strings.NEWS_ARTICLE_SUBTITLE_2_2,
      Strings.NEWS_ARTICLE_SUBTITLE_2_3,
    ],
    time: Strings.NEWS_ARTICLE_DATE_2,
    originalLink: 'https://www.edaily.co.kr/news/read?newsId=01902406622689000',
    description: [
      Strings.NEWS_ARTICLE_DESC_2_1,
      Strings.NEWS_ARTICLE_DESC_2_2,
      Strings.NEWS_ARTICLE_DESC_2_3,
      Strings.NEWS_ARTICLE_DESC_2_4,
      Strings.NEWS_ARTICLE_DESC_2_5,
      Strings.NEWS_ARTICLE_DESC_2_6,
      Strings.NEWS_ARTICLE_DESC_2_7,
      Strings.NEWS_ARTICLE_DESC_2_8,
      Strings.NEWS_ARTICLE_DESC_2_9,
      Strings.NEWS_ARTICLE_DESC_2_10,
      Strings.NEWS_ARTICLE_DESC_2_11,
    ],
  },
  {
    key: 4,
    thumbnail: img_04,
    title: Strings.NEWS_ARTICLE_TITLE_4,
    time: Strings.NEWS_ARTICLE_DATE_4,
    originalLink: 'http://www.bloter.net/archives/360807',
    description: [
      Strings.NEWS_ARTICLE_DESC_4_1,
      Strings.NEWS_ARTICLE_DESC_4_2,
      Strings.NEWS_ARTICLE_DESC_4_3,
      Strings.NEWS_ARTICLE_DESC_4_4,
      Strings.NEWS_ARTICLE_DESC_4_5,
      Strings.NEWS_ARTICLE_DESC_4_6,
      Strings.NEWS_ARTICLE_DESC_4_7,
    ],
  },
  {
    key: 5,
    thumbnail: img_03,
    title: Strings.NEWS_ARTICLE_TITLE_3,
    time: Strings.NEWS_ARTICLE_DATE_3,
    originalLink: 'https://biz.chosun.com/site/data/html_dir/2019/05/30/2019053002178.html',
    description: [
      Strings.NEWS_ARTICLE_DESC_3_1,
      Strings.NEWS_ARTICLE_DESC_3_2,
      Strings.NEWS_ARTICLE_DESC_3_3,
      Strings.NEWS_ARTICLE_DESC_3_4,
      Strings.NEWS_ARTICLE_DESC_3_5,
    ],
  },
  {
    key: 6,
    thumbnail: img_06,
    title: Strings.NEWS_ARTICLE_TITLE_5,
    time: Strings.NEWS_ARTICLE_DATE_5,
    subtitle: [Strings.NEWS_ARTICLE_SUBTITLE_5_1],
    originalLink: 'http://www.aitimes.kr/news/articleView.html?idxno=12600',
    description: [
      Strings.NEWS_ARTICLE_DESC_5_1,
      Strings.NEWS_ARTICLE_DESC_5_2,
      Strings.NEWS_ARTICLE_DESC_5_3,
      Strings.NEWS_ARTICLE_DESC_5_4,
      Strings.NEWS_ARTICLE_DESC_5_5,
      Strings.NEWS_ARTICLE_DESC_5_6,
      Strings.NEWS_ARTICLE_DESC_5_7,
      Strings.NEWS_ARTICLE_DESC_5_8,
      Strings.NEWS_ARTICLE_DESC_5_9,
      Strings.NEWS_ARTICLE_DESC_5_10,
    ],
  },
];

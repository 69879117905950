import { combineReducers, createStore } from 'redux';
import ui, { setMessage, MESSAGE_CONTROL } from './reducer/ui/ui';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { isDev } from 'utils';

export const history = createBrowserHistory();
const reducers = combineReducers({
  ui: ui,
});

const store = isDev() ? createStore(reducers, composeWithDevTools()) : createStore(reducers);

export const dispatch = store.dispatch;

export const setMessages = (payload) => dispatch(setMessage({ type: MESSAGE_CONTROL, ...payload }));

export default store;

import { forwardRef } from 'react';
import InputTypeFile from './input-type-file';
import './index.scss';

const FormFile = forwardRef(({ label, setFile, type, error, file }, ref) => {
  return (
    <div className='form-file-container' ref={ref}>
      <div className={`file-title ${error && 'error'}`}>{label}</div>
      <InputTypeFile setFile={setFile} type={type} file={file} />
    </div>
  );
});

export default FormFile;

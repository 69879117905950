import { useEffect } from 'react';

let timer;
const useScroll = ({ ref, sensor, showOnce = true, location = 70, pathname, contentLength }) => {
  //* ref: 스크롤 이펙트 달릴 elem들의 container
  //* sensor: undefined- 부모 위치 보정 X, 값O- 부모 위치 보정 O, 특정 값이 들어오면 그 값만의 로직 실행
  //* showOnce: 반대 방향으로 스크롤 시 숨길지 말지 결정
  //* location: 스크롤 바닥에 걸리는 위치 보정치
  //* pathname, contentLength: 변경시 useEffect 한번 더 실행

  useEffect(() => {
    const refElem = ref?.current;
    const refChildren = refElem?.children;
    let offsets = [];

    if (refElem && refChildren) {
      for (let element of refChildren) {
        if (sensor === 'news-detail') {
          offsets.push(element.offsetTop + 50);
        } else {
          offsets.push(
            !sensor ? element.offsetTop : element.offsetTop + element.offsetParent.offsetTop
          );
        }
      }

      const handleScroll = () => {
        const scrollHeight = window.scrollY + window.innerHeight; // 현재 스크롤위치

        if (sensor === 'news-detail' && refElem.offsetParent) {
          let newOffsets = offsets.map((height) => height + refElem.offsetParent.offsetTop);
          let showingIndex = newOffsets.filter((offset) => scrollHeight >= offset + 50);
          let totalRefCount = offsets.length - 1;

          // * 1. showingIndex가 없으면 (맨위에 있을 시) 모두 show 를 없앤다.
          if (showingIndex.length <= 0 && !showOnce) {
            for (let i = 0; i < totalRefCount; i++) {
              refChildren[i] && refChildren[i].classList.remove('show');
            }
          }
          // * 2. showingIndex 만큼 show 를 추가해주고
          for (let i = 0; i < showingIndex.length; i++) {
            refChildren[i] && refChildren[i].classList.add('show');
            if (!showOnce) {
              // * 3. showingIndex 말고는 show 를 제거해준다.
              for (let j = i + 1; j < totalRefCount + 1; j++) {
                refChildren[j] && refChildren[j].classList.remove('show');
              }
            }
          }
        } else {
          const showingIndex = offsets
            .map((offset, i) => {
              if (scrollHeight >= offset + location) {
                return String(i);
              } else {
                return undefined;
              }
            })
            .filter((elem) => elem); // 화면에 보이는 부분 인덱스
          const disappearIndex = offsets
            .map((offset, i) => {
              if (scrollHeight <= offset + location) {
                return String(i);
              } else {
                return undefined;
              }
            })
            .filter((elem) => elem); // 안보이는 부분 인덱스

          showingIndex.forEach((index) => {
            if (refChildren[Number(index)]) {
              refChildren[Number(index)].classList.add('show');
            }
          });

          if (!showOnce) {
            disappearIndex.forEach((index) => {
              if (refChildren[Number(index)]) {
                refChildren[Number(index)].classList.remove('show');
              }
            });
          }
        }
      };

      window.addEventListener('scroll', handleScroll);

      timer = setTimeout(() => {
        handleScroll();
      }, 800);

      return () => {
        window.removeEventListener('scroll', handleScroll);
        clearTimeout(timer);
      };
    }
  }, [ref, sensor, location, showOnce, pathname, contentLength]);
};

export default useScroll;

class GAclass {
  constructor() {
    this.gtag();
  }

  gtag() {
    let w = (window.dataLayer = window.dataLayer || []);
    w.push(arguments);
  }

  submit() {
    let gaId = 'UA-110150195-1';
    this.gtag('js', new Date());
    this.gtag('config', gaId);
  }
}

export default new GAclass();

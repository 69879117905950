import { useEffect, Suspense, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { viewChange } from '_redux/reducer/ui/ui';
import { Router, Route, Switch, BrowserRouter } from 'react-router-dom';
import {
  Main,
  About,
  News,
  NewsDetail,
  Privacy,
  Term,
  Contact,
  TechBlog,
  TechBlogDetail,
  Career,
  CareerApply,
  Partners,
  ResearchArea,
  ApplicantPolicy,
  Team,
  ViewInterHr,
} from 'pages';
import Layout from 'layouts';
import Loading from 'components/loading';
import './index.scss';

const App = ({ history }) => {
  const dispatch = useDispatch();
  const { ui, showMenu, message } = useSelector((state) => state.ui);

  const resizeFunc = useCallback(
    (e) => {
      let deviceWidth = e.currentTarget.innerWidth;
      dispatch(viewChange(deviceWidth));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(viewChange(window.innerWidth));
    window.addEventListener('resize', resizeFunc);

    return () => {
      window.removeEventListener('resize', resizeFunc);
    };
  }, [dispatch, resizeFunc]);

  return (
    <Router history={history}>
      <BrowserRouter>
        <Layout ui={ui} showMenu={showMenu} message={message}>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path='/' component={Main} />
              <Route path='/about' component={About} />
              <Route path='/news' component={News} />
              <Route path='/news-detail/:newsnumber' component={NewsDetail} />
              <Route path='/privacy' component={Privacy} />
              <Route path='/term' component={Term} />
              <Route path='/contact' component={Contact} />
              <Route path='/tech-blog' component={TechBlog} />
              <Route path='/tech-blog-detail/:articlenumber' component={TechBlogDetail} />
              <Route path='/career' component={Career} />
              <Route path='/career-apply' component={CareerApply} />
              <Route path='/partners' component={Partners} />
              <Route path='/research-area' component={ResearchArea} />
              <Route path='/applicant-policy' component={ApplicantPolicy} />
              <Route path='/team' component={Team} />
              <Route path='/product' component={ViewInterHr} />
            </Switch>
          </Suspense>
        </Layout>
      </BrowserRouter>
    </Router>
  );
};

export default App;

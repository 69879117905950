import { Fragment } from 'react';
import Logo from 'layouts/header/logo';
import { DownloadButton } from 'components';
import { useDispatch } from 'react-redux';
import { subMenuControl } from '_redux/reducer/ui/ui';
import { setMessages } from '_redux';
import { Icons } from 'styles';
import { matchLayouts, upToTop } from 'utils';
import Strings from 'strings';
import { screenSizeTypes } from 'constants/Types';
import './index.scss';

const footerMenu1 = [
  { name: Strings.FOOTER_TERMS, path: '/term' },
  { name: Strings.FOOTER_PRIVACY, path: '/privacy' },
  { name: Strings.FOOTER_BLOG, path: '/blog' },
  { name: Strings.FOOTER_CAREER, path: '/career' },
];

const footerSocialIcons = [
  {
    name: 'facebook',
    link: 'https://www.facebook.com/GenesisLab.Inc',
    icon: () => {
      return <Icons.IcFooterFacebook />;
    },
  },
  {
    name: 'youtube',
    link: 'https://www.youtube.com/channel/UCEUItacmjTBd1qbHBq-9YVA/videos',
    icon: () => {
      return <Icons.IcFooterYoutube />;
    },
  },
  {
    name: 'linkedin',
    link: 'https://www.linkedin.com/company/71666377',
    icon: () => {
      return <Icons.IcFooterLinkdin />;
    },
  },
  {
    name: 'twitter',
    link: 'https://twitter.com/GenesisLab_',
    icon: () => {
      return <Icons.IcFooterTwitter />;
    },
  },
];

const Footer = ({ ui, history, visible }) => {
  const pathname = history.location.pathname.split('/')[1];
  const dispatch = useDispatch();
  let logoWidth = matchLayouts(ui) === screenSizeTypes.BIG_SCREEN ? 120 : 100;
  let logoHeight = matchLayouts(ui) === screenSizeTypes.BIG_SCREEN ? 40 : 25;

  // const footerMenu2 = [{ name: 'Blog', path: '/blog' }];

  const controlShowFooter = () => {
    if (matchLayouts(ui) === screenSizeTypes.SMALL_SCREEN) {
      if (pathname === 'career-apply') {
        return 'not-show';
      }
    }
  };

  const DownloadPDFComponent = () => {
    return (
      <div
        className='download-pdf-component'
        onClick={() => setMessages({ showMessage: true, content: Strings.MAIN_NOT_READY })}
      >
        <DownloadButton label={Strings.FOOTER_DOWNLOAD} />
      </div>
    );
  };

  const footerMenuClick = (menuInfo, history) => {
    // console.log(menuInfo);
    if (menuInfo.name === Strings.FOOTER_BLOG) {
      window.open('https://blog.genesislab.ai/');
    } else {
      upToTop();
      history.push(menuInfo.path);
    }
  };

  const FooterMenuBar = ({ ui, history }) => {
    if (ui === screenSizeTypes.WIDESCREEN || ui === screenSizeTypes.FULLHD)
      return (
        <div className='footer-menu-container'>
          <div className='footer-menu-left'>
            {footerMenu1.map((item, idx) => (
              <div key={idx} className={`footer-menu-1 ${item.name}`}>
                <span onClick={() => footerMenuClick(item, history)}>{item.name}</span>
                <div className='under-bar' />
              </div>
            ))}
          </div>
          <div className='footer-menu-right'>
            <div className='footer-menu-2'>
              <div style={{ display: 'inline-block' }}>
                <DownloadPDFComponent />
              </div>
            </div>
            {/* 201224_kyj_주석처리, 우측으로 푸터 메뉴 탭을 작성하고 싶으면 아래 주석 해제 하고 내용을 추가하면 된다. */}
            {/* {footerMenu2.map((item, idx) => (
              <div key={idx}>
                <div className='footer-menu-2'>
                  <span onClick={() => window.open('https://blog.genesislab.ai/')}>
                    {item.name}
                  </span>
                </div>
                <div style={{ display: 'inline-block' }}>
                  <DownloadPDFComponent />
                </div>
              </div>
            ))} */}
          </div>
        </div>
      );
    else return <></>;
  };

  const FooterMenuUnderDeskTop = ({ history }) => {
    return (
      <div className='body-bottom-container'>
        <div className='footer-menu-left'>
          {footerMenu1.map((item, idx) => (
            <div key={idx} className='footer-menu-1 under-desktop'>
              <span
                className={`footer-menu-span ${item.name}`}
                onClick={() => footerMenuClick(item, history)}
              >
                {item.name}
              </span>
            </div>
          ))}
        </div>
        {/* 201224_kyj_주석처리, 우측으로 푸터 메뉴 탭을 작성하고 싶으면 아래 주석 해제 하고 내용을 추가하면 된다. */}
        {/* <div className='footer-menu-right'>
          {footerMenu2.map((item, idx) => (
            <div key={idx} className='footer-menu-2'>
              <span onClick={() => window.open('https://blog.genesislab.ai/')}>{item.name}</span>
            </div>
          ))}
        </div> */}
      </div>
    );
  };

  const GenesisLabInc = () => {
    return <div className='company-inc'>© 2021 Genesis Lab, Inc. All Rights Reserved.</div>;
  };

  const setSubMenuControl = (value) => {
    dispatch(subMenuControl(value));
  };

  const openSocialLink = (link) => {
    window.open(link);
  };

  return (
    <div className={`footer-container ${visible} ${controlShowFooter()} `}>
      <div className='footer-content'>
        <div className='footer-head-line'>
          <Logo
            width={logoWidth}
            height={logoHeight}
            history={history}
            marginRight='80px'
            setSubMenuControl={setSubMenuControl}
          />
          <FooterMenuBar ui={ui} history={history} />
        </div>
        <div className='footer-body'>
          {(ui === screenSizeTypes.DESKTOP ||
            ui === screenSizeTypes.TABLET ||
            ui === screenSizeTypes.MOBILE) && (
            <Fragment>
              <DownloadPDFComponent />
              <FooterMenuUnderDeskTop history={history} />
            </Fragment>
          )}
        </div>
        <div className='footer-bottom'>
          <GenesisLabInc />
          <div className='social-icons'>
            {footerSocialIcons.map((icon) => (
              <div
                className='social-icon'
                key={icon.name}
                onClick={() => openSocialLink(icon.link)}
              >
                {icon.icon()}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import { useState, useEffect, useRef, memo } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const HoverCursorSection = ({ backgroundColor, children, label, labelColor }) => {
  const [isCursorVisible, setIsCursorVisible] = useState(false);
  const ref = useRef();
  const cursorRef = useRef();

  const handleCursorMove = (e) => {
    const cursor = cursorRef?.current;

    if (cursor) {
      cursor.style.top = `${e.pageY - 70}px`;
      cursor.style.left = `${e.pageX - 70}px`;
      setIsCursorVisible(true);
    }
  };

  // const showCursor = (e) => {
  // setIsCursorVisible(true);
  // };

  const hideCursor = (e) => {
    setIsCursorVisible(false);
  };

  useEffect(() => {
    const container = ref.current;
    const cursor = cursorRef.current;

    if (container && cursor) {
      container.addEventListener('mousemove', handleCursorMove);
      // container.addEventListener('mouseover', showCursor);
      container.addEventListener('mouseleave', hideCursor);
    }

    return () => {
      container.removeEventListener('mousemove', handleCursorMove);
      // container.removeEventListener('mouseover', showCursor);
      container.removeEventListener('mouseleave', hideCursor);
    };
  }, []);

  return (
    <div className='hover-cursor-container' ref={ref}>
      <div
        className={`cursor ${isCursorVisible ? 'visible' : 'hide'}`}
        ref={cursorRef}
        style={{
          backgroundColor: backgroundColor,
          color: labelColor,
        }}
      >
        {label}
      </div>
      {children}
    </div>
  );
};

HoverCursorSection.propTypes = {
  /** 커서의 배경색 */
  backgroundColor: PropTypes.string,
  /** 커서의 라벨 텍스트 */
  label: PropTypes.string,
  /** 커서의 라벨 색상 */
  labelColor: PropTypes.string,
  /** 커서를 나타낼 섹션 내부에 들어갈 dom 요소들 */
  children: PropTypes.node.isRequired,
};

export default memo(HoverCursorSection);

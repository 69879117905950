import { Icons } from 'styles';
import PropTypes from 'prop-types';
import './index.scss';

const DownloadButton = ({ label, onClick }) => {
  const onClickButton = () => {
    onClick && onClick();
  };
  return (
    <div className='download-btn-container' onClick={onClickButton}>
      {label && <div className='download-btn-label'>{label}</div>}
      <div className='outer-button'>
        <div className='inner-button'></div>
        <Icons.IcDownload />
      </div>
    </div>
  );
};

DownloadButton.propTypes = {
  /** 버튼의 라벨 텍스트 */
  label: PropTypes.string,
  /** 버튼을 클릭했을 때 실행되는 함수 */
  onClick: PropTypes.func,
};

export default DownloadButton;

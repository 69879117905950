import { screenSizeTypes } from 'constants/Types';

export const VIEW_CHANGE = 'view-change';
export const MOBILE_MENU_CHANGE = 'mobile-menu-change';
export const SHOW_MODAL = 'show-modal';
export const SUBMENU_CONTROL = 'submenu-control';
export const MESSAGE_CONTROL = 'message-control';

const deviceSizeCheck = (width) => {
  let naming;
  if (width <= 767) {
    naming = screenSizeTypes.MOBILE;
  }
  if (width >= 768 && width <= 1023) {
    naming = screenSizeTypes.TABLET;
  }
  if (width >= 1024 && width <= 1215) {
    naming = screenSizeTypes.DESKTOP;
  }
  if (width >= 1216 && width <= 1407) {
    naming = screenSizeTypes.WIDESCREEN;
  }
  if (width >= 1408) {
    naming = screenSizeTypes.FULLHD;
  }
  return naming;
};

export const viewChange = (width) => {
  return { type: VIEW_CHANGE, payload: deviceSizeCheck(width) };
};

export const setShowModal = (modalState) => {
  return { type: SHOW_MODAL, payload: modalState };
};

export const setMobileMenu = (value) => {
  return {
    type: MOBILE_MENU_CHANGE,
    payload: value,
  };
};

export const subMenuControl = (value) => {
  return { type: SUBMENU_CONTROL, payload: value };
};

export const setMessage = ({ showMessage, content }) => {
  return {
    type: MESSAGE_CONTROL,
    payload: { showMessage, content },
  };
};

const initState = {
  ui: deviceSizeCheck(window.innerWidth),
  showMenu: '',
  showModal: false,
  subMenuChoice: undefined,
  message: {
    showMessage: false,
    content: '',
  },
};

const ui = (state = initState, action) => {
  switch (action.type) {
    case VIEW_CHANGE: {
      return { ...state, ui: action.payload };
    }
    case MOBILE_MENU_CHANGE: {
      return { ...state, showMenu: action.payload };
    }
    case SHOW_MODAL: {
      return { ...state, showModal: action.payload };
    }
    case SUBMENU_CONTROL: {
      return { ...state, subMenuChoice: action.payload };
    }
    case MESSAGE_CONTROL: {
      return {
        ...state,
        message: {
          showMessage: action.payload.showMessage,
          content: action.payload.content,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default ui;

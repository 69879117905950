import { useState, useEffect, useRef } from 'react';
import mobileLogo from 'assets/imgs/logo-top-40-px@2x.png';
import Strings from 'strings';
import Logo from './logo';
import { LongCircleButton, HambergerButton, AnimatedText } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { setMobileMenu, subMenuControl } from '_redux/reducer/ui/ui';
import { setTimeout } from 'core-js';
import { isExistPathname, upToTop } from 'utils';
import './index.scss';
import { screenSizeTypes } from 'constants/Types';

const commonMenu = [
  {
    name: Strings.HEADER_MENU_ABOUT,
    path: '/about',
  },
  {
    name: Strings.HEADER_MENU_TECHNOLOGY,
    subMenu: [
      {
        name: Strings.HEADER_SUBMENU_RESEARCH,
        path: '/research-area',
      },
      {
        name: Strings.HEADER_SUBMENU_BLOG,
        path: '/tech-blog',
      },
    ],
  },
  {
    name: Strings.HEADER_MENU_PRODUCT,
    path: '/product',
  },
  {
    name: Strings.HEADER_MENU_TEAM,
    path: '/team',
  },
  {
    name: Strings.HEADER_MENU_PARTNERS,
    path: '/partners',
  },
  {
    name: Strings.HEADER_MENU_NEWS,
    path: '/news',
  },
  {
    name: Strings.HEADER_MENU_CAREER,
    path: '/career',
  },
];

// const mobileSubMenuHeight = 34;

const Menu = ({ ui, history, setSubMenuControl }) => {
  let { pathname } = history.location;

  const menuClick = ({ item }) => {
    if (item.subMenu) {
      setSubMenuControl(item.name);
    } else {
      setSubMenuControl(undefined);
      history.push(item.path);
    }
  };

  if (ui === screenSizeTypes.WIDESCREEN || ui === screenSizeTypes.FULLHD)
    return (
      <div className={`menu-container ${ui}`}>
        {commonMenu.map((item, idx) => (
          <div
            key={idx}
            className={`menu-content ${item.name} ${isExistPathname({ pathname, item })}
            `}
            onClick={() => menuClick({ item })}
          >
            <div className={`menu-text up`}>{item.name}</div>
            <div className='menu-text down'>{item.name}</div>
          </div>
        ))}
      </div>
    );
  else {
    return <></>;
  }
};

const GnbRightButton = ({ ui, history, closeMobileMenu, showMenu }) => {
  if (
    ui === screenSizeTypes.DESKTOP ||
    ui === screenSizeTypes.TABLET ||
    ui === screenSizeTypes.MOBILE
  ) {
    return (
      <div className='hamburger-div' onClick={() => closeMobileMenu(!showMenu)}>
        <HambergerButton />
      </div>
    );
  } else {
    return (
      <LongCircleButton
        label='CONTACT US'
        onClick={() => {
          history.push('/contact');
          upToTop();
        }}
      />
    );
  }
};

const MobileMenu = ({
  ui,
  history,
  showMenu,
  closeMobileMenu,
  additionalName,
  setSubMenuControl,
  subMenuChoice,
}) => {
  let showTimer;
  let subMenuShowTimer;
  let delayTime = 300;
  let { pathname } = history.location;
  const mobileMenuRef = useRef();
  const subMenuRef = useRef();
  const [menuTextShow, setMenuTextShow] = useState('');

  const [showSubMenu, setShowSubMenu] = useState(false); // submenu 자체가 존재해서 화면에 나타나야 할지
  const [selectedMenu, setSelectedMenu] = useState(null); // 현재 선택되어 있는 메뉴 -> 0 때문에 String 값으로 비교 필요
  const [subMenuHeight, setSubMenuHeight] = useState(0); // 서브메뉴 위치 조정 위해 높이 계산
  const [subMenuTextShow, setSubMenuTextShow] = useState(false); // show classname 붙여주기 위해

  const menuClose = (e) => {
    const target = e.target;
    const currentTarget = e.currentTarget;
    if (target === currentTarget) {
      closeMobileMenu(false);
    }
  };

  const isSubMenuExist = (isSubMenu) => {
    return isSubMenu && showSubMenu;
  };

  const moveMenus = (idx) => {
    return showSubMenu && idx > selectedMenu;
  };

  const subMenuClick = (menu) => {
    upToTop();
    history.push(menu.path);
    closeMobileMenu(false);
    setSelectedMenu(null);
  };

  useEffect(() => {
    setMenuTextShow('');
    setSubMenuTextShow(false);
    setSelectedMenu(null);
    showTimer = setTimeout(() => {
      if (showMenu) setMenuTextShow('show');
    }, delayTime);
    subMenuShowTimer = setTimeout;

    return () => {
      clearTimeout(showTimer);
    };
  }, [showMenu]);

  useEffect(() => {
    let subMenuDiv = subMenuRef.current;
    let menuContent = document.getElementsByClassName('menu-content');
    let textHeight = 48; // * 모바일 메뉴의 메뉴 각각의 높이

    if (subMenuDiv && menuContent[selectedMenu]) {
      // * 선택한 메뉴가 서브메뉴가 있을 경우
      setTimeout(() => {
        // * 메뉴 타이틀이 바뀌게 되면 delayTime 후에 등장할 수 있게 설정해놓음.
        subMenuDiv.style.top = menuContent[selectedMenu].offsetTop + textHeight + 'px';
        setSubMenuTextShow('show');
      }, delayTime);
    }
  }, [showSubMenu, selectedMenu]);

  useEffect(() => {
    setShowSubMenu(false);
    setSubMenuHeight(0);
  }, [showMenu]);

  const keyPress = (event) => {
    if (event.keyCode === 27) {
      closeMobileMenu(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyPress);
    return () => {
      window.removeEventListener('keydown', keyPress);
    };
  }, [showMenu]);

  //* 서브메뉴 있는 메뉴들은 클릭되었을 때 닫히지 않도록 하기
  //* select가 붙었고 && 서브메뉴가 있는 메뉴를 가져오기 -> 해당 메뉴의 인덱스를 알아야 함
  //* submenu가 들어갈 만큼의 height 미리 계산
  //* 선택된 메뉴의 index보다 큰 index를 가진 메뉴들은 submenu의 height 만큼 위치 아래로 이동 (열리는 효과 애니메이션)
  //* submenu를 선택된 메뉴의 offsetBottom+4 위치에 배치

  return (
    <div
      className={`mobile-menu-container ${ui} ${showMenu}`}
      onClick={menuClose}
      ref={mobileMenuRef}
    >
      <div className='mobile-menu-content'>
        {commonMenu.map((item, idx) => (
          <div key={idx} className={`menu-content ${item.name} ${additionalName}`}>
            <div
              className={`menu-text inline ${menuTextShow} ${isExistPathname({
                pathname,
                item,
                selectedMenu,
              })} ${moveMenus(idx) ? `move-down` : ''}`}
              onClick={() => {
                if (idx) {
                  setSelectedMenu(idx);
                }
                if (item.path) {
                  history.push(item.path);
                  setShowSubMenu(false);
                  closeMobileMenu(false);
                }
                if (item.subMenu) {
                  const numberOfSubMenu = item.subMenu.length;
                  const height = numberOfSubMenu * 34 + (numberOfSubMenu - 1) * 4 + 24;
                  setSubMenuTextShow('');
                  if (String(idx) === String(selectedMenu) && showSubMenu) {
                    setSubMenuHeight(0);
                    setShowSubMenu(false);
                    // setSubMenuTextShow('');
                  } else {
                    setSubMenuHeight(height);
                    setShowSubMenu(true); // 서브메뉴 보이도록 하는 state
                    // setSubMenuTextShow('');
                  }
                }
              }}
            >
              <AnimatedText text={item.name} y={150} key={idx} duration={15} delay={delayTime} />
            </div>
          </div>
        ))}
      </div>
      <div ref={subMenuRef} className={`mobile-sub-menu-container block ${subMenuTextShow} `}>
        {isSubMenuExist(commonMenu[selectedMenu]) &&
          commonMenu[selectedMenu].subMenu.map((menu, i) => (
            <div className='mobile-sub-menu-content' key={i} onClick={() => subMenuClick(menu)}>
              <AnimatedText text={menu.name} y={150} key={i} duration={15} delay={delayTime} />
            </div>
          ))}
      </div>
      <div className='contact-position'>
        <LongCircleButton
          label='CONTACT US'
          labelSize='medium'
          onClick={() => {
            upToTop();
            history.push('/contact');
            closeMobileMenu(false);
          }}
        />
      </div>
    </div>
  );
};

const LogoSelect = ({ ui, history, show, showMenu, closeMobileMenu, setSubMenuControl }) => {
  if (ui === screenSizeTypes.WIDESCREEN || ui === screenSizeTypes.FULLHD) {
    return (
      <Logo
        history={history}
        show={show}
        ui={ui}
        showMenu={showMenu}
        setSubMenuControl={setSubMenuControl}
      />
    );
  } else if (
    ui === screenSizeTypes.MOBILE ||
    ui === screenSizeTypes.TABLET ||
    ui === screenSizeTypes.DESKTOP
  ) {
    return (
      <img
        src={mobileLogo}
        className='mobile-logo'
        onClick={() => {
          history.push('/');
          closeMobileMenu(false);
          setSubMenuControl(undefined);
        }}
        alt='mobile-logo'
      />
    );
  }
};

const SubMenu = ({ ui, subMenuChoice, history, setSubMenuControl }) => {
  const submenuContentRef = useRef();
  let choicedMenu = document.getElementsByClassName(subMenuChoice)[0];
  const [show, setShow] = useState('');
  useEffect(() => {
    setTimeout(() => {
      setShow('show');
      // * 왼쪽정렬을 ref 를 통해 맞추다 보니, 왼쪽에서 번쩍할때가 있어 50ms 후에 등장하게 설정.
    }, 50);
    if (submenuContentRef) {
      submenuContentRef.current.style.left = choicedMenu.offsetLeft + 'px';
    }
  }, [subMenuChoice]);

  return (
    <div className={`submenu-container ${subMenuChoice}`}>
      <div ref={submenuContentRef} className='submenu-content'>
        {commonMenu.map((item) => {
          if (item.name === subMenuChoice && item.subMenu) {
            return item.subMenu.map((subMenu, idx) => (
              <div
                className={`submenu-item ${show}`}
                onClick={() => {
                  setSubMenuControl(undefined);
                  upToTop();
                  history.push(subMenu.path);
                }}
                key={idx}
              >
                {subMenu.name}
              </div>
            ));
          }
        })}
      </div>
    </div>
  );
};

const Header = ({ ui, history }) => {
  const [mobileLogoShow, setMobileLogoShow] = useState(true);
  const [scrollDirection, setScrollDirection] = useState('');
  const [longHeadBarShow, setLongHeadBarShow] = useState('');
  const [additionalName, setAdditionalName] = useState(false);
  const dispatch = useDispatch();
  const { showMenu, subMenuChoice } = useSelector((state) => state.ui);

  const closeMobileMenu = (boolean) => {
    upToTop();
    dispatch(setMobileMenu(boolean));
  };

  const setSubMenuControl = (value) => {
    dispatch(subMenuControl(value));
  };

  const handleScroll = (e) => {
    const headHeight = 70;
    let topLocation = window.pageYOffset || document.documentElement.scrollTop;
    if (
      ui === screenSizeTypes.MOBILE ||
      ui === screenSizeTypes.TABLET ||
      ui === screenSizeTypes.DESKTOP
    ) {
      if (headHeight <= topLocation) {
        setMobileLogoShow(false);
      } else {
        setMobileLogoShow(true);
      }
    } else if (ui === screenSizeTypes.WIDESCREEN || ui === screenSizeTypes.FULLHD) {
      if (headHeight <= topLocation) {
        if (scrollDirection === 'up') {
          setLongHeadBarShow(true);
        } else if (scrollDirection === 'down') {
          setLongHeadBarShow(false);
        }
      } else if (headHeight > topLocation) {
        setLongHeadBarShow(true);
      }
      // if(scrollDirection==='up')
    }
  };

  const wheelEvent = (e) => {
    let delta;
    if (e.wheelDelta) {
      delta = e.wheelDelta;
    } else {
      delta = -1 * e.deltaY;
    }
    if (delta < 0) {
      setScrollDirection('down');
    } else if (delta > 0) {
      setScrollDirection('up');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', wheelEvent);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', wheelEvent);
    };
  }, [scrollDirection]);

  useEffect(() => {
    setAdditionalName(!additionalName);
    if (showMenu) {
      document.getElementsByClassName('layout-container')[0].style.height = '80px';
      document.getElementsByClassName('layout-container')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByClassName('layout-container')[0].style.height = 'unset';
      document.getElementsByClassName('layout-container')[0].style.overflow = 'unset';
    }
  }, [showMenu]);

  return (
    <>
      <div className={`header-wrapper ${ui} ${longHeadBarShow}`}>
        <div className={`header-container ${ui} ${longHeadBarShow}`}>
          <LogoSelect
            history={history}
            show={mobileLogoShow}
            ui={ui}
            showMenu={showMenu}
            closeMobileMenu={closeMobileMenu}
            setSubMenuControl={setSubMenuControl}
          />
          <Menu
            ui={ui}
            history={history}
            subMenuChoice={subMenuChoice}
            setSubMenuControl={setSubMenuControl}
          />
          <GnbRightButton
            ui={ui}
            history={history}
            closeMobileMenu={closeMobileMenu}
            showMenu={showMenu}
          />
        </div>
        {subMenuChoice && (
          <SubMenu
            ui={ui}
            history={history}
            subMenuChoice={subMenuChoice}
            setSubMenuControl={setSubMenuControl}
          />
        )}
      </div>
      {showMenu && (
        <MobileMenu
          ui={ui}
          history={history}
          closeMobileMenu={closeMobileMenu}
          showMenu={showMenu}
          additionalName={additionalName}
          setAdditionalName={setAdditionalName}
          setSubMenuControl={setSubMenuControl}
          subMenuChoice={subMenuChoice}
        />
      )}
    </>
  );
};

export default Header;

// * ui : mobile, tablet, desktop, widescreen, fullhd

import { messageAnimationEndTime, messageDurationTime } from 'constants/message';
import { useEffect, useState } from 'react';
import { setMessages } from '_redux';
import PropTypes from 'prop-types';
import { screenSizeTypes } from 'constants/Types';
import './index.scss';

const initialMessage = { showMessage: false, content: '' };
let messageAnimationEndTimer;
let messageAnimationDurationTimer;

const Message = ({ message, ui }) => {
  const [animation, setAnimation] = useState('');
  useEffect(() => {
    setAnimation('');
    if (message.showMessage) {
      setAnimation('appear');

      messageAnimationEndTimer = setTimeout(() => {
        setAnimation('disappear');
      }, messageAnimationEndTime);

      messageAnimationDurationTimer = setTimeout(() => {
        setMessages(initialMessage);
      }, messageDurationTime);
    }

    return () => {
      setAnimation('');
      clearTimeout(messageAnimationDurationTimer);
      clearTimeout(messageAnimationEndTimer);
    };
  }, [message]);
  return (
    <div className={`message-container ${ui} ${animation} ${message.showMessage}`}>
      <div className='text-div'>
        <div className='message-text'>{message.content}</div>
      </div>
    </div>
  );
};

Message.propTypes = {
  /** 메시지에 들어갈 텍스트, 표시 여부를 함께 나타내는 object */
  message: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
    showMessage: PropTypes.bool,
  }).isRequired,
  /* 현재 화면 사이즈 타입 **/
  ui: PropTypes.oneOf([
    screenSizeTypes.FULLHD,
    screenSizeTypes.WIDESCREEN,
    screenSizeTypes.DESKTOP,
    screenSizeTypes.TABLET,
    screenSizeTypes.MOBILE,
  ]),
};

export default Message;

import PropTypes from 'prop-types';
import './index.scss';

//! 사용하려는 상위 태그 (AnimatedText를 감싸는 태그)에
//! className으로 'show', scss를 참고해 추가 className을 붙여준 뒤
//! props로 애니메이션 관련 데이터를 넣어주세요

//! 'show' className 관련 주의사항 -> 원래부터 붙어 있다면 애니메이션이 작동하지 않습니다(변하는 것 감지하기 떄문)
//! 관련 hook 혹은 setTimeOut 등으로 동적으로 'show' className을 붙여주세요
const AnimatedText = ({ text, isLastWordIndex, y = 100, duration = 10, delay = 0 }) => {
  return (
    <>
      <div className='text-wrapper'>
        <span
          className={`animated-text ${y ? `y-${y}` : ''} ${
            duration ? `duration-${duration}` : ''
          } ${delay ? `delay-${delay}` : ''}`}
        >
          {text}
        </span>
        {isLastWordIndex && <div style={{ width: '84px', height: '100%' }}></div>}
      </div>{' '}
      {/* //!윗줄 여백 지우지 마세요 */}
    </>
  );
};

AnimatedText.propTypes = {
  /** 표시할 텍스트 */
  text: PropTypes.string.isRequired,
  /** 마지막 글자인지 여부 (텍스트가 잘릴 경우 ... 으로 표시해주기 위한 값) */
  isLastWordIndex: PropTypes.bool,
  /** 애니메이션이 시작되기 전 텍스트의 초기 위치 */
  y: PropTypes.number,
  /** 애니메이션이 재생되는 시간 */
  duration: PropTypes.number,
  /** 애니메이션이 재생될 때까지의 시간 */
  delay: PropTypes.number,
};

AnimatedText.defaultProps = {
  y: 100,
  duration: 10,
  delay: 0,
};

export default AnimatedText;

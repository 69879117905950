import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const TextField = forwardRef(({ value, onChange, name }, ref) => {
  return (
    <div className='text-field-container'>
      <textarea
        ref={ref}
        className='text-field'
        value={value}
        onChange={onChange}
        name={name}
      ></textarea>
    </div>
  );
});

export default TextField;

TextField.propTypes = {
  /** 텍스트 필드에 입력될 value */
  value: PropTypes.string.isRequired,
  /** 값을 입력받을 때마다 실행되는 함수 */
  onChange: PropTypes.func.isRequired,
  /** 텍스트 필드의 name */
  name: PropTypes.string,
};

// import thumbNail from 'assets/imgs/pc-img-team-reserch-553-px@2x.png';
import ResearchImg from 'assets/imgs/pc-img-team-reserch-553-px@2x.png';
import EngineeringImg from 'assets/imgs/pc-img-team-en-553-px@2x.png';
import BusinessImg from 'assets/imgs/pc-img-team-business-553-px@2x.png';
import OperationsImg from 'assets/imgs/pc-img-team-operations-553-px@2x.png';
import Strings from 'strings';
export const teamData = [
  {
    key: 0,
    thumbnail: ResearchImg,
    title: Strings.TEAM_CATEGORY_RESEARCH,
    text: Strings.TEAM_CATEGORY_RESEARCH_DESC,
  },
  {
    key: 1,
    thumbnail: EngineeringImg,
    title: Strings.TEAM_CATEGORY_ENGINEERING,
    text: Strings.TEAM_CATEGORY_ENGINEERING_DESC,
  },
  {
    key: 2,
    thumbnail: BusinessImg,
    title: Strings.TEAM_CATEGORY_BUSINESS,
    text: Strings.TEAM_CATEGORY_BUSINESS_DESC,
  },
  {
    key: 3,
    thumbnail: OperationsImg,
    title: Strings.TEAM_CATEGORY_OPERATION,
    text: Strings.TEAM_CATEGORY_OPERATION_DESC,
  },
];

/*eslint-disable */
import { useState, useEffect } from 'react';

// 각각의 텍스트 라인 넘버에 따라 className 붙여주는 hook
const useTextLine = ({ ref, lineClamp, n = 1, k = 0, showTitle, isIntersecting }) => {
  //! delay 보정: n * lineCount + k

  const [lastWordIndex, setLastWordIndex] = useState();
  let lineCount = 1;

  useEffect(() => {
    const refContainer = ref.current;
    const refChildren = refContainer?.children;

    if (refContainer && refChildren) {
      for (let index in refChildren) {
        if (refChildren[index].offsetTop && refChildren[index].children[0]) {
          if (
            Number(index) > 0 &&
            refChildren[index].offsetTop !== refChildren[`${Number(index) - 1}`].offsetTop
          ) {
            lineCount += 1;

            if (lineClamp) {
              if (lineClamp + 1 === lineCount) {
                setLastWordIndex(Number(index) - 1);
              }
            }
          }
          refChildren[index].children[0].classList.add(`delay-${n * lineCount + k}`);
        }
      }
    }
  }, [showTitle, isIntersecting]);

  return { lineCount, lastWordIndex };
};

export default useTextLine;

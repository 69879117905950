import { Icons } from 'styles';
import Strings from 'strings';
import { useEffect, useRef, useState } from 'react';
import './index.scss';
import { inputFileType } from 'constants/Types';

const fileType = 'application/pdf';

const InputTypeFile = ({ type, setFile, file }) => {
  const fileRef = useRef();
  const [fileName, setFileName] = useState(null);

  useEffect(() => {
    if (!file) {
      setFileName(null);
    }
  }, [file]);

  const onChangeFile = (e) => {
    const files = e.target.files;
    // 포트폴리오 일 경우 50MB 까지
    // 52428800;
    if (files) {
      if (files[0].type === fileType) {
        if (type === inputFileType.PORTFOLIO && files[0].size >= 52428800) {
          alert(Strings.APPLY_FORM_FILE_SIZE50_ALERT);
        } else if (type !== inputFileType.PORTFOLIO && files[0].size >= 10485760) {
          alert(Strings.APPLY_FORM_FILE_SIZE10_ALERT);
        } else {
          setFileName(files[0].name);
          setFile(files[0]);
        }
      } else {
        alert(Strings.CARRER_FORM_NOT_SUPPORTED);
      }
    }
  };

  const deleteFile = () => {
    setFileName(null);
    setFile('');
  };

  return (
    <div className={`input-type-file-container ${fileName ? 'exist' : ''}`}>
      <label className='file-selector-label' htmlFor='input-file'>
        <Icons.IcAdd />
        <span>{Strings.APPLY_FORM_TEXT}</span>
      </label>
      {fileName && (
        <div className='file-name-div'>
          <div className='delete-file-div' onClick={deleteFile}>
            <Icons.IcXbutton />
          </div>
          <span>{fileName}</span>
        </div>
      )}

      <input
        ref={fileRef}
        onChange={onChangeFile}
        className='input-file'
        type='file'
        accept={fileType}
      />
    </div>
  );
};

export default InputTypeFile;

import React, { useEffect, useState, Fragment } from 'react';
import Footer from './footer';
import { withRouter } from 'react-router-dom';
import Header from './header';
import { LongCircleButton, Message } from 'components';
import Strings from 'strings';
import { useDispatch } from 'react-redux';
import { subMenuControl } from '_redux/reducer/ui/ui';
import { upToTop } from 'utils';
import GAclass from 'utils/GAclass.js';
import './index.scss';

// * 마진 없는 요소가 늘어날 때마디 추가해주세요
const pageTypes = [
  'news-detail mobile',
  'news-detail tablet',
  'career mobile',
  'career tablet',
  'tech-blog',
  'research-area',
  'about',
  'partners',
  'contact',
  'team',
  'product',
];
const keyName = 'genAllow';
const checkMargin = (pathname, ui) => {
  // * 메인일 경우 무조건 마진 없게
  if (pathname === '') {
    return false;
  }

  // * 해당 페이지의 타입이 마진 없는 요소 라스트에 있으면 false
  const type = `${pathname} ${ui}`;
  if (pageTypes.includes(type) || pageTypes.includes(pathname)) {
    return false;
  }
  return true;
};

const CookieAlert = ({ history, showMenu }) => {
  const [bannerShow, setBannerShow] = useState(localStorage.getItem(keyName) ? true : false);
  const seeDetail = () => {
    history.push('/privacy');
  };
  const okGotIt = () => {
    localStorage.setItem(keyName, true);
    setBannerShow(true);
    GAclass.submit();
  };

  if (showMenu) {
    return <Fragment></Fragment>;
  } else
    return (
      <div className={`cookie-container ${bannerShow}`}>
        <div className='cookie-content'>
          <div className='cookie-content-left'>{Strings.COOKIE_DESC}</div>
          <div className='cookie-content-right'>
            <div className='cookie-content-long-circle-button-div left'>
              <LongCircleButton label={Strings.COOKIE_DETAIL} onClick={seeDetail} />
            </div>
            <div className='cookie-content-long-circle-button-div right'>
              <LongCircleButton label={Strings.COOKIE_OK} onClick={okGotIt} />
            </div>
          </div>
        </div>
      </div>
    );
};

const Layout = ({ children, ui, history, showMenu, message }) => {
  const pathname = history.location.pathname.split('/')[1];
  const dispatch = useDispatch();
  const childrenClick = () => {
    dispatch(subMenuControl(undefined));
  };

  useEffect(() => {
    if (localStorage.getItem(keyName)) {
      GAclass.submit();
    }
  }, []);

  useEffect(() => {
    return () => upToTop();
  }, [pathname]);

  return (
    <div className='layout-container'>
      <Message message={message} ui={ui} />
      <Header ui={ui} history={history} />

      <div className={`layout-content-container ${checkMargin(pathname, ui) ? '' : 'no-margin'}`}>
        {children}
      </div>
      <Footer ui={ui} history={history} visible={showMenu === '' || !showMenu} />
      <CookieAlert history={history} showMenu={showMenu} />
    </div>
  );
};

export default withRouter(Layout);

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icons } from 'styles';
import { upToTop } from 'utils';
import { setShowModal } from '_redux/reducer/ui/ui';
import './index.scss';

const Modal = ({ children, onClickCloseButton }) => {
  const [topLocation, setTopLocation] = useState();
  const { showModal, ui } = useSelector((state) => state.ui);
  const dispatch = useDispatch();

  const modalClose = () => {
    onClickCloseButton && onClickCloseButton();
    dispatch(setShowModal(false));
  };
  useEffect(() => {
    const layoutContainer = document.getElementsByClassName('layout-container')[0];
    if (showModal) {
      setTopLocation(window.pageYOffset);
      layoutContainer.classList.add('height-zero');
      upToTop();
    } else {
      layoutContainer.classList.remove('height-zero');
      window.scrollTo({
        top: topLocation,
      });
    }
  }, [showModal]);
  return (
    showModal && (
      <div className={`modal-container ${showModal}`}>
        <div className='modal-header'>
          <span />
          <span onClick={modalClose}>
            <Icons.IcCloseButton />
          </span>
        </div>
        <div className={`modal-content ${ui}`}>{children}</div>
      </div>
    )
  );
};

export default Modal;

import { Icons } from 'styles';
import PropTypes from 'prop-types';
import './index.scss';

//! label 중간에 링크가 들어가 있는 경우는 [일반 텍스트, 링크, 일반 텍스트] 형태로 label prop을 받고 있습니다
const CheckBox = ({ label, onClick, isChecked, onClickLink, labelSize = 'small' }) => {
  const onClickLabelLink = (i) => {
    if (onClickLink && i === 1) {
      onClickLink();
    }
  };

  return (
    <div className='checkbox-container' onClick={(e) => onClick(e, label)}>
      <div className='checkbox'>{isChecked ? <Icons.CheckBoxChecked /> : <Icons.CheckBox />}</div>
      <div className={`checkbox-label ${labelSize}`}>
        {Array.isArray(label) ? (
          label.map((text, i) => (
            <span
              className={`label-item ${i === 1 ? 'link' : ''}`}
              key={i}
              onClick={() => onClickLabelLink(i)}
            >
              {text}
            </span>
          ))
        ) : (
          <span className='label-item'>{label}</span>
        )}
      </div>
    </div>
  );
};

CheckBox.propTypes = {
  /** 체크박스 라벨
   *
   * (단일 텍스트라면 string, 링크가 포함되어 있다면 array로 받아옵니다)
   * */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  /** 체크박스가 체크되었는지 여부 */
  isChecked: PropTypes.bool.isRequired,
  /** isChecked를 토글하는 함수 */
  onClick: PropTypes.func,
  /** 체크박스 라벨의 크기 */
  labelSize: PropTypes.oneOf(['small', 'big']),
  /** 링크를 클릭했을 때 실행되는 함수
   *
   * (라벨에 링크가 포함되어 있을 때만 내려받습니다)
   */
  onClickLink: PropTypes.func,
};

export default CheckBox;

import { useEffect, useRef, useState } from 'react';
import { AnimatedText } from 'components';
import { useTextLine } from 'hooks';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { matchLayouts } from 'utils';
import { screenSizeTypes } from 'constants/Types';
import PropTypes from 'prop-types';
import './index.scss';

let titleTimer;
let descTimer;

const ContentHeader = ({ title, desc, type }) => {
  const { ui } = useSelector((state) => state.ui);
  const location = useLocation();
  const [titleShow, setTitleShow] = useState(false);
  const [descShow, setDescShow] = useState(false);
  const headerTitleRef = useRef();
  const headerDescRef = useRef();
  const pathname = location.pathname.slice(1);

  const titleTextParams = { ref: headerTitleRef };
  const descTextLineParams = { ref: headerDescRef, n: 1, k: 2 };
  useTextLine(titleTextParams);
  useTextLine(descTextLineParams);

  useEffect(() => {
    titleTimer = setTimeout(() => {
      setTitleShow(true);
    }, 100);

    return () => clearTimeout(titleTimer);
  }, []);

  useEffect(() => {
    let descDurationTime = 250;
    if (type === 'blog') {
      descDurationTime = 450;
    }
    descTimer = setTimeout(() => {
      setDescShow(true);
    }, descDurationTime);

    return () => clearTimeout(descTimer);
  }, [type]);

  const modifyClass = (type) => {
    if (type === 'blog') {
      return 'not-flex';
    } else return '';
  };

  return (
    <section className={`content-header ${matchLayouts(ui)} ${modifyClass(type)} ${pathname}`}>
      <div className={`content-header-title ${titleShow ? 'show' : ''} block`} ref={headerTitleRef}>
        {title.map((text, i) => (
          <AnimatedText key={i} text={text} y={100} />
        ))}
      </div>
      <div className={`content-header-desc ${descShow ? 'show' : ''} inline`} ref={headerDescRef}>
        {matchLayouts(ui) === screenSizeTypes.BIG_SCREEN
          ? desc.bigScreen.map((text, i) => <AnimatedText key={i} text={text} y={150} />)
          : desc.default.split(' ').map((text, i) => <AnimatedText key={i} text={text} y={150} />)}
      </div>
    </section>
  );
};

ContentHeader.propTypes = {
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  desc: PropTypes.shape({
    default: PropTypes.string,
    bigScreen: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  type: PropTypes.oneOf(['blog']),
};

export default ContentHeader;

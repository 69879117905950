import { AnimatedText } from 'components';
import { screenSizeTypes } from 'constants/Types';
import { useScroll } from 'hooks';
import { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import './index.scss';

const getTexts = (text, i) => {
  if (typeof text === 'object') {
    if (text.email) {
      return (
        <a key={i} href={`mailto:${text.email}`} className='content-desc email'>
          {text.email}
        </a>
      );
    } else if (text.link) {
      return (
        <a key={i} href={text.link} target='_blank' rel='noreferrer' className='content-desc link'>
          {text.link}
        </a>
      );
    } else if (text.listItem) {
      return (
        <div className='list-item-container' key={i}>
          <div className='dot'></div>
          <div className='content-desc list-item'>{text.listItem}</div>
        </div>
      );
    } else if (text.list) {
      return (
        <div className='list-container' key={i}>
          <div className='dot'></div>
          <div className='content-desc list'>{text.list}</div>
        </div>
      );
    } else {
      return <Fragment key={i}></Fragment>;
    }
  } else {
    return (
      <span key={i} className='content-desc'>
        {text}
      </span>
    );
  }
};

let titleTimer;
const PrivacyTermContent = ({ data, ui }) => {
  const [titleShow, setTitleShow] = useState(false);
  const titleRef = useRef();
  const contentRef = useRef();
  useScroll({ ref: contentRef, showOnce: true });

  useEffect(() => {
    titleTimer = setTimeout(() => {
      setTitleShow(true);
    }, 500);

    return () => clearTimeout(titleTimer);
  }, []);

  return (
    <div
      className={`content-wrapper ${ui === screenSizeTypes.MOBILE ? screenSizeTypes.MOBILE : ''}`}
    >
      <div className={`title-contanier ${titleShow ? 'show' : ''} block`} ref={titleRef}>
        <AnimatedText text={data.pageTitle} y={200} />
      </div>
      <div className='content-contanier' ref={contentRef}>
        {data.contents.map((content, i) => (
          <section className='content-section' key={i}>
            {content.sectionTitle && <h4 className='content-title'>{content.sectionTitle}</h4>}
            {content.descriptions.map((description, i) => (
              <div key={i} className='content-desc-para'>
                {Array.isArray(description) ? (
                  description.map((text, i) => getTexts(text, i))
                ) : (
                  <span key={i} className='content-desc'>
                    {description}
                  </span>
                )}
              </div>
            ))}
          </section>
        ))}
      </div>
    </div>
  );
};

export default PrivacyTermContent;

export const IcHambergerMenu = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g fill='#1F2122' fillRule='evenodd' className='bar-group'>
        <path d='M8 14H32V17H8z' className='bar top' />
        <path d='M8 23H32V26H8z' className='bar bottom' />
      </g>
    </svg>
  );
};

export const IcScrollHambergerMenu = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g
        fill='none'
        fillRule='evenodd'
        transform='translate(-100 -748) translate(100 748)'
        className='bar-group'
      >
        <circle cx='20' cy='20' r='20' fill='#1F2122' />
        <path fill='#FFF' d='M9 15H31V17H9z' className='bar top' />
        <path fill='#fff' d='M9 24H31V26H9z' className='bar bottom' />
      </g>
    </svg>
  );
};

export const IcCloseButton = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#1F2122'>
          <path d='M-2 9H22V12H-2z' transform='translate(10 9) rotate(45 10 10.5)' />
          <path d='M-2 9H22V12H-2z' transform='translate(10 9) rotate(-45 10 10.5)' />
        </g>
      </g>
    </svg>
  );
};

export const IcArrowLeft = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      className='arrow-icon'
    >
      <g fill='none' fillRule='evenodd' className='arrow-icon-group'>
        <path d='M3 13.071L3 3.071 13 3.071' transform='rotate(-90 8 8) rotate(-135 8 8.071)' />
        <path d='M8 2L8 15' transform='rotate(-90 8 8)' />
      </g>
    </svg>
  );
};

export const IcDownload = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      className='download-icon'
    >
      <g fill='none' stroke='#FFF' fillRule='evenodd'>
        <path d='M3 12.071L3 2.071 13 2.071' transform='translate(4 2) rotate(-135 8 7.071)' />
        <path d='M2 17L14 17M8 1L8 14' transform='translate(4 2)' />
      </g>
    </svg>
  );
};

export const IcBack = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g fill='none' stroke='#1F2122' fillRule='evenodd'>
        <path d='M32.556 30.556L10.556 30.556 10.556 8.556' transform='rotate(45 21.556 19.556)' />
      </g>
    </svg>
  );
};

export const IcNext = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g fill='none' stroke='#1F2122' fill-rule='evenodd'>
        <path
          d='M32.556 30.556L10.556 30.556 10.556 8.556'
          transform='matrix(-1 0 0 1 40 0) rotate(45 21.556 19.556)'
        />
      </g>
    </svg>
  );
};

export const SlideLeft = ({ opacity = 0.6 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='88' height='88' viewBox='0 0 88 88'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <circle cx='44' cy='44' r='44' fill='#1F2122' opacity={opacity} />
          <g stroke='#fff'>
            <path
              d='M32.556 30.556L10.556 30.556 10.556 8.556'
              transform='translate(24 24) rotate(45 21.556 19.556)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const SlideRight = ({ opacity = 0.6 }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='88' height='88' viewBox='0 0 88 88'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <circle cx='44' cy='44' r='44' fill='#1F2122' opacity={opacity} />
          <g stroke='#fff'>
            <g>
              <path
                d='M32.556 30.556L10.556 30.556 10.556 8.556'
                transform='translate(24 24) matrix(-1 0 0 1 40 0) rotate(45 21.556 19.556)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const CheckBox = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#FFF' stroke='#B2BEC3'>
          <path d='M0.5 0.5H23.5V23.5H0.5z' />
        </g>
      </g>
    </svg>
  );
};

export const CheckBoxChecked = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <path fill='#1F2122' d='M0 0H24V24H0z' />
        <path fill='#FFF' fillRule='nonzero' d='M10 17L5 12 6.41 10.59 10 14.17 17.59 6.58 19 8z' />
      </g>
    </svg>
  );
};

export const IcError = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <path
          d='M0 0L24 0 24 24 0 24z'
          transform='translate(-1705 -1165) translate(1145 1145) translate(560 20)'
        />
        <path
          fill='#FF005C'
          d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z'
          transform='translate(-1705 -1165) translate(1145 1145) translate(560 20)'
        />
      </g>
    </svg>
  );
};

export const PlayButton = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <path d='M0 0L24 0 24 24 0 24z' transform='translate(-100 -1582) translate(100 1582)' />
          <path
            fill='#FFF'
            d='M10 16.5l6-4.5-6-4.5v9zM12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'
            transform='translate(-100 -1582) translate(100 1582)'
          />
        </g>
      </g>
    </svg>
  );
};

export const PlayButtonFilled = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <g>
          <path d='M0 0L24 0 24 24 0 24z' transform='translate(-470 -1582) translate(470 1582)' />
          <path
            fill='#FFF'
            d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z'
            transform='translate(-470 -1582) translate(470 1582)'
          />
        </g>
      </g>
    </svg>
  );
};

export const IcMoreWhite = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g fill='none' fillRule='evenodd'>
        <g transform='translate(-1371 -2844) translate(288 1264) translate(744 312) translate(32 1248) translate(307 20)'>
          <circle cx='20' cy='20' r='20' fill='#FFF' />
          <g stroke='#1F2122'>
            <path
              d='M3 12.071L3 2.071 13 2.071'
              transform='translate(12 12) rotate(-90 8.5 7.5) rotate(-135 8 7.071)'
            />
            <path d='M8 1v13' transform='translate(12 12) rotate(-90 8.5 7.5)' />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IcMoreBlack = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g fill='none' fillRule='evenodd'>
        <g transform='translate(-100 -1894) translate(100 1894)'>
          <circle cx='20' cy='20' r='20' fill='#1F2122' />
          <g stroke='#FFF'>
            <path
              d='M3 13.071L3 3.071 13 3.071'
              transform='rotate(-90 20 8) rotate(-135 8 8.071)'
            />
            <path d='M8 2L8 15' transform='rotate(-90 20 8)' />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IcAdd = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#B2BEC3'>
          <path
            d='M5 0H6V11H5z'
            transform='translate(-1084 -2234) translate(288 1264) translate(744 312) translate(32 602) translate(0 44) translate(20 12) rotate(-180 6.5 6.5) rotate(-90 5.5 5.5)'
          />
          <path
            d='M5 0H6V11H5z'
            transform='translate(-1084 -2234) translate(288 1264) translate(744 312) translate(32 602) translate(0 44) translate(20 12) rotate(-180 6.5 6.5) rotate(-180 5.5 5.5)'
          />
        </g>
      </g>
    </svg>
  );
};

export const IcDelete = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#B2BEC3'>
          <path d='M1 5H12V6H1z' />
          <path d='M1 5H12V6H1z' />
        </g>
      </g>
    </svg>
  );
};

export const IcLink = ({ onClick }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0L24 0 24 24 0 24z' />
        <g stroke='#B2BEC3'>
          <path
            d='M14 8.875L14 14 0 14 0 0 5.125 0M8.75 0L14 0 14 5.25M13.559.426L6.767 7.218'
            transform='translate(5 5)'
          />
        </g>
      </g>
    </svg>
  );
};

export const IcArrowDownGrey = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0L24 0 24 24 0 24z' />
        <path fill='#636E72' d='M7 9L17 9 12 15z' />
      </g>
    </svg>
  );
};

export const IcArrowUpGrey = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0L24 0 24 24 0 24z' />
        <path fill='#636E72' d='M7 9L17 9 12 15z' transform='matrix(1 0 0 -1 0 24)' />
      </g>
    </svg>
  );
};

export const Marker = () => {
  return (
    <svg
      version='1.2'
      baseProfile='tiny'
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 50 50'
      overflow='inherit'
    >
      <path
        fill='#c92b2a'
        d='M25 0c-8.284 0-15 6.656-15 14.866 0 8.211 15 35.135 15 35.135s15-26.924 15-35.135c0-8.21-6.716-14.866-15-14.866zm-.049 19.312c-2.557 0-4.629-2.055-4.629-4.588 0-2.535 2.072-4.589 4.629-4.589 2.559 0 4.631 2.054 4.631 4.589 0 2.533-2.072 4.588-4.631 4.588z'
      />
    </svg>
  );
};

export const IcDone = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'>
      <g fill='none' fillRule='evenodd'>
        <g stroke='#FFF'>
          <path d='M2 7.653L5.801 11.653 13.454 4' transform='translate(.546 .347)' />
        </g>
      </g>
    </svg>
  );
};

export const IcCommingSoon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='56' height='56' viewBox='0 0 56 56'>
      <g fill='none' fillRule='evenodd'>
        <path fill='#FFF' d='M0 0H1922V3874H0z' transform='translate(-100 -2598)' />
        <g transform='translate(-100 -2598) translate(100 2598)'>
          <circle cx='28' cy='28' r='28' fill='#000' />
          <text
            fill='#FFF'
            fontFamily='Roboto-Medium, Roboto'
            fontSize='10'
            fontWeight='400'
            letterSpacing='.5'
          >
            <tspan x='7.542' y='25'>
              COMING
            </tspan>
            <tspan x='14.028' y='37'>
              SOON
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export const IcCopyUrl = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#B2BEC3'>
          <g transform='rotate(-180 8 8)'>
            <path
              id='2z7t464tsa'
              d='M1.52 8c0-1.368 1.112-2.48 2.48-2.48h3.2V4H4C1.792 4 0 5.792 0 8s1.792 4 4 4h3.2v-1.52H4c-1.368 0-2.48-1.112-2.48-2.48zm3.28.8h6.4V7.2H4.8v1.6zM12 4H8.8v1.52H12c1.368 0 2.48 1.112 2.48 2.48 0 1.368-1.112 2.48-2.48 2.48H8.8V12H12c2.208 0 4-1.792 4-4s-1.792-4-4-4z'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IcFilterOpen = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0L24 0 24 24 0 24z' />
        <path fill='#636E72' d='M2 6L22 6 12 18z' transform='matrix(1 0 0 -1 0 24)' />
      </g>
    </svg>
  );
};

export const IcFilterClose = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0L24 0 24 24 0 24z' />
        <path fill='#636E72' d='M2 6L22 6 12 18z' />
      </g>
    </svg>
  );
};

export const IcDecoration = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g fill='none' fillRule='evenodd' opacity='.3'>
        <g fill='#FFF'>
          <g>
            <path
              d='M15.923 0l-7.24 7.771c.187-.011.377-.017.567-.017 5.109 0 9.25 4.142 9.25 9.25 0 5.109-4.141 9.25-9.25 9.25S0 22.113 0 17.004c0-2.213.777-4.244 2.073-5.836h-.025l.3-.321c.093-.105.19-.208.288-.309L12.455 0h3.468zM35.923 0l-7.24 7.771c.187-.011.377-.017.567-.017 5.109 0 9.25 4.142 9.25 9.25 0 5.109-4.141 9.25-9.25 9.25S20 22.113 20 17.004c0-2.213.777-4.244 2.073-5.836h-.025l.3-.321c.093-.105.19-.208.288-.309L32.455 0h3.468z'
              transform='translate(1 7)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IcArrowUp = ({ onClick }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g onClick={onClick} fill='none' fillRule='evenodd'>
        <path d='M0 0L24 0 24 24 0 24z' />
      </g>
      <path fill='#636E72' d='M7 9L17 9 12 15z' transform='matrix(1 0 0 -1 0 24)' />
    </svg>
  );
};
export const IcArrowDown = ({ onClick }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
      <g onClick={onClick} fill='none' fillRule='evenodd'>
        <path d='M0 0L24 0 24 24 0 24z' />
        <path fill='#636E72' d='M7 9L17 9 12 15z' />
      </g>
    </svg>
  );
};

export const IcClose = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#1F2122'>
          <path
            d='M-4.667 14L32.667 14 32.667 15 -4.667 15z'
            transform='matrix(-1 0 0 1 34 6) rotate(45 14 14.5)'
          />
          <path
            d='M-4.667 14L32.667 14 32.667 15 -4.667 15z'
            transform='matrix(-1 0 0 1 34 6) rotate(-45 14 14.5)'
          />
        </g>
      </g>
    </svg>
  );
};

export const IcXbutton = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#FFF'>
          <path d='M5 0H6V11H5z' transform='rotate(135 6.086 6.914) rotate(-90 5.5 5.5)' />
          <path d='M5 0H6V11H5z' transform='rotate(135 6.086 6.914) rotate(-180 5.5 5.5)' />
        </g>
      </g>
    </svg>
  );
};

export const IcPopupClose = ({ size = 40, onClick }) => {
  return (
    <svg
      className='popup-x-button'
      onClick={onClick && onClick}
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 40 40'
    >
      <g fill='none' fillRule='evenodd'>
        <g fill='#1F2122'>
          <path
            d='M-2 9H22V10.5H-2z'
            transform='translate(-256 -24) translate(256 24) translate(10 11) rotate(45 10 9.75)'
          />
          <path
            d='M-2 9H22V10.5H-2z'
            transform='translate(-256 -24) translate(256 24) translate(10 11) rotate(-45 10 9.75)'
          />
        </g>
      </g>
    </svg>
  );
};

export const IcTwitter = ({ fill = '#B2BEC3', onClick }) => {
  return (
    <svg
      className='sns-icon'
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
      onClick={onClick}
    >
      <g fill={fill} fillRule='nonzero'>
        <g>
          <path
            d='M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20zm19.401-3.744l.042.692-.7-.084c-2.546-.325-4.77-1.427-6.659-3.277l-.923-.918-.238.678c-.503 1.511-.182 3.107.868 4.18.56.594.433.678-.532.325-.336-.113-.63-.198-.657-.155-.098.099.237 1.384.503 1.892.364.707 1.105 1.399 1.917 1.808l.685.325-.811.014c-.784 0-.812.014-.728.31.28.919 1.385 1.893 2.616 2.317l.868.297-.756.452c-1.119.65-2.434 1.016-3.749 1.045-.63.014-1.147.07-1.147.113 0 .141 1.707.932 2.7 1.243 2.98.918 6.52.522 9.177-1.046 1.889-1.115 3.777-3.333 4.659-5.48.475-1.143.951-3.234.951-4.236 0-.65.042-.735.825-1.512.462-.452.896-.946.98-1.087.14-.268.126-.268-.588-.028-1.189.423-1.357.367-.77-.269.434-.452.952-1.27.952-1.51 0-.043-.21.027-.448.154-.252.142-.811.354-1.23.48l-.756.24-.686-.465c-.377-.255-.91-.537-1.189-.622-.713-.198-1.805-.17-2.448.057-1.749.635-2.854 2.274-2.728 4.067z'
            transform='translate(-1000 -674) translate(320 140) translate(680 534)'
          />
        </g>
      </g>
    </svg>
  );
};

export const IcLinkedIn = ({ onClick }) => {
  return (
    <svg
      className='sns-icon'
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
      onClick={onClick}
    >
      <g fill='#B2BEC3' fillRule='nonzero'>
        <g>
          <path
            d='M0 20C0 8.954 8.954 0 20 0s20 8.954 20 20-8.954 20-20 20S0 31.046 0 20zm14.134-3.435H9.6v13.618h4.533V16.565zm.298-4.213c-.03-1.335-.984-2.352-2.534-2.352-1.551 0-2.565 1.017-2.565 2.352 0 1.308.984 2.354 2.506 2.354h.029c1.58 0 2.564-1.046 2.564-2.354zM30.48 22.375c0-4.183-2.235-6.13-5.218-6.13-2.406 0-3.484 1.322-4.085 2.25v-1.93h-4.533c.06 1.278 0 13.618 0 13.618h4.533v-7.605c0-.407.029-.813.149-1.105.327-.813 1.073-1.654 2.325-1.654 1.641 0 2.297 1.248 2.297 3.078v7.286h4.532v-7.808z'
            transform='translate(-1131 -674) translate(320 140) translate(811 534)'
          />
        </g>
      </g>
    </svg>
  );
};
export const IcDaOmPyo = () => {
  return (
    <svg
      className='daompyo'
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='40'
      viewBox='0 0 40 40'
    >
      <g fill='none' fillRule='evenodd'>
        <g fill='#B2BEC3'>
          <path
            d='M2.072 11.169h-.024l.269-.288c.108-.122.219-.241.333-.357L12.455 0h3.468l-7.24 7.771c.187-.011.377-.017.567-.017 5.109 0 9.25 4.142 9.25 9.25 0 5.109-4.141 9.25-9.25 9.25S0 22.113 0 17.004c0-2.212.777-4.243 2.072-5.835zM22.072 11.169h-.024l.269-.288c.108-.122.219-.241.333-.357L32.455 0h3.468l-7.24 7.771c.187-.011.377-.017.567-.017 5.109 0 9.25 4.142 9.25 9.25 0 5.109-4.141 9.25-9.25 9.25S20 22.113 20 17.004c0-2.212.777-4.243 2.072-5.835z'
            transform='translate(-288 -5226) translate(0 4934) translate(288 292) translate(1 7)'
          />
        </g>
      </g>
    </svg>
  );
};

export const spinIcon = ({ uploading }) => {
  const svgStyle = {
    margin: 'auto',
    display: 'block',
    shapeRendering: 'auto',
    animationPlayState: 'running',
    animationDelay: '0s',
    marginTop: uploading ? 'unset' : '30vh',
  };

  const gStyle = {
    animationPlayState: 'running',
    animationDelay: '0s',
  };
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      style={svgStyle}
      width='200px'
      height='200px'
      viewBox='0 0 100 100'
      preserveAspectRatio='xMidYMid'
    >
      <g transform='rotate(0 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.9166666666666666s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(30 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.8333333333333334s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(60 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.75s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(90 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.6666666666666666s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(120 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.5833333333333334s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(150 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.5s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(180 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.4166666666666667s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(210 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.3333333333333333s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(240 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.25s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(270 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.16666666666666666s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(300 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='-0.08333333333333333s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
      <g transform='rotate(330 50 50)' style={gStyle}>
        <rect x='47' y='24' rx='3' ry='6' width='6' height='12' fill='#1d0e0b' style={gStyle}>
          <animate
            attributeName='opacity'
            values='1;0'
            keyTimes='0;1'
            dur='1s'
            begin='0s'
            repeatCount='indefinite'
            style={gStyle}
          ></animate>
        </rect>
      </g>
    </svg>
  );
};

export const IcFooterFacebook = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#B2BEC3' fillRule='nonzero'>
          <g>
            <path
              d='M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16zm17.668 9.408v-8.705h2.403l.318-3h-2.721l.004-1.501c0-.783.074-1.202 1.198-1.202h1.502V8h-2.403c-2.887 0-3.903 1.455-3.903 3.902v1.801h-1.8v3h1.8v8.705h3.602z'
              transform='translate(-100 -2974) translate(100 2974)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IcFooterYoutube = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#B2BEC3' fillRule='nonzero'>
          <g>
            <path
              d='M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16zm24.177-3.951c-.197-.754-.775-1.348-1.51-1.55-1.33-.366-6.667-.366-6.667-.366s-5.337 0-6.668.367c-.734.201-1.313.795-1.509 1.549-.356 1.366-.356 4.218-.356 4.218s0 2.85.356 4.218c.196.754.775 1.347 1.51 1.549C10.662 22.4 16 22.4 16 22.4s5.337 0 6.668-.366c.734-.202 1.312-.795 1.509-1.55.356-1.366.356-4.217.356-4.217s0-2.852-.356-4.218z'
              transform='translate(-100 -3086) translate(100 3086)'
            />
            <path
              d='M14.4 19.2L14.4 13.867 18.667 16.533z'
              transform='translate(-100 -3086) translate(100 3086)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IcFooterLinkdin = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#B2BEC3' fillRule='nonzero'>
          <g>
            <g>
              <path
                d='M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16zm11.307-2.748H7.681v10.894h3.626V13.252zm.239-3.37C11.522 8.814 10.758 8 9.518 8s-2.051.814-2.051 1.882c0 1.046.787 1.883 2.004 1.883h.023c1.264 0 2.052-.837 2.052-1.883zM24.384 17.9c0-3.346-1.79-4.904-4.175-4.904-1.925 0-2.787 1.057-3.269 1.8v-1.544h-3.626c.048 1.022 0 10.895 0 10.895h3.626v-6.085c0-.325.024-.65.12-.883.262-.65.859-1.324 1.86-1.324 1.313 0 1.838.999 1.838 2.463v5.828h3.625V17.9z'
                transform='translate(-100 -3198) translate(100 3198)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const IcFooterTwitter = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'>
      <g fill='none' fillRule='evenodd'>
        <g fill='#B2BEC3' fillRule='nonzero'>
          <g>
            <path
              d='M0 16C0 7.163 7.163 0 16 0s16 7.163 16 16-7.163 16-16 16S0 24.837 0 16zm15.52-2.995l.034.554-.56-.068c-2.036-.26-3.816-1.141-5.326-2.621l-.74-.735-.19.542c-.402 1.21-.145 2.486.695 3.345.447.474.346.542-.426.26-.268-.09-.503-.158-.526-.125-.078.08.19 1.108.403 1.514.291.565.884 1.12 1.533 1.447l.549.26-.65.01c-.626 0-.648.012-.581.25.224.734 1.108 1.513 2.093 1.852l.693.238-.604.361c-.895.52-1.947.814-3 .836-.503.012-.917.057-.917.09 0 .114 1.365.746 2.16.995 2.384.734 5.215.418 7.342-.836 1.51-.893 3.021-2.667 3.727-4.384.38-.915.76-2.587.76-3.39 0-.52.034-.587.66-1.209.37-.361.717-.757.784-.87.112-.214.101-.214-.47-.022-.951.339-1.085.294-.615-.215.347-.361.76-1.017.76-1.209 0-.034-.167.023-.357.125-.202.113-.65.282-.985.384l-.605.192-.548-.373c-.302-.203-.727-.43-.951-.497-.571-.158-1.444-.136-1.959.045-1.399.508-2.283 1.82-2.182 3.254z'
              transform='translate(-100 -3310) translate(100 3310)'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

import PropTypes from 'prop-types';
import './index.scss';

//! size: big, small
const Label = ({ size = 'big', label }) => {
  return <div className={`label ${size}`}>{label}</div>;
};

Label.propTypes = {
  /** 라벨 사이즈 */
  size: PropTypes.oneOf(['big', 'small']),
  /** 라벨 텍스트 */
  label: PropTypes.string,
};

Label.defaultProps = {
  size: 'big',
};

export default Label;

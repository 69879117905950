import { lazy } from 'react';

export const Main = lazy(() => import('pages/main'));
export const About = lazy(() => import('pages/about'));
export const News = lazy(() => import('pages/news'));
export const NewsDetail = lazy(() => import('pages/newsdetail'));
export const Privacy = lazy(() => import('pages/privacy'));
export const Term = lazy(() => import('pages/term'));
export const Contact = lazy(() => import('pages/contact'));
export const TechBlog = lazy(() => import('pages/techblog'));
export const TechBlogDetail = lazy(() => import('pages/techblogdetail'));
export const Career = lazy(() => import('pages/career'));
export const CareerApply = lazy(() => import('pages/career-apply'));
export const Partners = lazy(() => import('pages/partners'));
export const ResearchArea = lazy(() => import('pages/research-area'));
export const ApplicantPolicy = lazy(() => import('pages/applicant-privacy-policy'));
export const Team = lazy(() => import('pages/team'));
export const ViewInterHr = lazy(() => import('pages/product'));

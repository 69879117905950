import { forwardRef } from 'react';
import { Input } from 'components';
import PropTypes from 'prop-types';
import './index.scss';

const FormInput = forwardRef(
  ({ value, name, onChange, onKeyDown, label, maxLength = 50, error, small, style }, refs) => {
    return (
      <div className={`input-box ${small ? 'small' : ''}`} style={style ? style : {}}>
        <Input
          ref={refs}
          value={value}
          name={name}
          maxLength={maxLength}
          onChange={onChange}
          onKeyDown={onKeyDown}
          error={error}
          label={label}
        />
      </div>
    );
  }
);

FormInput.propTypes = {
  /** input 박스에 입력될 value */
  value: PropTypes.string.isRequired,
  /** input 박스의 name */
  name: PropTypes.string,
  /** 라벨 텍스트 */
  label: PropTypes.string,
  /** 값을 입력받을 때마다 실행되는 함수 */
  onChange: PropTypes.func.isRequired,
  /** 특정 키를 눌렀을 때 실행되는 함수
   *
   * (ex. 엔터를 눌렀을 때 제출 로직 실행)
   */
  onKeyDown: PropTypes.func,
  /** 활성화 여부 */
  disabled: PropTypes.bool,
  /** 입력할 수 있는 최대 길이 */
  maxLength: PropTypes.number,
  /** 발생한 에러 종류나 문장 */
  error: PropTypes.string,
  /** input 박스의 가로 사이즈 조절 */
  small: PropTypes.bool,
  style: PropTypes.object,
};

FormInput.defaultProps = {
  maxLength: 50,
  small: false,
};

export default FormInput;

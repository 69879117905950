import PropTypes from 'prop-types';
import './index.scss';

const FlowingText = ({ label }) => {
  return (
    <div className='flowing-text-section'>
      <div className='flowing-text'>{label}</div>
      <div className='flowing-text'>{label}</div>
      <div className='flowing-text'>{label}</div>
      <div className='flowing-text'>{label}</div>
      <div className='flowing-text'>{label}</div>
      <div className='flowing-text'>{label}</div>
      <div className='flowing-text'>{label}</div>
      <div className='flowing-text'>{label}</div>
      <div className='flowing-text'>{label}</div>
      <div className='flowing-text'>{label}</div>
    </div>
  );
};

FlowingText.propTypes = {
  /** 반복시켜 표현할 텍스트 */
  label: PropTypes.string,
};

export default FlowingText;

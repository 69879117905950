import { useState, useEffect, useRef } from 'react';
import { Icons } from 'styles';
import PropTypes from 'prop-types';
import './index.scss';

const DefaultSelectItem = ({ value, index }) => {
  return <option value={value}>{value}</option>;
};

const CustomSelectItem = ({ value, onChange, toggleSelectBox }) => {
  return (
    <div
      className='select-box-item'
      onClick={(e) => {
        onChange(e);
        toggleSelectBox();
      }}
    >
      {value}
    </div>
  );
};

const DefaultSelectBox = ({ data, onChange, error, label, selectedItem }) => {
  const [isSelectBoxOpen, setIsSelectBoxOpen] = useState(false);

  return (
    <div
      className={`default-select select-container  ${error ? 'error' : ''}  ${
        selectedItem ? 'value-exist' : ''
      }`}
    >
      <select
        className='default-select-box'
        onFocus={() => setIsSelectBoxOpen(true)}
        onBlur={() => setIsSelectBoxOpen(false)}
        value={selectedItem}
        onChange={onChange}
      >
        <option value='none' hidden></option>
        {data.map((country, i) => (
          <DefaultSelectItem key={i} onChange={onChange} value={country} index={i} />
        ))}
      </select>
      <span className={`select-box-label`}>{label}</span>
      <div className='bar'></div>
      <div className={`bar animated-bar`}></div>
      <div className='toggle-icon'>
        {isSelectBoxOpen ? <Icons.IcArrowUpGrey /> : <Icons.IcArrowDownGrey />}
      </div>
    </div>
  );
};

const CustomSelectBox = ({ data, label, error, onChange, selectedItem }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  const toggleSelectBox = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickOutside = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div
      className={`custom-select select-container ${error ? 'error' : ''}  ${
        selectedItem ? 'value-exist' : ''
      }`}
      ref={ref}
    >
      <div className={`select-box-button ${isOpen ? 'open' : ''}`} onClick={toggleSelectBox}>
        <span className={`select-box-label`}>{label}</span>
        <div className='bar'></div>
        <div className={`bar animated-bar`}></div>
        <div className='toggle-icon'>
          {isOpen ? <Icons.IcArrowUpGrey /> : <Icons.IcArrowDownGrey />}
        </div>
        {selectedItem && <div className='selected-item'>{selectedItem}</div>}
      </div>
      {isOpen && (
        <div className='select-box-items'>
          {data.map((item, i) => (
            <CustomSelectItem
              key={i}
              value={item}
              onChange={onChange}
              toggleSelectBox={toggleSelectBox}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const SelectBox = ({
  data,
  type,
  label,
  selectedItem,
  error,
  onChangeDefaultSelectItem,
  onChangeCustomSelectItem,
}) => {
  return type === 'system' ? (
    <DefaultSelectBox
      data={data}
      label={label}
      error={error}
      onChange={onChangeDefaultSelectItem}
      selectedItem={selectedItem}
    />
  ) : (
    <CustomSelectBox
      data={data}
      label={label}
      error={error}
      onChange={onChangeCustomSelectItem}
      selectedItem={selectedItem}
    />
  );
};

SelectBox.propTypes = {
  /** 선택할 수 있는 아이템 목록 */
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]))
    .isRequired,
  /** 셀렉트 박스의 타입
   *
   * (system은 selectItem을 기본 시스템 아이템으로 사용, custom은 커스텀 아이템으로 사용)
   */
  type: PropTypes.oneOf(['system', 'custom']),
  /** 셀렉트 박스의 라벨 텍스트 */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** 에러 타입 및 내용 텍스트 */
  error: PropTypes.string,
  /** 시스템 셀렉트 박스의 아이템 선택이 변경되었을 경우 실행되는 함수 */
  onChangeDefaultSelectItem: PropTypes.func,
  /** 커스텀 셀렉트 박스의 아이템 선택이 변경되었을 경우 실행되는 함수 */
  onChangeCustomSelectItem: PropTypes.func,
  /** 현재 선택되어 있는 아이템 */
  selectedItem: PropTypes.string,
};

SelectBox.defaultProps = {
  type: 'custom',
};

export default SelectBox;

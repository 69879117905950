import { useEffect } from 'react';
// import Spin from './spin.gif';
import './index.scss';
import Strings from 'strings';
import { Icons } from 'styles';
import PropTypes from 'prop-types';

let backtimer;
const Loading = ({ timer, goBack, history, redirect, uploading }) => {
  useEffect(() => {
    if (goBack) {
      backtimer = setTimeout(() => {
        history.push(`/${redirect}`);
      }, timer);
    }
    return () => {
      clearTimeout(backtimer);
    };
  }, []);

  return (
    <div className={`loading-container ${uploading ? 'upload' : ''}`}>
      <Icons.spinIcon uploading={uploading} />
      {/* <img className='spin' src={Spin} alt='spin' /> */}
      {uploading && <div className='uploading-message'>{Strings.APPLY_UPLOADING_MESSAGE}</div>}
    </div>
  );
};

Loading.propTypes = {
  /** 뒤로가기 실행시 실행 시작 시간 */
  timer: PropTypes.number,
  /** 뒤로가기 실행 여부 */
  goBack: PropTypes.bool,
  /** 리다이렉션 시킬 url */
  redirect: PropTypes.string,
  /** 업로드 로딩 컴포넌트인지 여부 */
  uploading: PropTypes.bool,
  /** history 객체 */
  history: PropTypes.object,
};

export default Loading;

export const screenSizeTypes = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop',
  WIDESCREEN: 'widescreen',
  FULLHD: 'fullhd',
  SMALL_SCREEN: 'small-screen',
  BIG_SCREEN: 'big-screen',
};

export const jobKeys = {
  JOBS_FRONTEND_DEVELOPER_KEY: 'frontend_developer',
  JOBS_ANDROID_DEVELOPER_KEY: 'android_developer',
  JOBS_SERVER_DEVELOPER_KEY: 'server_developer',
  JOBS_PRODUCT_MANAGER_KEY: 'product_manager',
  JOBS_MARKETING_KEY: 'marketing',
  JOBS_DESIGNER_KEY: 'designer',
  JOBS_AI_SCIENTIST: 'ai_scientist',
  JOBS_DATA_SCIENTIST: 'data_scientist',
  JOBS_UI_UX_DESIGNER: 'service_ux/ui_designer',
  JOBS_OPERATION: 'sevice_operation_manager',
};

export const paramsKeys = {
  CAREER_POSITION_KEY: 'position',
};

export const careerFilterKeys = {
  CAREER_FILTER_JOB_TITLE: '1',
  CAREER_FILTER_LOCATION: '2',
};

export const teamTypes = {
  PRINCIPLE: 'principle',
  BENEFIT: 'benefit',
};

export const leaderKey = {
  YB: 'YB',
  DH: 'DH',
  JK: 'JK',
};

export const inputFileType = {
  PORTFOLIO: 'portfolio',
  RESUME: 'resume',
  COVER: 'cover',
};

export const carouselType = {
  TECHBLOG: 'tech-blog',
  TEAMS: 'teams',
  TEAM_ADVISOR: 'team-advisor',
  ARTICLE: 'article',
};

export const patentCountryType = {
  ko: '1',
  us: '2',
};

export const hrType = {
  keyFeature: 'keyFeature',
  goodForHr: 'goodForHr',
};

import { useState, useEffect, useRef, Fragment } from 'react';
import CircleButton from 'components/circle-button';
import Label from 'components/label';
import AnimatedText from 'components/animated-text';
import Flickity from 'flickity';
import { techBlog } from 'data/techBlogData';
import { articles } from 'data/articleData';
import { AdvisorModule, advisorsData } from 'pages/team/advisors';
import { Icons } from 'styles';
import { useSelector } from 'react-redux';
import { matchLayouts, upToTop } from 'utils';
import { carouselType, screenSizeTypes } from 'constants/Types';
import { teamData } from 'data/teamResearchData';
import Strings from 'strings';
import { setMessages } from '_redux';
import './index.scss';

const title = ['Related', 'stories'];
const exceptType = ['team-advisor'];
let carouselTitleTimer;

const leftText = 'left';
const rightText = 'right';

const CarouselModule = ({ article, ui, history, type }) => {
  function setLabelTag(type) {
    let result;
    if (type === carouselType.TECHBLOG) {
      result = article.category.map((item, idx) => <Label key={idx} size='big' label={item} />);
    } else {
      result = <Label label='Public News' />;
    }
    return result;
  }

  function onClickCarousel({ type, article }) {
    if (type === carouselType.TECHBLOG) {
      upToTop();
      history.push(`/tech-blog-detail/${article.key}`);
    } else if (type === carouselType.TEAMS) {
      setMessages({ showMessage: true, content: Strings.MAIN_NOT_READY });
    } else {
      upToTop();
      history.push(`/news-detail/${article.key}`);
    }
  }

  return type === carouselType.TEAM_ADVISOR ? (
    <AdvisorModule advisor={article} />
  ) : (
    <li className={`carousel-slide-li ${ui} ${type}`}>
      <div className='carousel-slide-img-div'>
        <img
          className='carousel-slide-img'
          src={article.thumbnail}
          alt='article-small-img'
          onClick={() => {
            onClickCarousel({ type, article });
          }}
        />
      </div>
      <div className={`carousel-contents-div ${type}`}>
        {type !== carouselType.TEAMS && setLabelTag(type)}
        <div className={`carousel-contents-title ${type}`}>{article.title}</div>
        {type === carouselType.TEAMS && (
          <div className={`carousel-contents-desc ${type}`}>{article.text}</div>
        )}
        <div className='carousel-circle-button-div'>
          <CircleButton
            label='More'
            onClick={() => {
              onClickCarousel({ type, article });
            }}
          />
        </div>
      </div>
    </li>
  );
};

function getClickableCount(type) {
  switch (type) {
    case carouselType.ARTICLE: {
      return articles.length;
    }
    case carouselType.TECHBLOG: {
      return techBlog.length;
    }
    case carouselType.TEAM_ADVISOR: {
      return 3;
    }
    case carouselType.TEAMS: {
      return teamData.length;
    }
    default: {
      return 3;
    }
  }
}

function checkArrowVisible(type, ui) {
  if (type === carouselType.TEAM_ADVISOR) {
    return ui === screenSizeTypes.MOBILE ? false : true;
  } else {
    return matchLayouts(ui) === screenSizeTypes.BIG_SCREEN ? true : false;
  }
}

const Carousel = ({ articleNumber, history, type = carouselType.ARTICLE }) => {
  const [clickCount, setClickCount] = useState(0);
  const [leftArrow, setLeftArrow] = useState(false);
  const [rightArrow, setRightArrow] = useState(true);
  const [titleShow, setTitleShow] = useState('');
  const { ui } = useSelector((state) => state.ui);
  const titleRef = useRef();
  const carouselSlideListRef = useRef();
  const sliderInnerDivRef = useRef();
  const clickableCountMaximum = getClickableCount(type);

  function carouselTotalLength({ marginRight, refChildren }) {
    let totalWidth = 0;
    for (const li of refChildren) {
      totalWidth += li.clientWidth + marginRight;
    }
    return totalWidth + 'px';
  }

  function carouselHandleScroll() {
    let standard =
      (document.documentElement.scrollTop || document.body.scrollTop) + window.innerHeight;

    let titleoffsetTop = titleRef && titleRef.current && titleRef.current.offsetTop;

    if (titleoffsetTop <= standard) {
      carouselTitleTimer = setTimeout(() => {
        setTitleShow('show');
      }, 700);
    } else {
      setTitleShow('');
    }
  }
  useEffect(() => {
    setTitleShow('');
    let imgMargin = ui === screenSizeTypes.MOBILE ? 24 : 40;
    let titleOffsetLeft = titleRef?.current?.offsetLeft;

    carouselSlideListRef.current.style.left =
      type === carouselType.TEAM_ADVISOR ? '200px' : titleOffsetLeft + 'px';
    carouselSlideListRef.current.style.width = carouselTotalLength({
      marginRight: imgMargin,
      refChildren: carouselSlideListRef.current.children,
    });

    window.addEventListener('scroll', carouselHandleScroll);

    if (
      ui === screenSizeTypes.MOBILE ||
      (ui === screenSizeTypes.TABLET && type !== carouselType.TEAM_ADVISOR)
    ) {
      let elem = carouselSlideListRef.current;
      new Flickity(elem, {
        cellAlign: 'left',
      });
      if (sliderInnerDivRef) {
        document.getElementsByClassName('flickity-viewport')[0].style.height =
          sliderInnerDivRef.current.offsetHeight + 'px';
      }
    }
    return () => {
      window.removeEventListener('scroll', carouselHandleScroll);
      clearTimeout(carouselTitleTimer);
    };
  }, []);

  function carouselArrowClick(direction) {
    let leftZero = '0px';
    let normalMoveWidth = '200px';

    let moveWidth =
      type === carouselType.TEAMS && ui === screenSizeTypes.DESKTOP ? '496px' : '592px';
    let nowWidth = carouselSlideListRef.current.style.left;
    if (direction === leftText) {
      if (clickCount <= 0) {
        carouselSlideListRef.current.style.left = leftZero;
      } else if (clickCount === 1) {
        carouselSlideListRef.current.style.left =
          type === carouselType.TEAM_ADVISOR ? normalMoveWidth : titleRef.current.offsetLeft + 'px';
      } else if (clickCount >= 1) {
        carouselSlideListRef.current.style.left = `calc( ${nowWidth} + ${moveWidth} )`;
      }
      if (clickCount >= clickableCountMaximum) {
        setClickCount(clickableCountMaximum - 1);
      } else {
        setClickCount(clickCount - 1);
      }
    } else if (direction === rightText) {
      if (clickCount === 0) {
        carouselSlideListRef.current.style.left = leftZero;
      } else if (clickCount < clickableCountMaximum) {
        carouselSlideListRef.current.style.left = `calc( ${nowWidth} - ${moveWidth} )`;
      }
      setClickCount(clickCount + 1);
    }
  }

  useEffect(() => {
    if (clickCount <= 0) {
      setLeftArrow(false);
      setRightArrow(true);
    }
    if (clickCount > 0 && clickCount <= clickableCountMaximum) {
      setRightArrow(true);
      setLeftArrow(true);
    }
    if (clickCount === clickableCountMaximum) {
      setRightArrow(false);
    }
  }, [clickCount, clickableCountMaximum]);

  function sortArticleType(type) {
    switch (type) {
      case carouselType.TECHBLOG: {
        const techBlogData = [...techBlog].reverse();
        const newTechBlogData = techBlogData.filter((item) => item.key !== Number(articleNumber));
        return newTechBlogData;
      }

      case carouselType.TEAM_ADVISOR:
        return advisorsData;
      case carouselType.TEAMS:
        return teamData;
      default: {
        // * 뉴스 default
        const newResult = articles.filter((item) => item.key !== Number(articleNumber));
        return newResult;
      }
    }
  }

  function exceptTitleFunc(type) {
    let result = false;
    for (const arr of exceptType) {
      if (arr === type) {
        result = false;
      } else {
        result = true;
      }
    }
    return result;
  }

  const arrowVisibleControl = (type, ui) => {
    let result;
    if (checkArrowVisible(type, ui)) {
      result = (
        <Fragment>
          <div
            className={`carousel-btn-div left ${leftArrow}`}
            onClick={() => carouselArrowClick(leftText)}
          >
            <Icons.SlideLeft />
          </div>
          <div
            className={`carousel-btn-div right ${rightArrow}`}
            onClick={() => carouselArrowClick(rightText)}
          >
            <Icons.SlideRight />
          </div>
        </Fragment>
      );
    } else {
      result = null;
    }
    return result;
  };

  return (
    <div className={`carousel-container ${type}`}>
      {exceptTitleFunc(type) && (
        <div ref={titleRef} className={`carousel-title inline ${titleShow}`}>
          {type !== carouselType.TEAMS &&
            title.map((text, i) => <AnimatedText text={text} key={i} y={100} duration={10} />)}
        </div>
      )}
      <div className='carousel-module-container'>
        <div
          style={{ width: window.innerWidth + 'px' }}
          className='carousel-module-container-inner-div'
        >
          {arrowVisibleControl(type, ui)}
          <div ref={sliderInnerDivRef} className={`carousel-slide-container ${type}`}>
            <ul ref={carouselSlideListRef} className='carousel-slide-ul'>
              {sortArticleType(type).map((item, idx) => (
                <CarouselModule type={type} history={history} article={item} key={idx} ui={ui} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;

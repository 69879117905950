import { useEffect, useState } from 'react';

// 참고자료 : https://developer.mozilla.org/ko/docs/Web/API/IntersectionObserver

const useIntersectionObserver = ({ ref, threshold = 0.2 }) => {
  const [isIntersecting, setIsIntersecting] = useState();
  const options = {
    root: null,
    threshold: threshold,
  };

  useEffect(() => {
    const target = ref?.current;
    let observer;

    const onIntersect = ([entry]) => {
      if (entry.isIntersecting) {
        setIsIntersecting(true);
      }
    };

    if (target) {
      observer = new IntersectionObserver(onIntersect, options);
      observer.observe(target);
    }

    return () => observer && observer.disconnect();
  }, []);

  return { isIntersecting };
};

export default useIntersectionObserver;

import PropTypes from 'prop-types';
import { Icons } from 'styles';
import './index.scss';

//! 체크된 태그 값(혹은 인덱스) 를 사용해야 할 때 onClick에 해당 작업을 넣어서 내려주세요
const Tag = ({ label, onClick, type, isChecked }) => {
  const toggleChecked = () => {
    onClick && onClick(label, type);
  };

  return (
    <div className={`tag ${!isChecked ? 'not-checked' : 'checked'}`} onClick={toggleChecked}>
      <div className='tag-icon'>
        <Icons.IcDelete />
      </div>
      <span className='tag-label'>{label}</span>
    </div>
  );
};

export default Tag;

Tag.propTypes = {
  /** 태그의 라벨 텍스트 */
  label: PropTypes.string.isRequired,
  /** 태그를 클릭했을 때 실행되는 함수 */
  onClick: PropTypes.func,
  /** 체크된 태그의 타입 (onClick을 사용할 때만 내려받습니다) */
  type: PropTypes.string,
  /** 태그가 체크된 상태인지 여부 */
  isChecked: PropTypes.bool.isRequired,
};

import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icons, Colors } from 'styles';
import './index.scss';

const outerBgColors = {
  type1: Colors.black,
  type2: Colors.white,
};

//! 일반 labelSize: 12px / 'big' 들어오면 16px / 'medium' 들어오면 14px
//! type1: default / type2: video component button (outer: white)
const LongCircleButton = ({ label, onClick, labelSize, disabled, type = 'type1' }) => {
  const outerButtonref = useRef();
  const innerButtonRef = useRef();

  const handleMouseEnterOrLeave = (e) => {
    const innerButton = innerButtonRef.current;
    if (innerButton) {
      innerButton.style.top = e.offsetY + 'px';
      innerButton.style.left = e.offsetX + 'px';
    }
  };

  useEffect(() => {
    const outerButton = outerButtonref.current;

    if (outerButton && !disabled) {
      outerButton.addEventListener('mouseenter', handleMouseEnterOrLeave);
      outerButton.addEventListener('mouseleave', handleMouseEnterOrLeave);
    }

    return () => {
      outerButton.removeEventListener('mouseenter', handleMouseEnterOrLeave);
      outerButton.removeEventListener('mouseleave', handleMouseEnterOrLeave);
    };
  }, [disabled]);

  return (
    <div
      className='outer-long-button'
      ref={outerButtonref}
      onClick={onClick}
      style={{ backgroundColor: disabled ? Colors.lightGrey : outerBgColors[type] }}
    >
      {!disabled && <span className='inner-long-button' ref={innerButtonRef}></span>}
      <span className={`button-label ${labelSize}`}>
        {disabled && (
          <span className='disabled-icon'>
            <Icons.IcDone />
          </span>
        )}
        <span className='label-text'>{label}</span>
      </span>
    </div>
  );
};

LongCircleButton.propTypes = {
  /** 버튼 라벨 텍스트 */
  label: PropTypes.string.isRequired,
  /** 버튼을 클릭했을 때 실행되는 함수 */
  onClick: PropTypes.func,
  /** 버튼 라벨 크기 */
  labelSize: PropTypes.oneOf(['big', 'medium']),
  /** 활성화 여부 */
  disabled: PropTypes.bool,
  /** 버튼의 타입 (스타일이 다릅니다) */
  type: PropTypes.oneOf(['type1', 'type2']),
};

LongCircleButton.defaultTyped = {
  type: 'type1',
};

export default LongCircleButton;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icons } from 'styles';
import PropTypes from 'prop-types';
import './index.scss';

const headerHeight = 80;
// const baseOffsetTop = 24;

const HambergerButton = ({ onClick }) => {
  const { showMenu } = useSelector((state) => state.ui);

  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const handleScroll = (e) => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > headerHeight) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => {
    if (showMenu === '' || !showMenu) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div className={`hamberger-button ${toggleMenu() ? 'active' : ''}`} onClick={onClick}>
      {!showScrollButton ? <Icons.IcHambergerMenu /> : <Icons.IcScrollHambergerMenu />}
    </div>
  );
};

HambergerButton.propTypes = {
  /** 버튼을 클릭했을 때 실행되는 함수 */
  onClick: PropTypes.func,
};

export default HambergerButton;

import { Carousel, CircleButton } from 'components';
import Strings from 'strings';
import { screenSizeTypes } from 'constants/Types';
import advisorImg1 from 'assets/imgs/img-team-advisros-sb3x.png';
import advisorImg2 from 'assets/imgs/img-team-advisros-jg3x.png';
import advisorImg3 from 'assets/imgs/img-team-advisros-sh3x.png';
import advisorImg4 from 'assets/imgs/img-team-advisros-hs3x.png';
import advisorImg5 from 'assets/imgs/img-team-advisros-hg3x.png';
import mobileAdvisorImg1 from 'assets/imgs/mo-img-team-advisros-sb3x.png';
import mobileAdvisorImg2 from 'assets/imgs/mo-img-team-advisros-jg3x.png';
import mobileAdvisorImg3 from 'assets/imgs/mo-img-team-advisros-sh3x.png';
import mobileAdvisorImg4 from 'assets/imgs/mo-img-team-advisros-hs3x.png';
import mobileAdvisorImg5 from 'assets/imgs/mo-img-team-advisros-hg3x.png';
import './index.scss';

export const advisorsData = [
  {
    id: '1',
    name: Strings.TEAM_ADVISORS_MODULE_NAME_1,
    desc: Strings.TEAM_ADVISORS_MODULE_DESC_1,
    degree: Strings.TEAM_ADVISORS_MODULE_DEGREE_1,
    imageDefault: advisorImg1,
    imageMobile: mobileAdvisorImg1,
    link: 'https://sites.google.com/view/sungbin',
  },
  {
    id: '2',
    name: Strings.TEAM_ADVISORS_MODULE_NAME_2,
    desc: Strings.TEAM_ADVISORS_MODULE_DESC_2,
    degree: Strings.TEAM_ADVISORS_MODULE_DEGREE_2,
    imageDefault: advisorImg2,
    imageMobile: mobileAdvisorImg2,
    link: 'https://sites.google.com/site/jaegulchoo/',
  },
  {
    id: '3',
    name: Strings.TEAM_ADVISORS_MODULE_NAME_3,
    desc: Strings.TEAM_ADVISORS_MODULE_DESC_3,
    degree: Strings.TEAM_ADVISORS_MODULE_DEGREE_3,
    imageDefault: advisorImg3,
    imageMobile: mobileAdvisorImg3,
    link: 'https://www.researchgate.net/profile/Sungchul_Choi2',
  },
  {
    id: '4',
    name: Strings.TEAM_ADVISORS_MODULE_NAME_4,
    desc: Strings.TEAM_ADVISORS_MODULE_DESC_4,
    degree: Strings.TEAM_ADVISORS_MODULE_DEGREE_4,
    imageDefault: advisorImg4,
    imageMobile: mobileAdvisorImg4,
    link:
      'https://scholar.google.com/citations?hl=en&user=METPtcAAAAAJ&view_op=list_works&sortby=pubdate',
  },
  {
    id: '5',
    name: Strings.TEAM_ADVISORS_MODULE_NAME_5,
    desc: Strings.TEAM_ADVISORS_MODULE_DESC_5,
    degree: Strings.TEAM_ADVISORS_MODULE_DEGREE_5,
    imageDefault: advisorImg5,
    imageMobile: mobileAdvisorImg5,
    link: 'https://www.researchgate.net/profile/Hyungjoo_Cho',
  },
];

const checkScreenSize = (ui) => {
  if (ui === screenSizeTypes.MOBILE || ui === screenSizeTypes.FULLHD) {
    return true;
  }
  return false;
};

export const AdvisorModule = ({ advisor, ui }) => {
  const openLink = () => {
    window.open(advisor.link);
  };

  return (
    <article
      className='module-container'
      onClick={() => {
        ui === screenSizeTypes.MOBILE && openLink();
      }}
    >
      <div className='module-image' onClick={openLink}>
        {ui === screenSizeTypes.MOBILE ? (
          <img src={advisor.imageMobile} alt={advisor.name} />
        ) : (
          <img src={advisor.imageDefault} alt={advisor.name} />
        )}
      </div>
      <div className='module-body'>
        <div className='module-descriptions'>
          <div className='module-name'>{advisor.name}</div>
          <div className='module-desc'>{advisor.desc}</div>
          <div className='module-degree'>{advisor.degree}</div>
        </div>
        {ui !== screenSizeTypes.MOBILE && (
          <CircleButton label={Strings.MAIN_CONTACT_US_CURSOR_LABEL} onClick={openLink} />
        )}
      </div>
    </article>
  );
};

const DefaultModuleList = ({ ui }) => {
  return (
    <div className='advisors-module-container'>
      {advisorsData.map((advisor) => (
        <AdvisorModule advisor={advisor} key={advisor.id} ui={ui} />
      ))}
    </div>
  );
};

const Advisors = ({ ui }) => {
  return (
    <section className='advisors-section'>
      <div className='advisors-content'>
        <div className='advisors-title-section'>
          <div className='advisors-title'>{Strings.TEAM_ADVISORS_TITLE}</div>
          <div className='advisors-title-desc'>{Strings.TEAM_ADVISORS_DESC}</div>
        </div>
        <div className='advisors-module-section'>
          {checkScreenSize(ui) ? <DefaultModuleList ui={ui} /> : <Carousel type='team-advisor' />}
        </div>
      </div>
    </section>
  );
};

export default Advisors;

import { screenSizeTypes } from 'constants/Types';

export const upToTop = () => {
  window.scrollTo({
    top: 0,
  });
};

export const isChangeAnimation = ({ ref, setShow, getValue }) => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  let windowInnerHeight = window.innerHeight;
  let bottom = scrollTop + windowInnerHeight; //* 보이는 화면의 바닥 위치;
  let targetTopLocation;

  if (!ref.current) {
    return { bottom: 0, targetTopLocation: 0 };
  } else if (ref && ref.current) {
    targetTopLocation = ref.current.offsetParent.offsetTop + ref.current.offsetTop + 50;
  } else {
    targetTopLocation = ref.offsetParent.offsetTop + ref.offsetTop + 50;
  }

  if (getValue) {
    return { bottom, targetTopLocation };
  }
};

export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  // return decodeURIComponent(results[2].replace(/\+/g, " "))
  return decodeURIComponent(results[2]);
};

export const matchLayouts = (ui) => {
  switch (ui) {
    case screenSizeTypes.MOBILE:
    case screenSizeTypes.TABLET: {
      return screenSizeTypes.SMALL_SCREEN;
    }
    case screenSizeTypes.DESKTOP:
    case screenSizeTypes.WIDESCREEN:
    case screenSizeTypes.FULLHD: {
      return screenSizeTypes.BIG_SCREEN;
    }
    default: {
      return '';
    }
  }
};

export const isExistPathname = ({ pathname, item, selectedMenu }) => {
  // * 메뉴의 서브메뉴까지 선택되어있는지 확인 후 메뉴 타이틀 색을 변경시킴
  // * selectedMenu 는 선택된 메뉴의 index 값.
  // console.log(
  //   '%cMyProject%cline:68%cpathname',
  //   'color:#fff;background:#ee6f57;padding:3px;border-radius:2px',
  //   'color:#fff;background:#1f3c88;padding:3px;border-radius:2px',
  //   'color:#fff;background:rgb(252, 157, 154);padding:3px;border-radius:2px',
  //   pathname,
  //   item,
  //   selectedMenu
  // );
  let result = '';
  if (item.path) {
    if (pathname === item.path) {
      result = 'select';
    }
    if (selectedMenu) result = ''; // * subMenu가 있을때에도 들어가야함
  } else if (item.subMenu) {
    for (let i = 0; i < item.subMenu.length; i++) {
      if (item.subMenu[i].path.indexOf(pathname) > -1) {
        if (pathname === '/') {
          result = '';
        } else {
          result = 'select';
        }
      }
    }
    if (selectedMenu) result = ''; // * subMenu가 있을때에도 들어가야함
  }

  return result;
};

//* email validation
export function validateEmail(value) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(value).toLowerCase());
}

export const validateCheckList = (checkListArr) => {
  let result = true;
  for (const target of checkListArr) {
    if (target.state === false) {
      result = false;
    }
  }
  return result;
};

export const interactiveAiPositionTop = (ui) => {
  switch (ui) {
    case screenSizeTypes.FULLHD:
      return 60;
    case screenSizeTypes.WIDESCREEN:
    case screenSizeTypes.DESKTOP:
      return 60;
    case screenSizeTypes.TABLET:
      return 100;
    case screenSizeTypes.MOBILE:
      return 64;
    default:
      return 180;
  }
};

export const jobInfoSort = ({ parameter, jobsData }) => {
  // * parameter 는 getParameterByname 으로 가져온 string.

  let labelArr = [];
  let title = '';
  let selectObj = {};
  if (jobsData) {
    for (const item of jobsData) {
      for (const job of item.jobs) {
        if (parameter === job.key) {
          title = job.title;
          labelArr.push(item.jobTitle);
          labelArr.push(item.location);
          selectObj = job;
        }
      }
    }
  }

  if (!labelArr.length && !title && !Object.keys(selectObj).length) {
    return null;
  }

  return { labelArr, title, selectObj };
};

export const browserCheck = () => {
  var agent = navigator.userAgent.toLowerCase();
  if (agent.indexOf('trident') !== -1) {
    return false;
  } else {
    // return false;
    return true;
  }
};

export const isSafari = () => {
  let agent = navigator.userAgent.toLocaleLowerCase();
  if (agent.indexOf('safari/') === -1) {
    return false;
  } else {
    return true;
  }
};

export const matchCardinalNum = (normalNumber) => {
  switch (normalNumber) {
    case 'one':
      return 0;
    case 'two':
      return 1;
    case 'three':
      return 2;
    case 'four':
      return 3;
    case 'five':
      return 4;
    case 'six':
      return 5;
    case 'seven':
      return 6;
    case 'eight':
      return 7;
    case 'nine':
      return 8;
    case 'ten':
      return 9;
    default:
      return 0;
  }
};

export const matchOridnalNum = (ordinalNum) => {
  switch (ordinalNum) {
    case 'first':
      return 0;
    case 'second':
      return 1;
    case 'third':
      return 2;
    case 'fourth':
      return 3;
    case 'fifth':
      return 4;
    case 'sixth':
      return 5;
    case 'seventh':
      return 6;
    case 'eighth':
      return 7;
    case 'nineth':
      return 8;
    default:
      return 0;
  }
};
export const addComma = (num) => {
  var regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
};

export const isDev = () => {
  if (window.location.origin.indexOf('genesislab.ai') > -1) {
    return false;
  } else {
    return true;
  }
};
